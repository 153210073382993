<template>
  <v-dialog v-model="dialog" eager max-width="50%">
    <v-card>
      <v-card-title>
        <span class="md-text">Export</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-col cols="12" class="ma-0" v-if="loading">
          <loadingProgress :loading="loading" text="Loading notification..." />
        </v-col>
        <v-col cols="12" class="ma-0 pa-0 pb-2" v-show="!loading">
          <v-text-field
            v-model="inputName"
            label="Exportation name"
            hide-details
            dense
            outlined
          ></v-text-field>
          <div class="d-flex align-center mt-2">
            <v-switch
              dense
              hide-details="true"
              class="mt-0"
              color="error"
              label="Only matches has data"
              v-model="filter_matchs"
            ></v-switch>
          </div>
        </v-col>

        <v-tabs v-show="!loading" grow background-color="#eee" v-model="currentItem">
          <v-tab>Websites filter</v-tab>
          <v-tab>Advanced filter</v-tab>
        </v-tabs>
        <v-tabs-items v-show="!loading" v-model="currentItem" class="pt-4">
          <v-tab-item>
            <v-row>
              <v-col cols="12" class="py-0">
                <div class="text--secondary ma-0 mt-2 md-text">Uncheck to exclude website</div>
              </v-col>
              <v-col cols="12" class="ma-0">
                <v-checkbox
                  dense
                  hide-details
                  class="ma-0 font-weight-medium"
                  label="Select all websites"
                  v-model="select_all"
                  @change="selectAll"
                >
                </v-checkbox>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="4"
                class="ma-0"
                v-for="(website, index) in websites"
                :key="index"
              >
                <v-checkbox dense hide-details class="ma-0" v-model="website.checked">
                  <template v-slot:label>
                    <div class="d-flex align-center">
                      <img
                        :src="
                          'https://8181.integ.crawlo.com/favicon?size=xs&website=' + website.url
                        "
                        @error="replaceByDefault"
                        width="30"
                        height="30"
                        class="mr-2 favicon-img"
                      />{{ website.alias }}
                    </div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row>
              <v-col cols="12" class="pb-2">
                <filterInputs
                  :exportation="true"
                  :component_name="component_name"
                  :opened="dialog"
                  @filter-changed="filterChanged"
                  @load-data="previewData"
                />
              </v-col>
              <v-col cols="12" class="ma-0">
                <pricesSimpleTable
                  :filter="table_filter"
                  :changed="data_changes"
                  :component_name="component_name"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="dialog = false">Cancel</v-btn>
        <submitButton
          :submitFunction="submitExportRequest"
          :submit_loading="submit_loading"
          text="Yes, export it!"
          color="secondary"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  import filterInputs from '../common/filterInputs.vue'
  import pricesSimpleTable from '../common/pricesSimpleTable.vue'
  export default {
    name: 'exportationModal',
    props: ['show', 'component_name'],
    components: {
      filterInputs,
      pricesSimpleTable,
    },
    data() {
      return {
        dialog: false,
        select_all: true,
        websites: {},
        inputName: '',
        loading: true,
        currentItem: 0,
        submit_loading: false,
        export_modal_opend: false,
        filter_matchs: true,
        table_filter: {},
        data_changes: false,
      }
    },
    methods: {
      previewData(payload) {
        this.table_filter = payload
        this.data_changes = !this.data_changes
      },
      filterChanged(payload) {
        this.table_filter = payload
      },
      submitExportRequest() {
        this.submit_loading = true
        setTimeout(() => {
          let exclude_websites = this.websites
            .filter((website) => {
              return !website.checked
            })
            .map((elt) => elt.alias)

          axios
            .post('/api/exportation/exportrequest', {
              data: {
                account: localStorage.getItem('account_id'),
                user: localStorage.getItem('user_id'),
                component: this.component_name,
                export_name: this.inputName,
                exclude_websites: exclude_websites,
                only_data: this.filter_matchs,
                filter: this.table_filter,
              },
            })
            .then((response) => {
              if (response.status == 201) {
                this.$emit('show-redirect-modal')
                this.export_modal_opend = true
              } else {
                this.showNotification({
                  msg: "Your export '" + this.inputName + "' Not successfully submited",
                  type: 'error',
                })
              }
            })
            .finally(() => {
              this.dialog = false
              this.submit_loading = false
            })
        }, 1000)
      },
      showNotification(payload) {
        this.snackbar_color = payload.type
        this.snackbar_msg = payload.msg
        this.snackbar_icon = payload.icon
        this.snackbar = !this.snackbar
        this.changed = !this.changed
      },
      getAccountWebsites() {
        this.websites = []
        this.inputName = ''
        this.loading = true
        axios
          .get('/api/exportation/websites', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
            },
          })
          .then((res) => {
            this.websites = res.data.map((elt) => {
              elt.checked = true
              return elt
            })
          })
          .finally(() => {
            this.loading = false
          })
      },

      selectAll() {
        this.websites.map((elt) => {
          elt.checked = this.select_all
        })
      },
    },
    watch: {
      show: {
        handler() {
          this.dialog = true
          this.currentItem = 0
          this.filter_matchs = true
          this.getAccountWebsites()
        },
      },
      websites: {
        handler() {
          let all_checked = true
          for (let i = 0; i < this.websites.length; i++) {
            if (!this.websites[i].checked) {
              all_checked = false
              break
            }
          }
          this.select_all = all_checked
        },
        deep: true,
      },
    },
  }
</script>
