import {
    SOCKET_CONNECTED,
    SOCKET_DESTROY,
} from "../actions/socket";

const state = {
    socket_connected: false
};
const getters = {
    isSocketConnected: state => () => {
        return state.socket_connected;
    },
};
const mutations = {
    [SOCKET_CONNECTED]: state => {
        state.socket_connected = true;
    },
    [SOCKET_DESTROY]: state => {
        state.socket_connected = false;
    }
};
const actions = {
    [SOCKET_CONNECTED]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            
            commit(SOCKET_CONNECTED);
        });
    },
    [SOCKET_DESTROY]: ({ commit, dispatch }) => {
        return new Promise((resolve, reject) => {
            commit(SOCKET_DESTROY);
        });
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};