<template>
  <v-list class="pa-1 mt-7" dense nav>
    <v-list-item-group v-model="item" color="primary">
      <template v-for="(route, index) in side_bar_items_config.routes">
        <v-list-item
          @click="redirectTo(route.route)"
          v-bind:key="`${index}-${new Date().getTime()}`"
        >
          <span>
            <img :src="require(`../assets/icones/${route.icon}`)" width="25" />
          </span>
          <v-list-item-content class="ml-2">
            <v-list-item-title>{{ route.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
  export default {
    name: 'SideBarNav',
    props: {
      side_bar_items_config: Object,
      selected_item: String,
      mini: Boolean,
    },
    data() {
      return {
        item: 0,
      }
    },
    methods: {
      redirectTo(route) {
        this.$router.push(route)
      },
      highlightSelectedItem(item) {
        this.side_bar_items_config.routes.forEach((element, index) => {
          if (item == element.route.toLowerCase()) this.item = index
        })
      },
    },
    mounted() {
      this.highlightSelectedItem(this.$route.path.toLowerCase())
    },
    watch: {
      selected_item: {
        handler() {
          this.highlightSelectedItem(this.$route.path.toLowerCase())
        },
      },
    },
  }
</script>

<style lang="less">
  #sidebar {
    min-width: 300px;
  }
</style>
