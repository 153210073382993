<template>
  <v-chip
    :color="color"
    label
    outlined
    :style="
      (small ? 'height: auto;' : '') +
      (cursor != null ? 'cursor:' + cursor + ';' : '')
    "
    :class="progress ? 'pr-0' : ''"
  >
    <v-avatar left v-if="icon && icon_align == 'left'">
      <v-icon>mdi-{{ icon }}</v-icon>
    </v-avatar>
    {{ text }}
    <v-progress-circular
      style="height: 15px"
      v-if="progress"
      indeterminate
      :color="color"
    ></v-progress-circular>
    <v-avatar right v-if="icon && icon_align == 'right'">
      <v-icon>mdi-{{ icon }}</v-icon>
    </v-avatar>

  </v-chip>
</template>
<script>
export default {
  name: "badge",
  props: ["text", "color", "progress", "small", "cursor", "icon", "icon_align"],
};
</script>
