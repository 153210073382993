<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="snackbar_timeout"
    :color="snackbar_color"
    light
  >
    <v-icon v-if="snackbar_icon != undefined" class="pr-4"
      >mdi-{{ snackbar_icon }}</v-icon
    >
    {{ snackbar_msg }}
    <v-btn dark text @click="snackbar = false">
      {{ snackbar_btn_text }}
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  name: "snackbar",
  props: [
    "show",
    "timeout",
    "color",
    "msg",
    "btn_text",
    "btn_function",
    "icon",
  ],
  data() {
    return {
      snackbar_timeout: 20000,
      snackbar_color: "default",
      snackbar_msg: "",
      snackbar_btn_text: "Close",
      snackbar: false,
      snackbar_icon: "",
    };
  },
  watch: {
    show: {
      handler() {
        this.snackbar = false;
        this.snackbar = true;
      },
    },
    timeout: {
      handler() {
        this.snackbar_timeout = this.timeout;
      },
    },
    color: {
      handler() {
        this.snackbar_color = this.color;
      },
    },
    msg: {
      handler() {
        this.snackbar_msg = this.msg;
      },
    },
    btn_text: {
      handler() {
        this.snackbar_btn_text = this.btn_text;
      },
    },
    icon: {
      handler() {
        this.snackbar_icon = this.icon;
      },
    },
  },
};
</script>
