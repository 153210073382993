<template>
  <v-data-table
    outlined
    class="alerts-table"
    :headers="table.headers"
    :items="table.data"
    :loading="loading"
    :items-per-page="10"
    :options.sync="pagination"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }"
    :server-items-length="total_items"
  >
    <template v-slot:item.status="{ item }">
      <v-switch
        color="error"
        v-model="item.status"
        @change="statusChange(item)"
      ></v-switch>
    </template>
    <template v-slot:item.days="{ item }">
      <span v-html="getDays(item.days)"></span>
    </template>
    <template v-slot:item.action="{ item }">
      <v-btn color="grey" icon light @click="editAlert(item)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn color="grey" icon light @click="deleteAlert(item)">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";
export default {
  name: "alertsTable",
  props: ["items", "changed"],
  data() {
    return {
      loading: false,
      pagination: { },
      total_items:0,
      days: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      table: {
        headers: [
          {
            text: "Alert name",
            value: "name",
            width: "27%",
            sortable: false,
          },
          {
            text: "Days",
            value: "days",
            width: "16%",
            sortable: false,
            align: "center",
          },
          {
            text: "Date",
            value: "date",
            width: "13%",
            sortable: false,
            align: "center",
          },
          {
            text: "Alert type",
            value: "alert_type",
            width: "12%",
            sortable: false,
            align: "center",
          },
          {
            text: "Products type",
            value: "product_type",
            width: "10%",
            sortable: false,
            align: "center",
          },
          {
            text: "Status",
            value: "status",
            width: "5%",
            sortable: false,
          },
          {
            text: "Actions",
            value: "action",
            width: "7%",
            sortable: false,
            align: "center",
          },
        ],
        data: [],
      },
    };
  },
  methods: {
    async statusChange(item) {
      await axios.post(`/api/alerts/changestatus`, {
        account: localStorage.getItem("account_id"),
        user: localStorage.getItem("user_id"),
        id: item.id,
        status: item.status,
      });
    },
    deleteAlert(item) {
      this.$emit("delete-alert", item);
    },
    editAlert(item) {
      this.$emit("edit-alert", item);
    },
    getDays(days) {
      let html = "";
      this.days.map((day) => {
        html += ` <span class="font-weight-bold ${
          days.indexOf(day) == -1 ? "text--disabled" : ""
        } ml-1">${day}</span>`;
      });
      return html;
    },
    getAlerts() {
      this.loading = true;
      let from =
        this.pagination.page * this.pagination.itemsPerPage -
        this.pagination.itemsPerPage;
      let size = this.pagination.itemsPerPage;
      axios
        .get("/api/alerts", {
          params: {
            account: localStorage.getItem("account_id"),
            user: localStorage.getItem("user_id"),
            from,
            size,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          
          this.table.data = res.data.items.map((elt) => {
            return {
              id: elt.id,
              name: elt.name,
              product_type: elt.product_type,
              status: elt.status,
              days: elt.config.days,
              alert_type:
                elt.config.alert_type.email &&
                elt.config.alert_type.notification
                  ? "Notification / Email"
                  : elt.config.alert_type.notification
                  ? "Notification"
                  : "Email",
              date: elt.date,
              config: elt.config,
            };
          });
          this.total_items = res.data.count.value;
          this.loading = false;
        })
        .catch(function () {
          console.log("FAILURE!! get all requests");
        })
        .finally(() => (this.loading = false));
    },
    getItemPerPage(val) {
      this.pagination.itemsPerPage = val;
    },
  },
  mounted() {
    // this.$socket.client.on("globalListener", (data) => {
    //   if (data.dataChanged && data.cause == "alertChanges") {
    //     this.getAlerts();
    //   }
    // });
  },
  watch: {
    changed:{
      handler(){
        setTimeout(this.getAlerts,1000);
      }
    },
    pagination: {
      handler() {
        this.loading = true;
        try {
          this.getAlerts();
        } catch (error) {
          console.log("pagination", error);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.alerts-table {
  width: 100%;
}
</style>
