<template>
  <v-card>
    <v-card-text class="pt-0">
      <v-row>
        <v-col cols="12" class="pb-0 d-flex align-center">
          <span class="product-num">Product {{ index + 1 }}</span>
          <v-btn icon class="ml-auto" @click="remove(index)" v-if="!update">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-row>
            <v-col cols="12">
              <v-text-field
                :disabled="update"
                label="sku (optional)"
                v-model="element.ref"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-for="(field, index) in selectedExtraFields" v-bind:key="field">
            <v-col cols="11">
              <v-text-field
                :disabled="update"
                :label="`${field} (optional)`"
                v-model="element[field]"
                :type="field == 'cost' ? 'number' : 'text'"
                :min="field == 'cost' ? 0 : null"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="1 pt-5"
              @click="selectedExtraFields.splice(index, 1)"
              style="cursor: pointer"
            >
              <v-icon medium>mdi-close-circle-outline</v-icon>
            </v-col>
          </v-row>
          <v-row v-if="selectedExtraFields.length < extraFields.length">
            <v-col cols="6">
              <v-menu open-on-click offset-y bottom :close-on-content-click="false">
                <template v-slot:activator="{ on: menu }" class="pa-4">
                  <v-btn small outlined v-on="{ ...menu }">
                    Add More Attributes <v-icon right dark>$add</v-icon>
                  </v-btn>
                </template>
                <v-card class="pa-0" flat>
                  <v-card-text>
                    <v-row
                      v-for="field in extraFields.filter(
                        (field) => !selectedExtraFields.includes(field),
                      )"
                      v-bind:key="field"
                      @click="selectedExtraFields.push(field)"
                      style="cursor: pointer"
                      v-model="element[field]"
                    >
                      <v-col cols="1">
                        <v-icon small>mdi-plus-circle-outline</v-icon>
                      </v-col>
                      <v-col cols="10"> {{ field }}</v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          URLs of you competitors (Each url in a new line)
          <codemirror
            :options="options"
            class="codemirror-input"
            :class="!full ? 'codemirror-not-full' : ''"
            v-model="element.urls"
          ></codemirror>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  import { codemirror } from 'vue-codemirror'
  import 'codemirror/theme/3024-day.css'
  import 'codemirror/addon/selection/active-line.js'
  const isUrl = require('is-url')

  import CodeMirror from 'codemirror/lib/codemirror'
  CodeMirror.defineMode('mymode', () => {
    return {
      token(stream, state) {
        if (isUrl(stream.string)) {
          stream.next()
          return 'variable'
        } else {
          stream.next()
          return 'keyword'
        }
      },
    }
  })

  export default {
    name: 'productForm',
    components: {
      codemirror,
    },
    props: ['element', 'index', 'update', 'full', 'error'],
    data() {
      return {
        selectedExtraFields: [],
        extraFields: ['ean', 'model', 'brand', 'category', 'cost'],
      }
    },
    computed: {
      options() {
        return {
          mode: 'mymode',
          styleActiveLine: true,
          lineNumbers: true,
          lineWrapping: false,
          viewportMargin: Infinity,
          dragDrop: true,
          inputStyle: 'textarea',
          theme: '3024-day',
          lineNumberFormatter: (line) => {
            if (this.error == 'true')
              return line == 1 ? 'Your product : ' : `Competitor ${line - 1} : `
            else return `Competitor ${line} : `
          },
        }
      },
    },
    methods: {
      remove(index) {
        this.$emit('delete-event', index)
      },
    },
  }
</script>
<style lang="scss">
  .codemirror-not-full {
    .CodeMirror {
      height: 150px !important;
      border-radius: 5px;
    }
  }
  .CodeMirror-linenumber {
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  }
  .CodeMirror {
    border-radius: 5px;
  }
  .codemirror-input {
    margin-top: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
</style>
