<template>
  <div class="items w-100" @click.stop="">
    <div
      class="competitors-section v-item-group theme--light v-slide-group v-slide-group--has-affixes v-slide-group--is-overflowing"
    >
      <div class="d-flex align-center mr-2">
        <v-btn icon large color="grey" @click.stop="scrollTo(false)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
      <div class="v-slide-group__wrapper">
        <div class="v-slide-group__content" :id="product_match.match_id">
          <v-tooltip top v-for="competitor in competitors" :key="competitor.website.id">
            <template
              v-slot:activator="{ on, attrs }"
              v-if="includeWebsite(competitor.website.alias)"
            >
              <v-card @click.stop="" v-bind="attrs" v-on="on" class="item ma-2 pa-2" flat>
                <a :href="competitor.product.url" target="_blank" style="color: #42b983">
                  <div class="d-flex align-center">
                    <img
                      :src="getWebsiteFavicon(competitor.website.url)"
                      :alt="competitor.website.alias"
                      height="25"
                      class="mr-4 favicon-img"
                    />
                    <div
                      class="text-truncate"
                      v-html="
                        priceFormatter(
                          competitor,
                          product_match.hight_price,
                          product_match.low_price,
                        )
                      "
                    ></div>
                  </div>
                  <div class="d-flex">
                    <span>
                      <v-chip
                        class="mt-2"
                        small
                        :color="
                          competitor.product.offers[0].availability == 'InStock'
                            ? 'success'
                            : 'error'
                        "
                        label
                        outlined
                      >
                        {{ competitor.product.offers[0].availability }}
                      </v-chip>
                    </span>
                    <span class="pa-2">
                      <a
                        :href="`https://www.google.com${product_match.googleShoppingUrl}?gl=${user_country}`"
                        target="_blank"
                      >
                        <img
                          v-if="competitor.product.isGoogleShoppingProduct"
                          :src="require(`../../assets/google_shopping_favicon.png`)"
                          width="20"
                          height="20"
                          class="mr-2 favicon-img"
                        />
                      </a>
                    </span>
                  </div>
                </a>
              </v-card>
            </template>
            <span class="d-flex align-center">
              <img
                :src="getWebsiteFavicon(competitor.website.url)"
                :alt="competitor.website.alias"
                height="25"
                class="mr-4 favicon-img"
              />{{ competitor.website.alias }}</span
            >
          </v-tooltip>
        </div>
      </div>
      <div class="d-flex align-center ml-2">
        <v-btn icon large color="grey" @click.stop="scrollTo(true)">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['product_match', 'competitors_websites'],
    data() {
      return {
        user_country: JSON.parse(localStorage.user_website).country,
        competitors: [],
        competitors_filter: [],
        model: null,
        current_offset: 0,
        current_element: 0,
      }
    },
    mounted() {
      Object.assign(this.competitors_filter, this.competitors_websites)
      this.initialisation()
    },
    methods: {
      includeWebsite(website) {
        if (!this.competitors_filter || !this.competitors_filter.length) return true
        if (this.competitors_filter.includes(website)) return true
        return false
      },
      scrollTo(forward) {
        let wrapper = document.getElementsByClassName('v-slide-group__wrapper')[0]
        let content = document.getElementById(this.product_match.match_id)
        let offset = content.offsetWidth - wrapper.offsetWidth
        if (forward)
          this.current_offset =
            this.current_offset + wrapper.offsetWidth / 2 > offset
              ? offset
              : this.current_offset + wrapper.offsetWidth / 2
        else {
          this.current_offset =
            this.current_offset - wrapper.offsetWidth / 2 < 0
              ? 0
              : this.current_offset - wrapper.offsetWidth / 2
        }
        content.style.transform = `translateX(-${this.current_offset}px)`
      },
      initialisation() {
        this.competitors = []
        Object.keys(this.product_match).forEach((key) => {
          if (this.product_match[key].product) {
            if (this.product_match[key].website.alias == localStorage.getItem('website_alias'))
              this.competitors.unshift(this.product_match[key])
            else this.competitors.push(this.product_match[key])
          }
        })
      },
    },
    watch: {
      product_match: {
        deep: true,
        handler() {
          this.initialisation()
        },
      },
    },
  }
</script>
<style scoped>
  .item {
    width: 145px;
  }
  .items {
    height: 80px;
  }
</style>
