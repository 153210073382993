<template>
  <v-dialog v-model="dialog" eager max-width="50%">
    <v-card>
      <v-card-title>
        <span class="md-text">Import setting</span>
        <div class="ml-auto d-flex flex-column mr-4">
          <span :class="inserted == limit ? 'error-color' : ''" class="ma-0 text-right sm-text">
            Matches : {{ inserted }} / {{ limit }}
          </span>

          <v-progress-linear
            height="15"
            style="width: 250px"
            :value="limit_percentage"
            :color="limit_progress_color"
            bottom
            rounded
            reactive
          >
          </v-progress-linear>
        </div>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="pb-0">
          <v-row>
            <v-tabs grow background-color="#eee" v-model="currentItem">
              <v-tab> (File) Adding Multiple Products </v-tab>
              <v-tab> Add one product at time </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentItem" class="pt-4 w-100">
              <v-tab-item>
                <multipleProductsForm
                  :key="Math.random()"
                  :opened="dialog"
                  ref="mulitpleProductsForm"
                  @close-model="closeModal"
                  @donwload-sample-file="downloadSampleFile()"
                  @show-notification="showNotification"
                />
              </v-tab-item>
              <v-tab-item>
                <plainTextProductForm
                  :hide="dialog"
                  @close-model="closeModal"
                  @show-notification="showNotification"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  import plainTextProductForm from './plainTextProductForm.vue'
  import multipleProductsForm from './multipleProductsForm.vue'
  export default {
    name: 'importationModal',
    props: ['show', 'inserted', 'limit'],
    components: {
      plainTextProductForm,
      multipleProductsForm,
    },
    data() {
      return {
        dialog: false,
        select_all: true,
        websites: {},
        submit_loading: false,
        currentItem: 0,
        hide: false,
      }
    },
    computed: {
      limit_percentage: function () {
        return (this.inserted / this.limit) * 100
      },
      limit_progress_color: function () {
        return this.limit_percentage > 70 && this.limit_percentage < 90
          ? 'yellow darken-2'
          : this.limit_percentage > 90 && this.limit_percentage < 100
            ? 'orange darken-2'
            : this.limit_percentage == 100
              ? 'red darken-2'
              : 'success'
      },
    },
    methods: {
      showNotification(payload) {
        this.$emit('show-notification', payload)
      },
      submitImportRequest() {},
      closeModal() {
        this.dialog = false
      },
    },
    watch: {
      show: {
        handler() {
          this.dialog = true
          this.currentItem = 0
        },
      },
    },
  }
</script>
