<template>
  <v-dialog v-model="dialog" max-width="520">
    <v-card>
      <v-card-title><span class="md-text">Download</span></v-card-title>

      <v-card-text>
        <v-alert type="success" class="mb-0 mt-4 mb-4" style="font-size:1.2rem; font-weight:500">
          We are now preparing your data. 
          <br>
          <br>
          You will find the export on your download-list, once it is ready
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="dialog = false">
          Close
        </v-btn>

        <v-btn color="success" @click="goToExport()">
          Open download-list
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  name: "exportRedirectModal",
  props: ["show"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    goToExport() {
      this.dialog = false;
      this.$router.push("/exportations");
    },
  },
  watch: {
    show: {
      handler() {
        this.dialog = true;
      },
    },
  },
};
</script>
