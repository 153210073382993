<template>
  <v-app>
    <v-navigation-drawer
      v-if="!$route.meta.plainLayout"
      v-model="drawer"
      mini-variant-width="45"
      :mini-variant="mini"
      permanent
      clipped
      app
    >
      <sideBarNav
        class="pt-1"
        :mini="mini"
        :side_bar_items_config="side_bar_nav_config"
        :selected_item="selected_item"
      />
    </v-navigation-drawer>

    <v-app-bar
      color="crawloOrange"
      dark
      dense
      clipped-left
      app
      v-if="!$route.meta.plainLayout"
      class="mainNavBar"
    >
      <v-app-bar-nav-icon @click.stop="mini = !mini">
        <v-icon v-if="!mini">$arrow_left</v-icon>
      </v-app-bar-nav-icon>

      <v-toolbar-title>
        <router-link to="pricing" class="d-flex align-center pa-3">
          <img :src="require('./assets/Dynamic_Pricing.svg')" width="180" />
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <span v-if="account != '654b792b2071861acbbc3331'">
        {{ user_website.id == source_id ? 'Xiaomi' : website_alias }}
      </span>
      <img
        v-if="account != '654b792b2071861acbbc3331'"
        :src="
          'https://8181.integ.crawlo.com/favicon?size=xs&website=' +
          (user_website.id == source_id ? 'www.mi.com' : website_url)
        "
        @error="replaceByDefault"
        :alt="user_website.id == source_id ? 'www.mi.com' : website_alias"
        :website="user_website.id == source_id ? 'www.mi.com' : website_url"
        width="20"
        class="ml-2 mr-4 favicon-img"
        ref="myImg"
      />

      <v-menu :close-on-content-click="false" :nudge-width="350" max-width="350" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-badge
              color="success"
              :content="notification_count"
              :value="notification_count > 0"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-card max-height="500">
          <v-list v-if="notifications.unseen.length">
            <v-subheader>Unseen</v-subheader>
            <template v-for="(item, index) in notifications.unseen">
              <v-list-item v-bind:key="index" @click="showNotificationModal(item)">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.product_type }} - {{ item.time }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click.stop="deleteNotification(item)" icon>
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>

          <v-divider class="ma-0" v-if="notifications.unseen.length"></v-divider>

          <v-list>
            <v-subheader
              >Seen
              <v-btn
                class="ml-auto"
                color="primary"
                text
                @click="deleteSeenNotification()"
                v-if="notifications.seen.length"
                >Delete all</v-btn
              ></v-subheader
            >
            <v-list-item v-if="!notifications.seen.length">
              <v-list-item-content class="pt-0 pb-2">
                <v-list-item-subtitle class="text-center"
                  >There is no notification</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <template v-for="(item, index) in notifications.seen">
              <v-list-item v-bind:key="index" @click="showNotificationModal(item)">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ item.product_type }} - {{ item.time }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn @click.stop="deleteNotification(item)" icon>
                    <v-icon color="grey">mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-menu>

      <v-menu :close-on-content-click="false" :nudge-width="250" max-width="250" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon dark v-bind="attrs" v-on="on">
            <v-icon>mdi-cog-outline</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar :color="website_color">
                <span class="white--text md-text font-weight-bold">{{ avatar_text }}</span>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ fullname }}</v-list-item-title>
                <v-list-item-subtitle>{{ username }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="ma-0"></v-divider>

          <v-list>
            <template v-for="item in nav_bar_config.drop_down_items">
              <template
                v-if="item.admin_only == undefined || (item.admin_only && super_admin == 'true')"
              >
                <v-divider v-if="item.upper_divider" :key="item.name" :inset="true"></v-divider>
                <v-list-item
                  v-if="item.route && !item.child_routes"
                  @click="redirectTo(item.route)"
                  :key="item.name"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon" :color="item.icon_color"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="item.callback" @click="item.callback" :key="item.name">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon" :color="item.icon_color"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>

                <v-list-group
                  v-if="item.child_routes"
                  :key="item.name"
                  :prepend-icon="item.icon"
                  no-action
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title v-text="item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>

                  <v-list-item v-for="child in item.child_routes" :key="child.name">
                    <v-list-item-content>
                      <v-list-item-title v-text="child.name"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
            <v-divider></v-divider>
            <v-list-item @click="signOut()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-main class="content-background">
      <v-container class="pa-3" fluid>
        <router-view default="/Pricing"></router-view>
      </v-container>
    </v-main>

    <notificationModal :show="show_notification" :item="selected_notification" />
    <snackbar :show="snackbar" :color="snackbar_color" :msg="snackbar_msg" :icon="snackbar_icon" />
  </v-app>
</template>

<script>
  import axios from 'axios'
  import sideBarNav from './modules/sidebarnav.vue'
  import io from 'socket.io-client'
  import notificationModal from './components/common/notificationModal.vue'

  export default {
    components: {
      notificationModal,
      sideBarNav,
    },
    data: () => ({
      account: localStorage.account_id,
      user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
      source_id: '6019aee744817b0f85ff85bb',
      snackbar: false,
      snackbar_msg: '',
      snackbar_color: '',
      snackbar_icon: '',
      selected_item: '/pricing',
      show_notification: false,
      selected_notification: '',
      super_admin: localStorage.getItem('super_admin'),
      fullname: localStorage.getItem('fullname'),
      username: localStorage.getItem('user'),
      website_alias: localStorage.getItem('website_alias'),
      website_url: localStorage.getItem('website_url'),
      website_color: localStorage.getItem('website_color'),
      notifications: { unseen: [], seen: [] },
      notification_count: 0,
      mini: true,
      drawer: true,
      socketConnected: false,
      socket_connected: false,
      gotoPage: '',
      nav_bar_config: {
        routes: [],
        toggle_target: 'sidebar',
        toggle_target_mobile: 'offcanvas-push',
        drop_down_items: [
          {
            name: 'Settings',
            icon: 'mdi-account-cog-outline',
            route: 'settings',
          },
          {
            name: 'Plans & Billing',
            icon: 'mdi-credit-card-settings-outline',
            route: 'billing',
          },
          // {
          //   name: "Sign out",
          //   icon: "mdi-logout",
          // },
          {
            name: 'Admin',
            icon: 'mdi-shield-account-outline',
            route: 'administration',
            upper_divider: true,
            admin_only: true,
            icon_color: '#ccac00',
            // child_routes: [
            //   {
            //     name: "Administration",
            //     icon: "mdi-shield-account-outline",
            //     route: "administration",
            //   },
            // ],
          },
        ],
        drop_down_user_items: [],
        search_input: false,
        image_profile: '',
        image_profile_visible: true,
        crawlo_logo: 'https://image.flaticon.com/icons/svg/331/331395.svg',
        crawlo_logo_name: 'Crawlo',
      },
      side_bar_nav_config: {
        routes: [
          {
            name: 'Dashboard',
            header: true,
            route: '/pricing',
            icon: 'dashboard.svg',
            child_routes: [],
          },
          {
            name: 'Compare',
            header: true,
            route: '/compare',
            icon: 'compare.svg',
            child_routes: [],
          },
          {
            name: 'Exclusive products',
            header: true,
            route: '/ExclusiveProducts',
            icon: 'exclusive.svg',
            child_routes: [],
          },
          {
            name: 'Favorite products',
            header: true,
            route: '/favoriteProducts',
            icon: 'favorite.svg',
            child_routes: [],
          },
          {
            name: 'Competitors comparison',
            header: true,
            route: '/competitorPriceComparison',
            icon: 'compare2.svg',
            child_routes: [],
          },
          {
            name: 'Add products',
            header: true,
            route: '/importations',
            icon: 'import.svg',
            child_routes: [],
          },
          {
            name: 'My export list',
            header: true,
            route: '/exportations',
            icon: 'export.svg',
            child_routes: [],
          },
          {
            name: 'Alerts',
            header: true,
            route: '/alerts',
            icon: 'alert.svg',
            child_routes: [],
          },
        ],
      },
      authorized_pages: [
        '/subscription',
        '/login',
        '/emailinsert',
        '/resetpassword',
        '/privacy',
        '/termsconditions',
        '/success',
        '/administration',
      ],
    }),
    methods: {
      async signOut() {
        if (this.isAuthenticated()) {
          this.destroySocket()
          await this.$store.dispatch('AUTH_LOGOUT')
          this.$router.push('/login')
        }
      },
      showNotificationModal(item) {
        this.selected_notification = item
        this.show_notification = !this.show_notification
      },
      async deleteSeenNotification() {
        await axios
          .post(`/api/notification/deleteall`, {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
          })
          .then((res) => {
            if (res.data.status) {
              this.showNotification({
                msg: `All seen notifications has been successfully deleted from your notifications list`,
                type: 'success',
                icon: 'delete',
              })
            }
          })
      },
      async deleteNotification(item) {
        await axios
          .post(`/api/notification/delete`, {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            id: item.id,
          })
          .then((res) => {
            if (res.data.status) {
              this.showNotification({
                msg: `The notification has been successfully deleted from your notifications list`,
                type: 'success',
                icon: 'delete',
              })
            }
          })
      },
      destroySocket() {
        try {
          this.$socket.client.emit(
            'DisconnectListening',
            {
              user_id: localStorage.getItem('user_id'),
            },
            (resp) => {
              this.$store.dispatch('socketDestroyed')
            },
          )
        } catch (ex) {
          console.log(ex)
        }
      },
      socketlistener() {
        this.$socket.client.emit(
          'globalListener',
          {
            user_id: localStorage.getItem('user_id'),
          },
          (resp) => {
            this.$store.dispatch('socketConnected')
            this.$socket.client.on('globalListener', (res) => {
              if (res.dataChanged && res.notification) {
                this.showNotification({
                  type: res.data.warning ? 'success' : 'error',
                  msg: res.data.msg,
                })
              }
            })
          },
        )
      },
      redirectTo(route) {
        this.$router.push(route)
      },
      goto() {
        this.$router.push(this.gotoPage)
      },
      logout: function () {
        if (this.isAuthenticated) {
          this.$store.dispatch('AUTH_LOGOUT')
          this.$router.push('/login')
        }
      },
      showNotification(payload) {
        this.snackbar_color = payload.type
        this.snackbar_msg = payload.msg
        this.snackbar_icon = payload.icon
        this.snackbar = !this.snackbar
      },
      getTime(timestamp) {
        let diff = parseInt((Date.now() - timestamp) / 1000)
        let time = ''
        if (diff < 60) time = 'just now'
        else if (diff / 60 < 60) {
          diff = parseInt(diff / 60)
          time = diff == 1 ? 'about a minute ago' : diff + ' minutes ago'
        } else if (diff / 60 / 60 < 24) {
          diff = parseInt(diff / 60 / 60)
          time = diff == 1 ? 'about an hour ago' : diff + ' hours ago'
        } else if (diff / 60 / 60 / 24 < 7) {
          diff = parseInt(diff / 60 / 60 / 24)
          time = diff == 1 ? 'about a day ago' : diff + ' days ago'
        } else {
          diff = parseInt(diff / 60 / 60 / 24 / 7)
          time = diff == 1 ? 'about a week ago' : diff + ' weeks ago'
        }
        return time
      },
      getNotifications() {
        this.notifications = { unseen: [], seen: [] }
        axios
          .get('/api/notification', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            this.notifications.seen = res.data.items
              .filter((elt) => elt.viewed)
              .map((elt) => {
                elt.time = this.getTime(elt.timestamp)
                return elt
              })
            this.notifications.unseen = res.data.items
              .filter((elt) => !elt.viewed)
              .map((elt) => {
                elt.time = this.getTime(elt.timestamp)
                return elt
              })
            this.notification_count = this.notifications.unseen.length
          })
          .catch((err) => console.log(err))
      },
      timeoutNotificationTimeAdjustement() {
        setTimeout(() => {
          this.notifications.seen = this.notifications.seen.map((elt) => {
            elt.time = this.getTime(elt.timestamp)
            return elt
          })
          this.notifications.unseen = this.notifications.unseen.map((elt) => {
            elt.time = this.getTime(elt.timestamp)
            return elt
          })
          this.timeoutNotificationTimeAdjustement()
        }, 60000)
      },
      isAuthorized(page) {
        return [
          '/subscription',
          '/login',
          '/emailinsert',
          '/resetpassword',
          '/privacy',
          '/termsconditions',
          '/success',
          '/administration',
        ].includes(page)
      },
    },
    created() {
      if (this.$socket == undefined && !this.isAuthorized(this.$route.path.toLowerCase())) {
        this.getNotifications()
        this.connectSocket()
        this.socketlistener()
        this.timeoutNotificationTimeAdjustement()
        this.$socket.client.on('globalListener', (data) => {
          if (data.dataChanged && data.cause == 'notificationChanges') {
            this.getNotifications()
          }
        })
      }
    },
    mounted() {
      /**
       * We will back to this later
       */
      // this.$socket.client.on("connect", () => {
      //   console.log("socket connected");
      // });

      // this.$socket.client.on("disconnect", () => {
      //   const connectionRetries = setInterval(() => {
      //     console.log("reconnecting");
      //     if (this.$socket.client.connected) {
      //       clearInterval(connectionRetries);
      //       this.socketlistener();
      //     }
      //   }, 1000);
      // });

      // this.nav_bar_config.drop_down_items[2].callback = this.signOut();
      document.getElementById('index-loading').remove()
      let zendesk = document.createElement('script')
      zendesk.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=07830ac3-e4b2-48fa-a70c-ed3cdddbae73',
      )
      zendesk.setAttribute('id', 'ze-snippet')
      //document.head.appendChild(zendesk)
      axios.interceptors.request.use((request) => {
        this.$Progress.start()
        return request
      })

      document.title = 'Dynamic Pricing'

      axios.interceptors.response.use(
        (response) => {
          this.$Progress.finish()
          return response
        },
        (error) => {
          this.$Progress.fail()
        },
      )
    },
    computed: {
      isAuthenticated() {
        return this.$store.getters.isAuthenticated
      },
      avatar_text() {
        let avatar = this.fullname != undefined ? this.fullname.trim().split(' ') : []
        avatar = (avatar.length > 0 ? avatar[0][0] : '') + (avatar.length > 1 ? avatar[1][0] : '')
        return avatar.toUpperCase()
      },
    },

    watch: {
      async $route(to, from) {
        this.selected_item = to.path.toLowerCase()

        if (this.$socket == undefined && !this.isAuthorized(to.path.toLowerCase())) {
          this.connectSocket()
          this.socketlistener()
        }

        if (from.path.toLowerCase() == '/login' && !this.isAuthorized(to.path.toLowerCase())) {
          this.fullname = localStorage.getItem('fullname')
          this.username = localStorage.getItem('user')
          this.website_alias = localStorage.getItem('website_alias')
          this.website_url = localStorage.getItem('website_url')
          this.website_color = localStorage.getItem('website_color')
          this.super_admin = localStorage.getItem('super_admin')
          this.getNotifications()
        }

        if (this.website_alias != localStorage.getItem('website_alias')) {
          this.website_alias = localStorage.getItem('website_alias')
          this.website_url = localStorage.getItem('website_url')
          this.website_color = localStorage.getItem('website_color')
        }
      },
    },
  }
</script>

<style lang="less">
  .v-application,
  .v-data-iterator,
  .v-data-table-header {
    font-family: 'Red Hat Display', sans-serif !important;
  }

  .sm-text {
    font-size: 0.8em !important;
  }
  .md-text {
    font-size: 1.2em !important;
  }
  .lg-text {
    font-size: 1.6em !important;
  }
  .xl-text {
    font-size: 2em !important;
  }
  .xxl-text {
    font-size: 2em !important;
  }

  a {
    text-decoration: none;
  }
</style>

<style>
  /*.content-background {
    background-color: #f8f8f8;
  }*/
  .text-danger {
    color: #bc3343;
  }
  .text-success {
    color: #42b983;
  }
  .text-small {
    font-size: 0.8rem;
  }
  .mw-100 {
    max-width: 100% !important;
  }
  .w-100 {
    width: 100% !important;
  }
  .w-90 {
    width: 90% !important;
  }
  .w-80 {
    width: 80% !important;
  }
  .w-70 {
    width: 70% !important;
  }
  .w-60 {
    width: 60% !important;
  }
  .w-50 {
    width: 50% !important;
  }
  .w-40 {
    width: 40% !important;
  }
  .w-30 {
    width: 30% !important;
  }
  .w-25 {
    width: 25% !important;
  }
  .w-20 {
    width: 20% !important;
  }
  .w-15 {
    width: 15% !important;
  }
  .w-10 {
    width: 10% !important;
  }
  .w-0 {
    width: 0% !important;
  }
  .favicon-img {
    border-radius: 5px;
  }
  .product-image {
    height: 80px;
    background-size: cover;
    margin: 10px 0px;
  }
  .simple-table-row-height {
    height: 55px;
  }

  .v-data-table td,
  .v-data-table th,
  .v-data-iterator .v-list {
    font-size: 1rem !important;
  }
  .v-data-iterator .v-card__title,
  .v-data-table th {
    font-weight: 900 !important;
  }
  .v-data-iterator .v-card__title {
    font-size: 1.4rem !important;
  }
  .cursor-pointer {
    cursor: pointer !important;
  }

  .not_found_product {
    background: #ffd2d2 !important;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(70, 76, 91, 0.8);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(70, 76, 91, 1);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(70, 76, 91, 0.6);
  }

  .v-data-table__wrapper > table > tbody > tr:hover {
    background: inherit !important;
  }
  .mainNavBar {
    box-shadow: none !important;
  }
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: none;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
</style>
