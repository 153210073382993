<template>
  <v-card flat>
    <v-card-text class="pb-0">
      <div>
        <productForm
          v-for="(element, index) in products"
          :key="element.id"
          :class="index > 0 ? 'mt-6' : ''"
          :element="element"
          :index="index"
          :update="false"
          @delete-event="remove(index)"
        />
      </div>
      <div class="mt-4">
        <v-row>
          <v-col cols="3">
            <v-btn text @click="addProductForm()"> <v-icon left>mdi-plus</v-icon> Add more </v-btn>
          </v-col>
          <v-col cols="6">
            <div class="d-flex ml-auto align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span class="mr-2" v-bind="attrs" v-on="on"> Enable auto discovery </span>
                </template>
                <span>
                  By activating auto-discovery, our system will search for possible matched products
                  automatically.
                </span>
              </v-tooltip>
              <v-switch
                dense
                hide-details="true"
                class="mt-0"
                color="error"
                v-model="autoDiscovery"
              ></v-switch>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeModal">cancel</v-btn>
      <submitButton
        :submitFunction="save"
        :submit_loading="submit_loading"
        color="secondary"
        text="Looks good, next"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
  import axios from 'axios'

  export default {
    data() {
      return {
        ref: '',
        urls: '',
        products: [],
        submit_loading: false,
        autoDiscovery: false,
      }
    },
    props: ['hide'],
    methods: {
      closeModal() {
        this.$emit('close-model')
      },
      initialise_data() {
        this.products = [
          {
            id: 'P1',
            ref: '',
            ean: '',
            brand: '',
            model: '',
            category: '',
            urls: '',
            cost: 0,
          },
        ]
        this.autoDiscovery = false
      },
      save() {
        this.submit_loading = true
        let data = []
        this.products.map((elt) => {
          let temp = elt.urls.split('\n')
          temp = temp.map((element) => {
            return { url: element }
          })
          data.push({
            ref: elt.ref,
            ean: elt.ean,
            model: elt.model,
            brand: elt.brand,
            category: elt.category,
            urls: temp,
            cost: elt.cost ? parseFloat(elt.cost) : 0.0,
            id: elt.id,
            checked: false,
          })
        })
        data.map((elt) => delete elt.checked)
        axios
          .post('/api/importation/addproduts', {
            params: {
              account_id: localStorage.getItem('account_id'),
              user_id: localStorage.getItem('user_id'),
              products: data,
              autoDiscovery: this.autoDiscovery,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            this.initialise_data()
            this.closeModal()
            if (res.data.status) {
              this.$emit('show-notification', {
                msg: 'The importation request has been successfully uploaded, it will be processed soon',
                type: 'success',
              })
            } else {
              this.$emit('show-notification', {
                msg: res.data.msg,
                type: 'error',
              })
              this.error_msg = res.data.msg
            }
          })
          .catch(function () {
            console.log('FAILURE!!')
          })
          .finally(() => {
            this.submit_loading = false
          })
      },
      addProductForm() {
        this.products.push({
          id: 'P' + (this.products.length + 1),
          ref: '',
          url: '',
          ean: '',
          brand: '',
          model: '',
          category: '',
        })
      },
      remove(index) {
        if (this.products.length > 1) this.products.splice(index, 1)
        else this.initialise_data()
      },
    },
    mounted() {
      this.initialise_data()
    },
    watch: {
      hide: {
        deep: true,
        handler() {
          this.initialise_data()
        },
      },
    },
  }
</script>
<style>
  .product-num {
    margin: 0px;
    font-size: 14pt;
  }
</style>
