<template>
  <v-row>
    <v-col cols="12" sm="12" md="12" class="ma-0">
      <v-checkbox
        dense
        hide-details
        class="ma-0 font-weight-medium"
        label="Select all websites"
        v-model="select_all"
        @change="selectAll"
      >
      </v-checkbox>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      md="4"
      class="ma-0 website_select"
      v-for="(website, index) in websites"
      :key="index"
    >
      <v-checkbox dense hide-details class="ma-0" v-model="website.checked">
        <template v-slot:label>
          <div class="d-flex align-center">
            <img
              :src="
                tuto
                  ? require(`../../assets/favicon_1.png`)
                  : 'https://8181.integ.crawlo.com/favicon?size=xs&website=' +
                    website.url
              "
              @error="replaceByDefault"
              width="30"
              height="30"
              class="mr-2 favicon-img"
            />{{ website.alias }}
          </div>
        </template>
      </v-checkbox>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      select_all: true,
      headers: [
        { text: "Select", value: "select", width: "1%", sortable: false },
        { text: "Icon", value: "icon", width: "1%", sortable: false },
        { text: "Websites From Excel File", value: "alias" },
      ],
    };
  },
  props: ["websites", "tuto"],
  components: {},
  methods: {
    selectAll() {
      this.websites.map((elt) => {
        elt.checked = this.select_all;
      });
    },
  },
  watch: {
    websites: {
      handler() {
        let all_checked = true;
        for (let i = 0; i < this.websites.length; i++) {
          if (!this.websites[i].checked) {
            all_checked = false;
            break;
          }
        }
        this.select_all = all_checked;
      },
      deep: true,
    },
  },
};
</script>
