import Home from "./Home/Home.vue";
import About from "./About/About.vue";
import Login from "./Login/Login.vue";
import Pricing from "./Pricing/Pricing.vue";
import EmailInsert from "./ForgotPassword/EmailInsert.vue";
import ResetPassword from "./ForgotPassword/ResetPassword.vue";
import Importations from "./Importation/Importation.vue";
import Settings from "./Settings/Settings.vue";
import Compare from './Compare/Compare.vue'
import Subscription from './Subscribe/Subscription.vue'
import Success from './RegistrationSuccess/Success.vue'
import Billing from './Billing/Billing'
import Privacy from './Privacy/Privacy'
import TermsConditions from './TermsConditions/TermsConditions'
import Exportations from './Exportation/exportation.vue';
import Error from './Error/Error.vue';
import Unauthorized from './Unauthorized/Unauthorized.vue';
import NotFound from './NotFound/NotFound.vue';
import Alerts from './Alerts/alerts.vue';
import FavoriteProducts from './FavoriteProduct/favoriteProducts.vue';
import CompetitorPriceComparison from './CompetitorPriceComparison/competitorPriceComparison.vue';
import Tutorial from './Importation/tutorial.vue';
import ExclusiveProducts from './ExclusiveProducts/ExclusiveProducts.vue';
import Administration from './Administration/administration.vue';

export default {
  Home,
  About,
  Login,
  Pricing,
  EmailInsert,
  ResetPassword,
  Importations,
  Settings,
  Compare,
  Subscription,
  Success,
  Billing,
  Privacy,
  TermsConditions,
  Exportations,
  NotFound,
  Unauthorized,
  Error,
  Alerts,
  FavoriteProducts,
  CompetitorPriceComparison,
  Tutorial,
  ExclusiveProducts,
  Administration
};
