<template>
  <v-btn
    :loading="submit_loading"
    :disabled="submit_loading"
    :color="color"
    class="white--text"
    @click="submitFunction"
    :text="just_text"
    :small="small"
  >
    {{ text }}
  </v-btn>
</template>
<script>
export default {
  name: "submitButton",
  props: ["submitFunction", "submit_loading", "text", "color", "just_text","small"],
};
</script>
