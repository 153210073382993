<template>
  <v-dialog v-model="dialog" eager max-width="85%">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="8">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="d-inline-block text-truncate md-text secondary--text"
                  style="max-width: 500px"
                >
                  {{ selectedProduct.name }}
                </span>
              </template>
              <span>{{ selectedProduct.name }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  placeholder="select a range"
                  prepend-icon="mdi-calendar-range-outline"
                  readonly
                  dense
                  hide-details
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                v-show="!months_btn"
                v-model="dates"
                :max="max_date"
                :min="min_date"
                no-title
                range
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="changeRange"> OK </v-btn>
              </v-date-picker>

              <v-date-picker
                v-show="months_btn"
                v-model="dates"
                :max="max_date"
                :min="min_date"
                no-title
                range
                scrollable
                type="month"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="changeRange"> OK </v-btn>
              </v-date-picker>
            </v-menu>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="grey" icon light @click="reset" class="mx-2">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh the chart</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-btn icon class="ml-4" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <!-- <v-card-title>
        <span class="md-text secondary--text">{{
          this.selectedProduct.name
        }}</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title> -->

      <v-card-text class="mt-4">
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-row style="min-height: 430px">
                <v-col cols="3" class="d-flex justify-center" :key="loadKey">
                  <img
                    :src="this.selectedProduct.image"
                    @error="getCompetitorImage($event, selected_product)"
                    class="align-self-center mw-100"
                    style="object-fit: cover; object-position: center; max-height: 430px"
                  />
                </v-col>
                <v-col cols="9" class="d-flex">
                  <loadingProgress :loading="loading" text="loading chart data..." />
                  <v-row v-show="!loading">
                    <v-col cols="12" class="pt-0">
                      <v-row>
                        <v-col cols="5"></v-col>
                        <v-col cols="12" md="2" class="d-flex justify-center">
                          <v-btn-toggle v-model="btns_group" color="primary" borderless>
                            <v-btn small value="Days" @click="changeInterval('day')">
                              <span class="hidden-sm-and-down">Days</span>
                            </v-btn>

                            <v-btn small value="Months" @click="changeInterval('month')">
                              <span class="hidden-sm-and-down">Months</span>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                        <v-col cols="5" class="pr-4 d-flex">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="ml-auto mr-4"
                                icon
                                small
                                color="primary"
                                @click="saveAsImage"
                              >
                                <v-icon dark> $download </v-icon>
                              </v-btn>
                            </template>
                            <span>save chart as image</span>
                          </v-tooltip>

                          <v-menu open-on-hover bottom offset-y class="mr-4">
                            <template v-slot:activator="{ on: menu }">
                              <v-btn v-on="{ ...menu }" outlined small color="primary">
                                Export chart
                                <v-icon right dark> $file_download </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item @click="exportChart(false)">
                                <v-list-item-title>Download Data in range</v-list-item-title>
                              </v-list-item>
                              <v-list-item @click="exportChart(true)">
                                <v-list-item-title>Download Full Data</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <canvas
                        class="align-self-center"
                        id="historiqueChart"
                        width="500"
                        height="170"
                      ></canvas>
                    </v-col>
                    <v-col cols="12">
                      <v-range-slider
                        v-model="range"
                        :max="max"
                        :min="min"
                        hide-details
                        class="align-center"
                        track-color="primary"
                        color="green"
                        @input="rangeChanged()"
                      >
                        <template v-slot:prepend>
                          <span style="width: 100px" class="text-right">{{
                            labels[range[0]]
                          }}</span>
                        </template>
                        <template v-slot:append>
                          <span style="width: 100px">{{ labels[range[1]] }}</span>
                        </template>
                      </v-range-slider>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="12" v-if="website == 'sfr.fr'">
                  <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="9" class="d-flex justify-center">
                      <v-btn-toggle v-model="tables_btns_group" color="primary" borderless>
                        <v-btn small value="Prices" @click="show_prices = true">
                          <span class="hidden-sm-and-down">Prices</span>
                        </v-btn>

                        <v-btn small value="Engagements" @click="show_prices = false">
                          <span class="hidden-sm-and-down">Engagements</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3" class="d-flex pt-0">
                  <v-simple-table class="w-100">
                    <template v-slot:default>
                      <tbody v-html="infoSectionHtml"></tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="9" class="pt-0">
                  <productsLastStatCustom
                    v-if="user_website.id == source_id"
                    v-show="show_prices"
                    :product_match="this.selected_product"
                  />
                  <productsLastStat
                    v-else
                    v-show="show_prices"
                    :product_match="this.selected_product"
                  />

                  <div v-show="!show_prices">
                    <offerPlansTable :competitors="offer_engagements" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import Chart from 'chart.js'
  import productsLastStat from './productsLastStat'
  import productsLastStatCustom from './productsLastStatCustom'
  import offerPlansTable from './offerPlansTable'
  import moment from 'moment'
  import axios from 'axios'
  import XLSX from 'xlsx/xlsx.mini'
  export default {
    components: {
      productsLastStatCustom,
      productsLastStat,
      offerPlansTable,
    },
    props: ['historgram_data', 'selected_product', 'show', 'component_name'],
    mounted() {
      this.initHistogramChart()
    },
    data() {
      return {
        loadKey: 0,
        account_id: localStorage.getItem('account_id'),
        website: localStorage.getItem('website_alias'),
        user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
        source_id: '6019aee744817b0f85ff85bb',
        min: 0,
        max: 100,
        range: [0, 100],
        labels: [],
        show_prices: true,
        chartData: {
          data: {
            labels: [],
            datasets: [],
          },
        },
        selectedProduct: {
          name: '',
          url: '',
          ean: '',
          brand: '',
          price: '',
          high: '',
          low: '',
          availability: true,
        },
        dates: [],
        months_dates: [],
        menu: false,
        no_data: false,
        interval: 'day',
        loading: true,
        infoSectionHtml: '',
        historiqueChart: '',
        dialog: false,
        btns_group: 'Days',
        tables_btns_group: 'Prices',
        from: '0',
        to: '0',
        offer_engagements: [],
        allowed_start_date: 0,
        months_btn: false,
        max_date: '',
        min_date: '',
      }
    },
    computed: {
      dateRangeText() {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
      getCompetitorImage(e, match) {
        let image = ''
        const website = Object.keys(match).find(
          (key) => match[key].product && match[key].product.image && match[key].product.validated,
        )
        if (website) image = match[website].product.image
        e.target.src = image
      },
      async changeRange() {
        this.$refs.menu.save(this.dates)
        let temp1, temp2

        if (this.dates.length) {
          temp1 = this.dates[0].split('-')
          let x = new Date(
            Date.UTC(temp1[0], temp1[1] - 1, temp1[2] != undefined ? temp1[2] : 1, 0, 0, 0),
          )
          temp2 = this.dates[1] == undefined ? this.dates[0].split('-') : this.dates[1].split('-')
          let y = new Date(
            Date.UTC(temp2[0], temp2[1] - 1, temp2[2] != undefined ? temp2[2] : 1, 22, 0, 0),
          )
          if (+x <= +y) {
            this.from = x.getTime()
            this.to = y.getTime()
          } else {
            y.setHours(0)
            x.setHours(22)
            this.from = y.getTime()
            this.to = x.getTime()
          }
        } else {
          this.to = 0
          this.from = 0
        }

        this.loading = true
        this.no_data = false
        await this.getOfferHistorique()
        this.loading = false
      },
      reset() {
        this.dates = []
        this.changeRange()
      },
      rangeChanged() {
        let min_index = this.chartData.data.labels.indexOf(this.labels[this.range[0]])
        let max_index = this.chartData.data.labels.indexOf(this.labels[this.range[1]]) + 1

        this.historiqueChart.data.labels = this.chartData.data.labels.slice(min_index, max_index)
        let datasets = []
        this.chartData.data.datasets.forEach((dataset) => {
          let temp = { ...dataset }
          delete temp._meta
          temp.data = temp.data.slice(min_index, max_index)
          datasets.push(temp)
        })
        this.historiqueChart.data.datasets = datasets
        this.historiqueChart.update()
      },
      exportChart() {
        let data_to_export = []
        this.historiqueChart.data.labels.map((elt, index) => {
          let websites = { date: elt }
          this.historiqueChart.data.datasets.map((dataset) => {
            websites[dataset.label] = dataset.data[index]
          })
          data_to_export.push(websites)
        })
        let workSheet = XLSX.utils.json_to_sheet(data_to_export)
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, workSheet, 'chart data')
        XLSX.writeFile(wb, `chart_export_${this.selectedProduct.name}.xlsx`)
      },
      async initialisation() {
        this.dates = []
        this.loading = true
        this.initSelectedProduct()
        this.getInfoSections()
        await this.getOfferHistorique()
        this.loading = false
        this.getEngagements()
      },
      changeInterval(value) {
        this.dates = []
        this.interval = value
        this.months_btn = value == 'month'
      },
      async getEngagements() {
        await axios
          .get('/api/products/getengagements', {
            params: {
              account: this.account_id,
              id_match: this.selected_product.match_id,
            },
          })
          .then((res) => {
            this.offer_engagements = res.data
          })
      },
      async setUserWebsites() {
        await axios
          .get('/api/websites/list', {
            params: {
              account: this.account_id,
            },
          })
          .then((res) => {
            localStorage.setItem(`user_website`, JSON.stringify(res.data.account.website))
            localStorage.setItem(`user_websites`, JSON.stringify(res.data.account.websites))
          })
      },
      async getOffers(out_dated) {
        return await axios
          .get(`/api/products/history`, {
            params: {
              interval: this.interval,
              account: this.account_id,
              user: localStorage.getItem('user_id'),
              match: this.selected_product.match_id,
              component: this.component_name,
              from: this.from,
              to: this.to,
            },
          })
          .then((res) => {
            Object.keys(out_dated).map((key) => {
              if (res.data.websiteData[key]) {
                let from = res.data.labels.indexOf(out_dated[key]) + 1
                let to = res.data.websiteData[key].length - from
                res.data.websiteData[key].splice(from, to)
              }
            })
            return res.data
          })
      },
      validateLastChangedDate() {
        let out_dated_product = {}
        Object.keys(this.selected_product).forEach((key) => {
          if (this.selected_product[key].product) {
            let update_date = this.selected_product[key].product.offers[0].updateDate.split(' ')[0]
            if (update_date.indexOf(moment().format('YYYY-MM-DD')) == -1)
              out_dated_product[key] = update_date
          }
        })
        return out_dated_product
      },
      // this function used to get offers changes over time
      async getOfferHistorique() {
        let out_dated = this.validateLastChangedDate()
        //fetch if the are any new websites for this account
        this.setUserWebsites()
        this.chartData.data.datasets = []
        this.chartData.data.labels = []
        const dataResponse = await this.getOffers(out_dated)
        this.allowed_start_date = dataResponse.first_date
        //insert datasets for chartjs
        this.chartData.data.labels = dataResponse.labels

        Object.keys(dataResponse.websiteData).forEach((key) => {
          const websiteColorTest = JSON.parse(localStorage.user_websites).filter(
            (w) => w.alias == key,
          )
          let websiteColor = JSON.parse(localStorage.user_websites).filter((w) => w.alias == key)
          websiteColor = websiteColor.length ? websiteColor[0].config.color : ''
          const dataset = {
            label: key,
            data: dataResponse.websiteData[key],
            fill: false,
            steppedLine: true,
            borderColor: websiteColor,
            backgroundColor: websiteColor,
            borderWidth: 1,
          }
          this.chartData.data.datasets.push(dataset)
        })

        this.historiqueChart.data.labels = this.chartData.data.labels

        this.labels = this.chartData.data.labels.map((elt) => elt.split(' ')[0])
        this.max = this.labels.length - 1
        this.range = [0, this.labels.length]
        this.historiqueChart.data.datasets = this.chartData.data.datasets
        this.historiqueChart.update()
        this.max_date = moment(Date.now()).format('YYYY-MM-DD')
        this.min_date = moment(this.allowed_start_date).format('YYYY-MM-DD')
      },
      // this function to init Histroque chart
      initHistogramChart() {
        let ctx = 'historiqueChart'
        this.historiqueChart = new Chart(ctx, {
          type: 'line',
          data: this.chartData,
          options: {
            animation: {
              duration: 0,
            },
            scales: {
              responsive: true,
              yAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    beginAtZero: false,
                  },
                },
              ],
            },
          },
        })
      },
      buildImageSrc(item) {
        if (item && item.match(/data:image\/.*/g)) return item.match(/data:image\/.*/g)[0]
        return item
      },
      initSelectedProduct() {
        this.loadKey++
        this.selectedProduct.name = this.selected_product.product_name
        this.selectedProduct.url = this.selected_product.product_url
        this.selectedProduct.image = this.buildImageSrc(this.selected_product.product_image)
        this.selectedProduct.high = this.selected_product.hight_price
        this.selectedProduct.low = this.selected_product.low_price
        this.selectedProduct.price = this.selected_product.product_offer.price
        this.selectedProduct.brand = this.selected_product.product_brand
      },
      getInfoSections() {
        let infoSection = ``
        let price = ``
        let hight = ``
        let low = ``

        Object.keys(this.selectedProduct).map((key) => {
          switch (key) {
            case 'brand':
              infoSection = infoSection.concat(`
                        <tr>
                            <td class="md--text">${key.toUpperCase()} : </td>
                            <td class="text--secondary font-weight-black">${this.selectedProduct[
                              key
                            ].toUpperCase()}</td>
                        </tr>`)
              break
            case 'price':
              //formating price later, each region with a deffirent formatter
              //text colors changed when the price is higher or lower
              price = this.selectedProduct[key]
              infoSection = infoSection.concat(`
                        <tr class="simple-table-row-height md--text">
                            <td>My Price : </td>
                            <td class="text--secondary font-weight-bold">${
                              price ? this.singlePriceHandler(price) : 'Not found'
                            }</td>
                        </tr>`)
              break
            case 'high':
              hight = this.selectedProduct.high
              infoSection = infoSection.concat(`
                        <tr class="simple-table-row-height md--text">
                            <td>Highest Price : </td>
                            <td class="error--text font-weight-bold">${
                              hight ? this.singlePriceHandler(hight) : 'Not found'
                            }</td>
                        </tr>`)
              break
            case 'low':
              low = this.selectedProduct.low
              infoSection = infoSection.concat(`
                        <tr class="simple-table-row-height md--text">
                            <td>Lowest Price : </td>
                            <td class="success--text font-weight-bold">${
                              low ? this.singlePriceHandler(low) : 'Not found'
                            }</td>
                        </tr>`)
              break
          }
        })
        this.infoSectionHtml = infoSection
      },
      saveAsImage() {
        var url_base64jp = document.getElementById('historiqueChart').toDataURL('image/jpg')

        let link = document.createElement('a')
        link.href = url_base64jp
        link.setAttribute('download', this.selectedProduct.name + '_chart.jpg')
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
    },
    watch: {
      interval: async function (newValue, oldValue) {
        this.loading = true
        this.to = 0
        this.from = 0
        this.dates = []
        await this.getOfferHistorique()
        this.loading = false
      },
      selected_product: {
        handler() {},
      },
      show: {
        handler() {
          this.to = 0
          this.from = 0
          this.dates = []
          this.initialisation()
          this.dialog = true
        },
      },
    },
  }
</script>
<style scoped>
  .product-image {
    width: 250px;
    height: 250px;
    background-size: cover;
  }
  .responsive {
    width: 100%;
    height: auto;
  }
</style>
