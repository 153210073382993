<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <router-link to="/importation">
          <v-btn icon class="mr-4">
            <v-icon>$back</v-icon>
          </v-btn>
        </router-link>
        <span class="md-text">Tutorial</span>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-container class="pb-0">
          <v-row>
            <v-col cols="12" class="d-flex justify-center text-center">
              <span class="ma-0 w-70 md-text">
                here you will know the whole importation process also how to prepare you
                importation, following the steps, you will be able to do the import. this tutorial
                may take some time, because it has all the import parts and all information that you
                will need, just be patient and try to finish it, good luck
              </span>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn outlined light large color="primary" @click="startTuto"
                >Start tutorial
                <v-icon class="ml-4" right large dark>mdi-play-circle-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-card class="w-80" outlined>
                <v-card-title>
                  <span class="md-text">Importations List</span>

                  <div class="ml-auto d-flex flex-column mr-4 statistics-place">
                    <span
                      :class="inserted == limit ? 'error-color' : ''"
                      class="ma-0 text-right sm-text"
                    >
                      Matches : {{ inserted }} / {{ limit }}
                    </span>

                    <v-progress-linear
                      height="15"
                      style="width: 250px"
                      :value="limit_percentage"
                      :color="limit_progress_color"
                      bottom
                      rounded
                      reactive
                    >
                    </v-progress-linear>
                  </div>

                  <v-btn outlined light color="primary" class="import-btn"
                    >Upload new products file
                    <v-icon right dark>mdi-database-import</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-data-table
                      class="w-100 importation-table"
                      :headers="data.headers"
                      :items="data.items"
                      :footer-props="{
                        'items-per-page-options': [],
                        showFirstLastPage: true,
                      }"
                      :server-items-length="data.count"
                      :items-per-page="10"
                      item-class="import_rows"
                    >
                      <template v-slot:item.status="{ item }">
                        <v-tooltip
                          bottom
                          :disabled="
                            item.status != 'uncompleted' &&
                            item.status != 'suspending' &&
                            item.status != 'error'
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              <badge
                                :text="item.status == 'done' ? 'success' : item.status"
                                :color="badgeColor(item.status)"
                                :progress="item.status == 'pending' || item.status == 'processing'"
                                cursor="help"
                              />
                            </span>
                          </template>
                          <span>{{ item.msg }}</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:item.errors="{ item }">
                        <v-tooltip bottom :disabled="item.status != 'uncompleted'">
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              <badge
                                :text="item.errors"
                                :color="item.errors > 0 ? 'red' : 'grey'"
                                small="true"
                                cursor="pointer"
                              />
                            </span>
                          </template>
                          <span>Click to handle Errors</span>
                        </v-tooltip>
                      </template>

                      <template v-slot:item.action="{ item }">
                        <v-tooltip
                          bottom
                          v-if="
                            item.status != 'uncompleted' &&
                            item.status != 'pending' &&
                            item.type_num != '2'
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn v-on="on" icon small>
                              <v-icon> mdi-download </v-icon>
                            </v-btn>
                          </template>
                          <span>Download Origin</span>
                        </v-tooltip>

                        <v-menu
                          open-on-hover
                          bottom
                          offset-y
                          v-if="item.status == 'uncompleted' && item.type_num != '2'"
                        >
                          <template v-slot:activator="{ on: menu }">
                            <v-btn class="action-icon" v-on="{ ...menu }" small icon>
                              <v-icon> mdi-download </v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item>
                              <v-list-item-title>Download Origin</v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                              <v-list-item-title>Download Errors</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              icon
                              small
                              :class="item.status == 'suspending' ? 'display' : 'hide'"
                            >
                              <v-icon> mdi-alert-box </v-icon>
                            </v-btn>
                          </template>
                          <span>Confirm Insertion</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-card class="w-60 import-modal-file">
                <v-card-title>
                  <span class="md-text">Import setting</span>
                  <div class="ml-auto d-flex flex-column mr-4">
                    <span
                      :class="inserted == limit ? 'error-color' : ''"
                      class="ma-0 text-right sm-text"
                    >
                      Matches : {{ inserted }} / {{ limit }}
                    </span>

                    <v-progress-linear
                      height="15"
                      style="width: 250px"
                      :value="limit_percentage"
                      :color="limit_progress_color"
                      bottom
                      rounded
                      reactive
                    >
                    </v-progress-linear>
                  </div>
                  <v-btn icon>
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pa-0">
                  <v-container class="pb-0">
                    <v-row>
                      <v-tabs grow v-model="active_tab" id="import-file-tab">
                        <v-tab class="tab-import-file"> (File) Adding Multiple Products </v-tab>
                        <v-tab class="tab-import-form"> Add one product at time </v-tab>

                        <v-tab-item>
                          <v-card flat>
                            <v-card-text class="pa-8 pb-0">
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
                                    <fileUpload disable="true" class="file-place" />
                                  </v-col>
                                  <v-col cols="6" class="ma-0 pa-0">
                                    <div v-if="websites_items.length > 0" class="account-website">
                                      <v-combobox
                                        outlined
                                        :items="websites_items"
                                        label="Select your website"
                                        dense
                                        hide-details
                                      >
                                        <template v-slot:selection="{ item }">
                                          <div class="pt-2 pb-1 d-flex align-center">
                                            <img
                                              :src="require(`../../assets/favicon_1.png`)"
                                              width="25"
                                              height="25"
                                              class="mr-2"
                                              ref="myImg"
                                            />
                                            {{ item }}
                                          </div>
                                        </template>
                                        <template v-slot:item="{ item }">
                                          <img
                                            :src="require(`../../assets/favicon_1.png`)"
                                            width="30"
                                            height="30"
                                            class="mr-2"
                                            ref="myImg"
                                          />
                                          {{ item }}
                                        </template>
                                      </v-combobox>
                                    </div>
                                  </v-col>
                                  <v-col cols="6" class="ma-0 pa-0"> </v-col>
                                  <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 pt-4">
                                    <div v-if="websites.length > 0" class="websites-place">
                                      <div>
                                        <h3 class="ma-0">
                                          We have found these websites in your file
                                        </h3>
                                        <p class="text--secondary ma-0">
                                          Uncheck to exclude website
                                        </p>
                                      </div>

                                      <div>
                                        <websitesTablePreview :websites="websites" tuto="true" />
                                      </div>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text>cancel</v-btn>
                              <submitButton
                                class="submit_button1"
                                color="secondary"
                                text="Looks good, next"
                                :submitFunction="blankFunction"
                              />
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                        <v-tab-item>
                          <v-card flat>
                            <v-card-text>
                              <div>
                                <v-card class="import-form-card">
                                  <v-card-text class="pt-0">
                                    <v-row>
                                      <v-col cols="12" class="pb-0 d-flex align-center">
                                        <span class="product-num">Product 1</span>
                                        <v-btn icon class="ml-auto form-delete-btn">
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <v-text-field
                                          disabled
                                          value="1000086"
                                          class="form-ref-place"
                                          label="Your product Reference (optional)"
                                          dense
                                          hide-details
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        URLs of you competitors (Each url in a new line)
                                        <codemirror
                                          v-model="errors_info1"
                                          :options="options"
                                          class="codemirror-input codemirror-not-full form-urls-place"
                                        ></codemirror>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>

                                <v-card class="mt-6">
                                  <v-card-text class="pt-0">
                                    <v-row>
                                      <v-col cols="12" class="pb-0 d-flex align-center">
                                        <span class="product-num">Product 2</span>
                                        <v-btn icon class="ml-auto form-delete-btn">
                                          <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        <v-text-field
                                          disabled
                                          value="1000087"
                                          class="form-ref-place"
                                          label="Your product Reference (optional)"
                                          dense
                                          hide-details
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" class="pt-0">
                                        URLs of you competitors (Each url in a new line)
                                        <codemirror
                                          v-model="errors_info2"
                                          :options="options"
                                          class="codemirror-input codemirror-not-full form-urls-place"
                                        ></codemirror>
                                      </v-col>
                                    </v-row>
                                  </v-card-text>
                                </v-card>
                              </div>
                              <div style="margin-top: 15px">
                                <v-btn class="form-add-btn" text>
                                  <v-icon left>mdi-plus</v-icon> Add more
                                </v-btn>
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="primary" text>cancel</v-btn>
                              <submitButton
                                class="submit_button2"
                                color="secondary"
                                text="Looks good, next"
                                :submitFunction="blankFunction"
                              />
                            </v-card-actions>
                          </v-card>
                        </v-tab-item>
                      </v-tabs>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <v-card class="confirm-insertion-modal">
                <v-card-title>
                  <span class="md-text">Importation Confirmation</span>
                  <v-btn icon class="ml-auto">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-alert type="warning" class="mb-0 mt-4 font-weight-bold delete-text">
                    you have reached the limits, you want to insert 22 but you still just have 10 in
                    your account limits.
                    <br />
                    Confirm to insert till the limit, or rollback the importation
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text>cancel</v-btn>
                  <v-btn color="error" class="rollback-btn">Rollback !</v-btn>
                  <v-btn color="secondary" class="confirm-btn">Yes, import it !</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-card class="errors-handler">
                <v-card-title>
                  <span class="md-text">Urls errors handler</span>
                  <v-btn icon class="ml-auto">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pb-0">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 pb-2">
                        <v-card class="mt-6">
                          <v-card-text class="pt-0">
                            <v-row>
                              <v-col cols="12" class="pb-0 d-flex align-center">
                                <span class="product-num">Product 1</span>
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                <v-text-field
                                  disabled
                                  value="1000086"
                                  class="form-ref-place"
                                  label="Your product Sku (optional)"
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                URLs of you competitors (Each url in a new line)
                                <codemirror
                                  v-model="errors_info1"
                                  :options="options"
                                  class="codemirror-input codemirror-not-full form-urls-error-place"
                                ></codemirror>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>

                        <v-card class="mt-6">
                          <v-card-text class="pt-0">
                            <v-row>
                              <v-col cols="12" class="pb-0 d-flex align-center">
                                <span class="product-num">Product 2</span>
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                <v-text-field
                                  disabled
                                  value="1000087"
                                  class="form-ref-place"
                                  label="Your product Reference (optional)"
                                  dense
                                  hide-details
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="pt-0">
                                URLs of you competitors (Each url in a new line)
                                <codemirror
                                  v-model="errors_info2"
                                  :options="options"
                                  class="codemirror-input codemirror-not-full form-urls-place"
                                ></codemirror>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text class="mr-2">Cancel</v-btn>
                  <submitButton
                    class="error-save-button"
                    :submitFunction="blankFunction"
                    text="Save"
                    color="secondary"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <v-tour name="myTour" :options="{ highlight: true }" :steps="steps"> </v-tour>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import fileUpload from '../common/fileUpload.vue'
  import websitesTablePreview from './websitesTablePreview.vue'
  export default {
    name: 'tutorialModal',
    props: ['show'],
    components: {
      fileUpload,
      websitesTablePreview,
    },
    data() {
      return {
        dialog: false,
        active_tab: 0,
        inserted: 67,
        limit: 1000,
        errors_info1:
          'my-website.com/product/product-name1/\nhttps://my-website/product/product-name2/',
        errors_info2: 'httpsmy-website2.com/product/product-name2/',
        data: {
          count: 7,
          headers: [
            { text: 'Importations', value: 'filename', sortable: false },
            { text: 'Date', value: 'date', align: 'center', sortable: false },
            { text: 'Type', value: 'type', align: 'center', sortable: false },
            {
              text: 'Processed',
              value: 'processed',
              align: 'center',
              sortable: false,
              width: '1%',
            },
            {
              text: 'Added',
              value: 'added',
              align: 'center',
              sortable: false,
              width: '1%',
            },
            {
              text: 'Errors',
              value: 'errors',
              align: 'center',
              sortable: false,
              width: '1%',
            },
            {
              text: 'Status',
              value: 'status',
              align: 'center',
              sortable: false,
              width: '1%',
            },
            {
              text: 'Actions',
              value: 'action',
              align: 'center',
              sortable: false,
            },
          ],
          items: [
            {
              id: '7A81jXEBASNa7FdwDKyGf',
              filename: '20200418-131324_1587212004238',
              date: '18/04/2020 13:13:24',
              status: 'processing',
              msg: '',
              type: 'Import using form',
              type_num: '2',
              processed: 0,
              added: 0,
              errors: 0,
            },
            {
              id: '7A81jXEBASNaIYwDKyGf',
              filename: '20200418-131324_1587212004238',
              date: '18/04/2020 13:13:24',
              status: 'pending',
              msg: '',
              type: 'Import using file',
              type_num: '1',
              processed: 0,
              added: 0,
              errors: 0,
            },
            {
              id: '6w_ig3EBASNaIYwDTSH-',
              filename: '20200416-174618_1587055578618.xlsx',
              date: '16/04/2020 17:46:18',
              status: 'error',
              msg: 'You reached the limit, please upgrade',
              type: 'Import using form',
              type_num: '2',
              processed: 12,
              added: 0,
              errors: 0,
            },
            {
              id: '6g_ig3EBASNaIYwDFCFI',
              filename: '20200416-174603_1587055563847',
              date: '16/04/2020 17:46:03',
              status: 'suspending',
              msg: 'you have reached the limits, you want to insert 22 but you still just have 10 in your account limits.',
              type: 'Import using form',
              type_num: '2',
              processed: 5,
              added: 0,
              errors: 0,
            },
            {
              id: '6Q_bg3EBASNaIYwDBiH9',
              filename: '20200416-173821_1587055101671.xlsx',
              date: '16/04/2020 17:38:21',
              status: 'uncompleted',
              msg: 'Matches well inserted but there is some matches has urls that has not been validated',
              type: 'Import using file',
              type_num: '1',
              processed: 32,
              added: 29,
              errors: 3,
            },
            {
              id: '6Q_bg3EBASNawDUHBiH9',
              filename: '20200416-173821_1587055101671.xlsx',
              date: '16/04/2020 17:38:21',
              status: 'done',
              msg: '',
              type: 'Import using form',
              type_num: '2',
              processed: 22,
              added: 22,
              errors: 0,
            },
            {
              id: '6Q_bgUIjASNaIYwDBiH9',
              filename: '20200416-173821_1587055101671.xlsx',
              date: '16/04/2020 17:38:21',
              status: 'done',
              msg: '',
              type: 'Import using file',
              type_num: '1',
              processed: 16,
              added: 16,
              errors: 0,
            },
          ],
        },
        websites: [
          { alias: 'my-website.com', checked: true },
          { alias: 'my-competitor1.com', checked: true },
          { alias: 'my-competitor2.com', checked: true },
          { alias: 'mycompetitor3.com', checked: true },
        ],
        websites_items: [
          'my-website.com',
          'my-competitor1.com',
          'my-competitor2.com',
          'mycompetitor3.com',
        ],
        first_website_exlude_website: '',
        steps: [
          {
            target: '.import-btn',
            content: `If you want to start an import process, you should click to the "Upload new products file" button`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -300,
            duration: 200,
          },
          {
            target: '.import-modal-file',
            content: `when you clicked on the "Upload new products file" button, this form will reveal`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '#import-file-tab>.v-item-group',
            content: `The import form has two parts, the first part is import by file`,
            params: {
              placement: 'bottom',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.tab-import-file',
            content: `Let's start with import by file`,
            params: {
              placement: 'bottom',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.file-place',
            content: `To upload the file, drop it here, or click on it to choose the file that you want to import`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -250,
            duration: 200,
          },
          {
            target: '.account-website',
            content: `Here you can choose the account website, from the websites in the file`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -250,
            duration: 200,
          },
          {
            target: '.websites-place',
            content: `Once you choose the file, the websites will automatically displayed in the websites table`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -250,
            duration: 200,
          },
          {
            target: '.website_select',
            content: `Click on website to exclude products of a website, then the products from that website will not be inserted with matches`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -250,
            duration: 200,
          },
          {
            target: '.submit_button1',
            content: `Click on the save button, to submit the importation information`,
            params: {
              placement: 'right',
              highlight: true,
            },
            offset: -400,
            duration: 200,
            before: (type) => {
              this.active_tab = 0
            },
          },
          {
            target: '.tab-import-form',
            content: `If you want to add matches by typing them in a form one by one, just click on add one product at time`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
            before: (type) => {
              this.active_tab = 1
            },
          },
          {
            target: '.import-form-card',
            content: `Here the form that you will enter the data about the product`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.form-ref-place',
            content: `Here you can enter the reference of product, it is optional`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.form-urls-place',
            content: `And here you can enter your product url and the competitors urls, each one is a new line`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.form-delete-btn',
            content: `Also you can delete a form if you do not want to add that product`,
            params: {
              placement: 'right',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.form-add-btn',
            content: `You can add another form by clicking on add more, to enter another product at the same time`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -400,
            duration: 200,
          },
          {
            target: '.submit_button2',
            content: `Finally you can save all the product you enter by clicking on the save button`,
            params: {
              placement: 'right',
              highlight: true,
            },
            offset: -400,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(2)',
            content: `Congratulation, here it is the importation that you have done`,
            params: {
              placement: 'bottom',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(2) > td:nth-child(7)',
            content: `You can see the current status of your import here, the pending status means that your import is not yet processed, it takes a while`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(3) > td:nth-child(7)',
            content: `The error status means that you have reached the account authorized matches count, you do not have the ability to add another product, if you want to extend your account offer, please contact the support`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(4) > td:nth-child(7)',
            content: `The suspending status means that the count of the products that you want to add, surpass the account authorized matches count, and you can only add some of them until reaching the limits, and the other products will not be inserted, if you want to extend you offer, please contact the support`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(4) > td:nth-child(8) > button',
            content: `Click here to confirm or rollback the import process`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.confirm-insertion-modal',
            content: `This is the confirm dialog`,
            params: {
              placement: 'top',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.confirm-btn',
            content: `If you want to insert just some of the products until reaching the account authorized matches count, and the others will not inserted, click on confirm to do that process`,
            params: {
              placement: 'right',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.rollback-btn',
            content: `And if you want to rollback the whole import process, and not insert any product, then click on rollback button`,
            params: {
              placement: 'bottom',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(5) > td:nth-child(7)',
            content: `The uncompleted status means that the import has some bad urls, that is need a handling to insert them, and the good ones has been well inserted`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(5) > td:nth-child(8) > button',
            content: `Here you can download the origin file that you uploaded, and also a error file that has been generated has the errors that should handle`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(5) > td:nth-child(6) > span',
            content: `if you want to handle the errors, just click on this button to show you errorhandler dialog`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.errors-handler',
            content: `This is the error handler pop-up, that gives you the ability to handle the import errors`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.form-urls-error-place',
            content: `Here you will find the urls that has not benn validated, and needs a handling`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.error-save-button',
            content: `Then click on save button to save all the handling that you have made`,
            params: {
              placement: 'right',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(7) > td:nth-child(7)',
            content: `Success status means that the import process done successfully, and all the products has been well inserted without any error`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.importation-table tbody > tr:nth-child(7) > td:nth-child(8) > button',
            content: `Here you can download the origin file that you uploaded`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -350,
            duration: 200,
          },
          {
            target: '.statistics-place',
            content: `Finally you can see here that the count of imported matches increase`,
            params: {
              placement: 'left',
              highlight: true,
            },
            offset: -200,
            duration: 200,
          },
        ],
      }
    },
    computed: {
      options: function () {
        return {
          mode: 'mymode',
          styleActiveLine: true,
          lineNumbers: true,
          lineWrapping: false,
          viewportMargin: Infinity,
          dragDrop: true,
          inputStyle: 'textarea',
          theme: '3024-day',
          lineNumberFormatter: (line) => `Url ${line} : `,
        }
      },
      limit_percentage: function () {
        return (this.inserted / this.limit) * 100
      },
      limit_progress_color: function () {
        return this.limit_percentage > 70 && this.limit_percentage < 90
          ? 'yellow darken-2'
          : this.limit_percentage > 90 && this.limit_percentage < 100
            ? 'orange darken-2'
            : this.limit_percentage == 100
              ? 'red darken-2'
              : 'success'
      },
    },
    methods: {
      blankFunction() {},
      startTuto() {
        this.active_tab = 0
        this.$tours['myTour'].start()
      },
      badgeColor(status) {
        let color = 'success'
        switch (status) {
          case 'pending':
            color = 'secondary'
            break
          case 'processing':
            color = 'secondary'
            break
          case 'error':
            color = 'red'
            break
          case 'done':
            color = 'green'
            break
          case 'suspending':
            color = 'grey'
            break
          case 'uncompleted':
            color = 'orange'
            break
        }
        return color
      },
    },
    mounted() {},
    watch: {
      show: {
        handler() {
          this.dialog = true
        },
      },
    },
  }
</script>
<style>
  .button-primary {
    border-radius: 0px !important;
    background-color: #464c5b !important;
    color: #fff !important;
    border: 1px solid transparent !important;
  }
  .button-default {
    border-radius: 0px !important;
    background-color: transparent !important;
    color: #333 !important;
    border: 1px solid #e5e5e5 !important;
  }
  .shepherd-element,
  .shepherd-content {
    border-radius: 0px !important;
  }
  .shepherd-text {
    font-size: 1.1rem !important;
  }
  .shepherd-footer {
    display: flow-root !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .action-icon {
    font-size: 24px !important;
  }
  .next-btn {
    float: right !important;
    margin-right: 0px !important;
  }
  .back-btn {
    float: right !important;
    margin-right: 0.5rem !important;
  }
</style>
