<template>
  <v-dialog v-model="dialog" eager max-width="50%">
    <v-card>
    <v-form ref="website_form" id="website_form" lazy-validation>
      <v-card-title>
        <span class="md-text">Auto products creation</span>
        </v-card-title>
        <v-card-text>
            <v-row>
               <v-col cols="6">
                    <v-text-field
                        label="Insert your website url"
                        v-model="myWebsite"
                        outlined
                        :rules="[rules.required,rules.url]"
                    >
                    </v-text-field>
               </v-col> 
            </v-row>
            <v-row >
                <v-row class="pa-4">
                  <v-col cols="6">
                    <div class="v-label">Select competitors</div>
                    <v-switch
                      v-model="randomCompetitors"
                      dense
                      hide-details
                      color="error"
                      class="mt-2 mb-2"
                      :label="randomCompetitors ? 'Auto selection' : 'Manual selection'"
                    ></v-switch>
                    <v-autocomplete
                      :disabled="randomCompetitors"
                      :items="availableWebsites"
                      v-model="selectedWebsites"
                      label="Competitors"
                      color="secondary"
                      multiple
                      outlined
                      :loading="loadingWebsites"
                      hide-details
                      clearable
                      >
                        <template v-slot:item="{ active, item, attrs, on }">
                          <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                            <v-list-item-action class="mr-3">
                              <v-checkbox :input-value="active"></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>
                                <v-row no-gutters align="center">
                                  <span>{{item.host}}</span>
                                  <v-spacer></v-spacer>
                                  <v-chip
                                    v-if="item.count > 0"
                                    text-color="white"
                                    color="primary"
                                    small
                                    >{{ item.count }}</v-chip
                                  >
                                </v-row>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, index }">
                          <v-chip color="primary" style="height: auto" v-if="index === 0">
                            <span>{{ item.host }}</span>
                          </v-chip>
                          <span v-if="index === 1" class="grey--text caption">
                            (+{{ selectedWebsites.length - 1 }} others)
                          </span>
                        </template>                   
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="6" hidden>
                    <div class="v-label">Select categories</div>
                      <v-switch
                        v-model="randomCategories"
                        dense
                        hide-details
                        color="error"
                        class="mt-2 mb-2"
                        :label="randomCategories ? 'Auto selection' : 'Manual selection'"
                      ></v-switch>
                      <v-autocomplete
                          :disabled="randomCategories"
                          v-model="selectedCategories"
                          :items="availableCategories" 
                          label="Categories"
                          color="secondary"
                          multiple
                          :loading="loadingCategories"
                          outlined
                          hide-details
                          clearable
                        >
                          <template v-slot:item="{ active, item, attrs, on }">
                            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                              <v-list-item-action class="mr-3">
                                <v-checkbox :input-value="active"></v-checkbox>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <v-row no-gutters align="center">
                                    <span>{{ item.category.toUpperCase() }}</span>
                                    <v-spacer></v-spacer>
                                    <v-chip
                                      v-if="item.count > 0"
                                      text-color="white"
                                      color="primary"
                                      small
                                      >{{ item.count }}</v-chip
                                    >
                                  </v-row>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" style="height: auto" v-if="index === 0">
                              <span>{{ item.category.toUpperCase() }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text caption">
                              (+{{ availableCategories - 1 }} others)
                            </span>
                          </template>
                        </v-autocomplete>
                  </v-col>
                </v-row>
            </v-row>
        </v-card-text>
        <v-card-actions>
       <v-spacer></v-spacer>
       <v-btn text @click="closeModal" >Cancel</v-btn>
        <v-btn
        large
        :loading="submit_loading"
        light
        @click="sendData"
        color="error"
      >
      Start
      </v-btn>
    </v-card-actions>
    </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
const isUrl = require("is-url");
export default {
  name: "productsCreationModal",
  props: ["show", "inserted", "limit"],
  components: {
  },
  data() {
    return {
      snackbar_color: "",
      snackbar_msg: "",
      snackbar: false,
      myWebsite: '',
      dialog: false,
      select_all: true,
      websites: {},
      submit_loading: false,
      currentItem: 0,
      hide: false,
      randomCompetitors: true,
      randomCategories: true,
      selectPriceRange: false,
      rules: {
          required: (value) => !!value || "This field is required.",
          url: (value) => isUrl(value) || "This field must be a valid url.",
      },
      availableWebsites: [],
      selectedWebsites: [],
      availableCategories: [],
      selectedCategories: [],
      priceRange:[0,100000],
      loadingWebsites:false,
      loadingCategories:false,
    }
  },
  computed: {
  },
  methods: {
    closeModal() {
        this.dialog = false;
    },
    mounted() {
    },
    getAvailableWebsites(website) {
      return axios.get("/api/importation/getavailablewebsites",{
        params:{
          account_id:localStorage.getItem("account_id"),
          requestedWebsite:website,
          source:'auto',
          region:'es'
        }
      }).then((res) => res.data);
    },
    getFilterDataForHost(host){
      return axios.get("/api/products/getfilterdata", {
        params:{
          account: localStorage.getItem("account_id"),
          user: localStorage.getItem("user_id"),
          component: 'Pricing',
        }
      }).then(res => res);
    },
    async sendData(){
        if(this.$refs.website_form.validate()){
          this.submit_loading = true;
          const account = localStorage.getItem("account_id")
          const website = this.myWebsite;
          const availableWebsites = await this.getAvailableWebsites(website);
          await axios.get("/api/importation/discovermatches",{
            params:{
              account_id:account,
              website,
              selectedWebsites:this.selectedWebsites.map(el => el.host),
            }
          }).then(res => {
            this.submit_loading = false;
            this.myWebsite = '';
            this.$emit("show-notification", {
              msg: 'Discovery task created',
              type: "success",
            });
            this.closeModal();
          });
        }
    }
  },
  watch: {
    randomCategories: async function(val){
      if(!this.randomCategories && this.myWebsite && this.$refs.website_form.validate()){
        this.loadingCategories = true;
        const filterData = await this.getFilterDataForHost(this.myWebsite);
        this.availableCategories = filterData.data.categories.map(el => {
          el.text = el.category;
          return el
        });
        this.loadingCategories = false;
      }
    },
    randomCompetitors: async function(val) {
      if(!this.randomCompetitors && this.myWebsite && this.$refs.website_form.validate()){
        this.loadingWebsites = true;
        this.availableWebsites = (await this.getAvailableWebsites(this.myWebsite)).availableWebsites.map(el => {
          el.text = el.host;
          return el
        });
        this.selectedWebsites = this.availableWebsites.slice(0,10).map(el => {
          el.text = el.host;
          return el            
        }); 
        this.loadingWebsites = false;
      }else if(this.randomCompetitors){
        this.availableWebsites = [];
        this.selectedWebsites = [];
      }
    },
    myWebsite: async function(val) {
      if(!this.randomCompetitors){
        this.randomCompetitors = true;
      }
    },
    show: {
      handler() {
        
        this.availableWebsites = [];
        this.selectedWebsites = [];

        this.availableCategories = [];
        this.selectedWebsites = [];

        this.myWebsite = '';
        this.dialog = true;
        this.currentItem = 0;

        this.randomCompetitors = true;
        this.randomCategories = true;
      },
    },
  },
};
</script>
