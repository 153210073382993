<template>
  <v-dialog v-model="dialog" max-width="520">
    <v-card>
      <v-card-title><span class="md-text">Message</span></v-card-title>

      <v-card-text>
        <v-alert
          type="success"
          class="mb-0 mt-4"
          style="font-size:1.2rem; font-weight:500"
        >
          Alert has been created successfully!
          <br />
          <br />
          You will receive the alert at 
          <span class="font-weight-bold">9h</span> on 
          <br />
          <span class="font-weight-bold">
            [ {{ days }} ]
          </span>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "addedMessage",
  props: ["show", "days"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
  },
  watch: {
    show: {
      handler() {
        this.dialog = true;
      },
    },
  },
};
</script>
