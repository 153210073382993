<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            <span class="md-text">Importations List</span>
            <div class="ml-auto d-flex flex-column mr-4">
              <span :class="inserted == limit ? 'error-color' : ''" class="ma-0 text-right sm-text">
                Matches : {{ inserted }} / {{ limit }}
              </span>

              <v-progress-linear
                height="15"
                style="width: 250px"
                :value="limit_percentage"
                :color="limit_progress_color"
                bottom
                rounded
                reactive
              >
              </v-progress-linear>
            </div>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="isDevMode"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  light
                  color="primary"
                  @click="showProductsCreationModal"
                  class="ml-2"
                  >Automatic Creation
                  <v-icon right dark>mdi-robot</v-icon>
                </v-btn>
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  light
                  color="primary"
                  small
                  class="mt-4"
                  @click="showImportModal"
                  >Upload new products file
                  <v-icon right dark>mdi-database-import</v-icon>
                </v-btn>
              </template>
              <span>import matches</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-row>
              <importationTable
                class="w-100"
                :importations="importations"
                @show-handle-error-modal="showHandleErrorModal"
                @get-requests-event="getRequestsPager"
                @confirm-modal-event="confirmModal"
                @update-statistics="updateStatistics"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-row justify="center">
          <v-col lg="auto">
            <v-card
              @click="showTutorial"
              class="d-inline-block pa-4 mr-3 ml-3 card-botton"
              max-width="500"
              ripple
            >
              <v-card-text>
                <div class="d-flex justify-center">
                  <v-icon size="100"> mdi-information-outline </v-icon>
                </div>
                <div class="mt-2">
                  <div class="font-weight-bold text-center xl-text">How to prepare your import</div>
                  <div class="text-center mt-4 md-text">
                    the guide will show you how to prepare you import file and how to import using a
                    form
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col lg="auto">
            <v-card
              class="d-inline-block pa-4 mr-3 ml-3 card-botton"
              max-width="500"
              ripple
              @click="downloadSampleFile()"
            >
              <v-card-text>
                <div class="d-flex justify-center">
                  <v-icon size="100"> mdi-download </v-icon>
                </div>
                <div class="mt-2">
                  <div class="font-weight-bold text-center xl-text">
                    Download sample import file
                  </div>
                  <div class="text-center mt-4 md-text">
                    would you like to see how import file looks like ? Download sample file and test
                    import process
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="show_confirm_dialog" eager max-width="30%">
      <v-card>
        <v-card-title>
          <span class="md-text">Importation Confirmation</span>
          <v-btn icon class="ml-auto" @click="show_confirm_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="warning" class="mb-0 mt-4 font-weight-bold delete-text">
            {{ modal_error_msg }}
            <br />
            Confirm to insert till the limit, or rollback the importation
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show_confirm_dialog = false">cancel</v-btn>
          <v-btn color="delete" class="white--text" @click="confirmInsertion(false)"
            >Rollback !</v-btn
          >
          <v-btn color="secondary" @click="confirmInsertion(true)">Yes, import it !</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <errorHandlerTable
      :show="show_error_handler_modal"
      :data="errors_info"
      :loading="errors_loading"
      :isSingleProduct="isSingleProduct"
      :error_id="error_id"
      @show-notification="showNotification"
    />
    <productsCreationModal
      :show="show_products_creation_modal"
      :inserted="inserted"
      :limit="limit"
      @show-notification="showNotification"
    />

    <importModal
      :show="show_import_modal"
      :inserted="inserted"
      :limit="limit"
      @show-notification="showNotification"
    />
    <snackbar :show="snackbar" :color="snackbar_color" :msg="snackbar_msg" />
  </div>
</template>
<script>
  import importModal from './importModal.vue'
  import errorHandlerTable from './errorHandlerTable.vue'
  import importationTable from './importationTable.vue'
  import productsCreationModal from './productsCreationModal.vue'
  import axios from 'axios'

  export default {
    name: 'Importations',
    data() {
      return {
        isDevMode: false,
        snackbar_color: '',
        snackbar_msg: '',
        snackbar: false,
        show_error_handler_modal: false,
        modal_error_msg: '',
        error_id: '',
        importations: [],
        errors_info: [],
        selected_item: {},
        errors_loading: false,
        inserted: 0,
        limit: 0,
        isSingleProduct: false,
        show_import_modal: false,
        show_products_creation_modal: false,
        show_confirm_dialog: false,
      }
    },
    mounted() {
      this.isDevMode = process.env.VUE_APP_MODE === 'dev'
    },
    computed: {
      limit_percentage: function () {
        return (this.inserted / this.limit) * 100
      },
      limit_progress_color: function () {
        return this.limit_percentage > 70 && this.limit_percentage < 90
          ? 'yellow darken-2'
          : this.limit_percentage > 90 && this.limit_percentage < 100
            ? 'orange darken-2'
            : this.limit_percentage == 100
              ? 'red darken-2'
              : 'success'
      },
    },
    components: {
      importModal,
      importationTable,
      errorHandlerTable,
      productsCreationModal,
    },
    props: {},
    methods: {
      showTutorial() {
        this.$router.push('/tutorial')
      },
      getRequestsPager(payload) {
        this.getRequests(payload.from, payload.size)
      },
      showHandleErrorModal(payload) {
        this.selected_item = payload
        this.show_error_handler_modal = !this.show_error_handler_modal
        this.getErrorsInfo(payload.id)
      },
      updateStatistics() {
        axios
          .get('/api/importation/getstatistics', {
            params: {
              account_id: localStorage.getItem('account_id'),
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            this.inserted = res.data.inserted
            this.limit = res.data.limit
          })
          .catch(function () {
            console.log('FAILURE!! get all requests')
          })
      },
      showImportModal() {
        this.show_import_modal = !this.show_import_modal
      },
      showProductsCreationModal() {
        this.show_products_creation_modal = !this.show_products_creation_modal
      },
      getRequests(from, size) {
        axios
          .get('/api/importation', {
            params: {
              account_id: localStorage.getItem('account_id'),
              from,
              size,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            this.importations = res.data
            this.updateStatistics()
          })
          .catch(function () {
            console.log('FAILURE!! get all requests')
          })
      },
      async getErrorsInfo(id, type) {
        this.errors_info = []
        this.error_id = id
        this.errors_loading = true
        axios
          .get('/api/importation/geterrorinfo', {
            params: {
              account_id: localStorage.getItem('account_id'),
              id,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            this.isSingleProduct = res.data.isSingleProduct ? true : false
            this.errors_loading = false
            if (res.data.status) {
              this.errors_info = res.data.data.map((elt) => {
                elt.checked = true
                return elt
              })
            }
          })
          .catch((err) => {
            console.log('FAILURE!! get all requests', err)
          })
      },
      confirmModal(payload) {
        this.selected_item = payload
        this.modal_error_msg = payload.msg
        this.show_confirm_dialog = !this.show_confirm_dialog
      },
      confirmInsertion(confirm) {
        axios
          .post('/api/importation/confirminsertion', {
            data: {
              account_id: localStorage.getItem('account_id'),
              id: this.selected_item.id,
              type: this.selected_item.type_num,
              confirm,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            this.show_confirm_dialog = false
            if (res.data.status) {
              this.showNotification({ msg: res.data.msg, type: 'success' })
            }
          })
          .catch(function () {
            console.log('FAILURE!! confirmation')
          })
      },
      blobToFile(data, filename) {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = downloadUrl
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        link.remove()
      },
      downloadSampleFile() {
        axios
          .get('/api/importation/getsamplefile', {
            responseType: 'blob',
          })
          .then((res) => {
            this.blobToFile(res.data, 'pricing_sample_file.xlsx')
          })
          .catch(function () {
            console.log('FAILURE!! get all requests')
          })
      },
      showNotification(payload) {
        this.snackbar_color = payload.type
        this.snackbar_msg = payload.msg
        this.snackbar = true
      },
    },
  }
</script>

<style>
  .card-botton {
    cursor: pointer;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .theme--light.v-application {
    background: none !important;
  }
  .error-color {
    color: #f0506e;
  }
</style>
