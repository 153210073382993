<template>
  <v-card elevation="0" v-if="!loading" class="w-100">
    <v-data-table
      class="matches-table"
      :headers="headers"
      :items="data"
      :items-per-page="15"
      :options.sync="pagination"
      :server-items-length="data_total"
      :loading="data_loading"
      light
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50],
        showFirstLastPage: true,
      }"
      :no-data-text="favorite_page ? 'No favorites products available' : 'No data available'"
      @update:options="sortTable"
      @update:items-per-page="setItemPerPage"
      multi-sort
      :item-class="notFound"
    >
      <template v-slot:header.select v-if="allowMultiSelect()">
        <v-btn icon @click="selectAll()">
          <v-icon size="x-large" color="error" v-if="selectedMatchs.length"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon size="x-large" color="grey" v-else>mdi-checkbox-blank-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:header.price="{ header: TableHeader }">
        <v-icon :color="user_website.config.color">$circle</v-icon>
        {{ TableHeader.text }}
      </template>
      <template v-slot:item.select="{ item }" v-if="allowMultiSelect()">
        <div class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="align-self-center mr-2"
                icon
                :color="item.select ? 'error' : 'grey'"
                @click.stop="selectMatch(item)"
              >
                <v-icon size="x-large" v-if="!item.select" :key="tableComponentKey"
                  >mdi-checkbox-blank-outline</v-icon
                >
                <v-icon size="x-large" v-if="item.select" :key="tableComponentKey"
                  >mdi-checkbox-marked</v-icon
                >
              </v-btn>
            </template>
            <span>Remove thid product from favorite products</span>
          </v-tooltip>
          <div class="w-100 d-flex justify-center" v-if="item[`product_image`]">
            <img
              class="product-image"
              :src="buildImageSrc(item[`product_image`])"
              @error="getCompetitorImage"
            />
          </div>
        </div>
      </template>
      <template v-slot:item.favorite="{ item }">
        <div class="d-flex">
          <v-tooltip bottom v-if="favorite_page">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="align-self-center mr-2"
                icon
                color="error"
                @click.stop="removeFavorite(item)"
              >
                <v-icon>$heart_off</v-icon>
              </v-btn>
            </template>
            <span>Remove thid product from favorite products</span>
          </v-tooltip>
          <div class="w-100 d-flex justify-center" v-if="item[`product_image`]">
            <img
              class="product-image"
              :src="buildImageSrc(item[`product_image`])"
              @error="getCompetitorImage"
            />
          </div>
        </div>
      </template>
      <template v-slot:item.product_name="{ item }">
        <div @click.stop="showPriceChart(item)" class="more-info">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div
                class="ma-0 mb-1 text-truncate secondary--text"
                :data-match-id="item['match_id']"
                v-on="on"
                v-bind="attrs"
              >
                {{ cutText(item['product_name'], 50) }}
              </div>
            </template>
            <span>{{ item['product_name'] }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <badge
                  v-if="item.product_brand"
                  :text="cutText(item.product_brand, 50)"
                  color=""
                  class="mb-2 text-truncate sm-text"
                  small="true"
                />
              </span>
            </template>
            <span>{{ item.product_brand }}</span>
          </v-tooltip>
          <div
            class="text--disabled sm-text font-weight-bold"
            v-if="
              item[user_website.alias] != undefined &&
              item[user_website.alias].product.offers[0].updateDate.length
            "
          >
            Last update :
            {{ item[user_website.alias].product.offers[0].updateDate }}
          </div>
          <div
            class="text--disabled sm-text font-weight-bold"
            v-if="
              item[user_website.alias] != undefined &&
              item[user_website.alias].product.offers[0].changeDate.length
            "
          >
            Last change :
            {{ item[user_website.alias].product.offers[0].changeDate }}
          </div>
          <div class="ma-1">
            <div v-if="item.verified">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="grey"
                    class="mr-2"
                    @click.stop="showValidateModal(item)"
                  >
                    <v-icon color="success">mdi-check-decagram</v-icon>
                  </v-btn>
                </template>
                <span>Product match confirmed</span>
              </v-tooltip>
            </div>
            <div v-if="!item.verified">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    color="grey"
                    class="mr-1"
                    @click.stop="showValidateModal(item)"
                  >
                    <v-icon color="warning">mdi-alert-circle-check-outline</v-icon>
                  </v-btn>
                </template>
                <span>Product match not confirmed!</span>
              </v-tooltip>
            </div>
          </div>
          <div class="mb-5 d-md-flex mt-2">
            <img
              v-if="item['google_shopping']"
              :src="require(`../../assets/google_shopping_favicon.png`)"
              width="20"
              height="20"
              class="mr-2 favicon-img"
            />
            <badge
              v-if="item['google_shopping']"
              text="From Google Shopping"
              color="blue"
              small="true"
            />
          </div>
        </div>
      </template>
      <template v-slot:item.price="{ item }">
        <v-row>
          <v-col class="pa-0" cols="6">
            <span
              v-html="
                priceFormatter(item[user_website.alias], item.hight_price, item.low_price, true)
              "
            ></span>
            <div>
              <badge
                v-if="item[user_website.alias] != undefined"
                :text="item[user_website.alias].product.offers[0].availability"
                :color="
                  item[user_website.alias].product.offers[0].availability == 'InStock'
                    ? 'success'
                    : 'error'
                "
                small="true"
              />
              <badge v-else text="Not Found" color="grey" small="true" />
            </div>
          </v-col>
          <v-col class="pa-0" cols="6">
            <div>
              <div class="font-weight-bold" v-if="item.competitors_count > 0">
                <span class="text-success">
                  {{ singlePriceHandler(item.low_price) }}
                </span>
                <span class="font-weight-bold"> - </span>
                <span class="text-danger">
                  {{ singlePriceHandler(item.hight_price) }}
                </span>
              </div>
              <div>
                <div class="text--disabled font-weight-bold sm-text">
                  {{
                    item.competitors_count >= 1
                      ? item.competitors_count - 1
                      : item.competitors_count
                  }}
                  competitors
                </div>
                <div
                  class="text--disabled font-weight-bold sm-text more-info"
                  @click.stop="showValidateModal(item)"
                >
                  {{ getInvalidProducts(item) }}
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <template class="test" v-slot:item.competitors_pricing="{ item }">
        <competitorsInfo
          :product_match="item"
          :competitors_websites="filter ? filter.competitors_websites : []"
        />
      </template>

      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              color="grey"
              class="mr-2"
              @click.stop="showPriceChart(item)"
            >
              <v-icon>$eye_outline</v-icon>
            </v-btn>
          </template>
          <span>Show changes history</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              color="grey"
              class="mr-2"
              @click.stop="showEditModal(item)"
            >
              <v-icon>$edit</v-icon>
            </v-btn>
          </template>
          <span>Edit match</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              color="grey"
              @click.stop="confirmDelete(item)"
            >
              <v-icon>$delete</v-icon>
            </v-btn>
          </template>
          <span>Delete match</span>
        </v-tooltip>
        <v-tooltip bottom v-if="!favorite_page">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="align-self-center mr-2"
              icon
              :color="item.favorite ? 'error' : 'grey'"
              @click.stop="setFavorite(item)"
            >
              <v-icon size="large">$heart</v-icon>
            </v-btn>
          </template>
          <span>Add this product to your favorite products</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import competitorsInfo from './competitorsInfo.vue'
  export default {
    name: 'comparePricesTable',
    props: [
      'items',
      'filter',
      'loading',
      'favorite_page',
      'exclusive_page',
      'changed',
      'total',
      'selected_price_index',
      'component_name',
    ],
    components: {
      competitorsInfo,
    },
    data() {
      return {
        isDevMode: false,
        tableComponentKey: 0,
        data_loading: false,
        user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
        source_id: '6019aee744817b0f85ff85bb',
        pagination: { rowsPerPage: 15, totalItems: 0 },
        searchParams: {},
        from: 0,
        size: 15,
        headers: [
          {
            text: 'Product',
            value: 'product_name',
            //width: '300px',
            sortable: true,
          },
          {
            text: 'My price',
            value: 'price',
            width: '300',
            sortable: true,
          },
          {
            text: 'Competitors pricing',
            value: 'competitors_pricing',
            //width: '50%',
            sortable: true,
          },

          {
            text: 'Actions',
            value: 'action',
            width: '190px',
            sortable: false,
            align: 'center',
          },
        ],
        data: [],
        selectedMatchs: [],
        data_total: 0,
        matchsStats: false,
        table_sorts: [],
        price_index: null,
      }
    },
    mounted() {
      this.isDevMode = process.env.VUE_APP_MODE === ''

      /**
       * show the current component
       */
      if (this.allowMultiSelect()) {
        this.headers.unshift({
          text: '',
          value: 'select',
          sortable: false,
          width: '2%',
        })
      }

      if (this.favorite_page) {
        this.headers.unshift({
          text: '',
          value: 'favorite',
          sortable: false,
          width: '2%',
        })
      }
    },
    methods: {
      cutText(text, length) {
        return text.length > length ? text.substring(0, length) + '...' : text
      },
      forceUpdate() {
        this.tableComponentKey++
      },
      allowMultiSelect() {
        return ['/pricing', '/compare', '/exclusiveproducts'].includes(
          window.location.pathname.toLowerCase(),
        )
      },
      buildImageSrc(item) {
        if (item && item.match(/data:image\/.*/g)) {
          return item.match(/data:image\/.*/g)[0]
        }
        return item
      },
      confirmDelete(item) {
        this.$emit('confirm-delete', item)
      },
      showPriceChart(item) {
        this.$emit('show-price-chart', item)
      },
      showEditModal(item) {
        this.$emit('show-edit-modal', item)
      },
      showValidateModal(item) {
        this.$emit('show-validate-modal', {
          item,
          data: this.data,
          pagination: {
            from: this.from,
            size: this.size,
          },
          searchParams: this.searchParams,
        })
      },
      setItemPerPage(val) {
        this.pagination.rowsPerPage = val
      },
      getInvalidProductsLength(item) {
        return Object.keys(item)
          .map((key) => {
            if (item[key].product) {
              return item[key].product
            }
          })
          .filter((product) => product && !product.validated).length
      },
      getInvalidProducts(item) {
        const invalidProducts = this.getInvalidProductsLength(item)
        return invalidProducts ? `${invalidProducts} need validation` : ''
      },
      sortTable(sorts) {
        this.table_sorts = []
        let sort_by = sorts.sortBy
        let sort_order = sorts.sortDesc
        sort_by.map((elt, index) => {
          this.table_sorts.push({
            sort_by: elt,
            order: sort_order[index] ? 'desc' : 'asc',
          })
        })
      },
      async setFavorite(item) {
        let index = this.data.indexOf(item)
        this.data[index].favorite = !this.data[index].favorite
        await axios
          .post(`/api/products/favorite`, {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
            id_match: item.match_id,
            favorite: this.data[index].favorite,
          })
          .then((res) => {
            if (res.data.status) {
              if (res.data.operation)
                this.$emit('show-notification', {
                  msg: `the product named ( ${this.data[index].product_name} ) has been successfully added to favorites, check your favorites list`,
                  type: 'success',
                  icon: 'heart',
                })
              else
                this.$emit('show-notification', {
                  msg: `the product named ( ${this.data[index].product_name} ) has been successfully removed from favorites, check your favorites list`,
                  type: 'error',
                  icon: 'heart-off',
                })
            }
          })
      },
      getCompetitorImage(e) {
        let image = ''
        for (let i = 0; i < this.data.length; i++) {
          const match = this.data[i]
          const website = Object.keys(match).find(
            (key) => match[key].product && match[key].product.image && match[key].product.validated,
          )
          if (website) image = match[website].product.image
        }
        e.target.src = image
      },
      async getProducts() {
        this.data = []
        this.data_loading = true
        let filter = this.filter != undefined ? this.filter : {}
        this.from = this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage
        this.size = this.pagination.rowsPerPage
        this.searchParams = {
          //...this.filter,
          ...filter,
          account: localStorage.getItem('account_id'),
          user: localStorage.getItem('user_id'),
          table_sorts: this.table_sorts || [],
          favorite_only: this.favorite_page,
          exclusive_only: this.exclusive_page,
          price_index: this.price_index,
          raw_price_index: filter.price_index,
          component: this.component_name,
          page: this.pagination.page,
          rowsPerPage: this.pagination.rowsPerPage,
          from: this.from,
          size: this.size,
        }

        if (!this.searchParams.table_sorts.length)
          this.table_sorts.push({ sort_by: 'product_name', order: 'asc' })

        this.data = await axios
          .get('/api/products/list', {
            params: {
              ...this.searchParams,
            },
          })
          .then((res) => {
            if (res) {
              this.data_total = res.data.total
              return res.data.data
            }
            return []
          })
          .catch((err) => console.error('comparePricesTable - getProducts'))
        this.data_loading = false
        this.selectedMatchs = []
      },
      async selectAll() {
        if (this.selectedMatchs.length > 0) {
          this.selectedMatchs = []
          for (const elt of this.data) {
            elt.select = false
          }
        } else {
          for (const elt of this.data) {
            elt.select = true
            this.selectedMatchs.push(elt)
          }
        }
        this.forceUpdate()
        this.$emit('select-matchs', this.selectedMatchs)
      },
      async selectMatch(item) {
        item.select = !item.select
        if (item.select) {
          this.selectedMatchs.push(item)
        } else {
          this.selectedMatchs = this.selectedMatchs.filter((elt) => elt.match_id != item.match_id)
        }
        this.$emit('select-matchs', this.selectedMatchs)
      },
      async removeFavorite(item) {
        this.$emit('confirm-unfavorite', item)
      },
      notFound(item) {
        if (item[this.user_website.alias] && item[this.user_website.alias].product.not_found)
          return 'not_found_product'
      },
    },
    watch: {
      selectedMatchs: {
        handler() {},
      },
      pagination: {
        handler() {
          try {
            console.log('calling get products')
            this.getProducts()
          } catch (error) {
            console.log('pagination', error)
          }
        },
        deep: true,
      },
      changed: {
        deep: true,
        async handler() {
          this.price_index = undefined
          await this.getProducts()
        },
      },
      selected_price_index: {
        async handler() {
          this.price_index = this.selected_price_index
          await this.getProducts()
        },
      },
      total: {
        handler() {
          this.data_total = this.total
        },
      },
    },
  }
</script>
<style scoped>
  .product-image {
    object-fit: contain;
    max-width: 200px;
    max-height: 80px;
    background-size: cover;
  }
  .more-info {
    cursor: pointer;
  }
  .brand-text {
    font-size: 1rem;
    font-weight: 600;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
  /* .matches-table >>> tbody tr :hover {
    cursor: pointer;
  } */
  .matches-table >>> tbody tr td:nth-child(2) {
    max-width: 300px;
  }
  .matches-table >>> tbody tr td:nth-child(4) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 800px;
    padding: 0px;
  }

  /* 
.costum-card{
  display: inline-block;
} */
</style>
