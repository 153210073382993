var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"eager":"","max-width":"65%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0 d-flex"},[_c('span',{staticClass:"md-text"},[_vm._v(_vm._s(_vm.selected_event.tag))]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pb-0 mt-4"},[_c('v-container',{staticClass:"pt-0"},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('cartInfo',{attrs:{"color":"success","value":_vm.selected_event.call_count,"title":"Total","icon":"mdi-pound"},on:{"click":function($event){return _vm.showSection('global')}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('cartInfo',{attrs:{"color":"error","value":_vm.selected_event.errors != undefined
                  ? _vm.selected_event.errors.length
                  : 0,"title":"Fail","icon":"mdi-alert-circle-outline"},on:{"click":function($event){return _vm.showSection('errors')}}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('cartInfo',{attrs:{"color":"info","value":_vm.selected_event.pages != undefined
                  ? Object.keys(_vm.selected_event.pages).length
                  : 0,"title":"Pages","icon":"mdi-book-open-page-variant-outline"},on:{"click":function($event){return _vm.showSection('pages')}}})],1)],1),(_vm.section == 'global')?_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((Object.keys(_vm.selected_event.from)),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('div',{staticClass:"d-flex w-100"},[_c('span',{staticClass:"sm-text info--text"},[_vm._v(_vm._s(item)+" "),(_vm.selected_event.from[item].info.company.length)?_c('span',{staticClass:"font-weight-bold"},[_vm._v("("+_vm._s(_vm.selected_event.from[item].info.company)+")")]):_vm._e()]),(_vm.selected_event.from[item].info.website.length)?_c('span',{staticClass:"sm-text ml-2"},[_vm._v(" -"+_vm._s(_vm.selected_event.from[item].info.website)+"- ")]):_vm._e(),_c('span',{staticClass:"md-text font-weight-bold ml-auto"},[_vm._v(_vm._s(_vm.selected_event.from[item].count))])])]),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("user id")]),_c('th',[_vm._v("username")]),_c('th',{staticClass:"text-center"},[_vm._v("call count")])])]),_c('tbody',[_vm._l((Object.keys(
                          _vm.selected_event.from[item]
                        )),function(child,child_index){return [_c('v-tooltip',{key:child_index,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(child != 'count' && child != 'info')?_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v(" "+_vm._s(child)+" ")]),_c('td',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.selected_event.from[item][child].username)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.selected_event.from[item][child].count)+" ")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.selected_event.from[item][child].fullname)+" - "+_vm._s(_vm.selected_event.from[item][child].email)+" ")])])]})],2)]},proxy:true}],null,true)})],1)],1)],1)}),1):_vm._e(),(_vm.section == 'errors')?_c('v-row',{staticClass:"d-flex justify-center"},_vm._l((_vm.selected_event.errors),function(error,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_c('div',{staticClass:"w-100 md-text text-center"},[_vm._v(" "+_vm._s(_vm.formatDate(error.timestamp))+" ")])]),_c('v-card-text',[_c('codemirror',{staticClass:"codemirror-input",attrs:{"options":_vm.options,"value":JSON.stringify(
                      { params: error.params, error: error.error },
                      null,
                      2
                    )}})],1)],1)],1)}),1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }