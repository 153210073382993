import Vue from 'vue'
import io from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended'
export default function connectSocket() {
  let socketOptions = {
    path: '/api/socket.io',
    query: `token=${localStorage.getItem('user-token')}&user_id=${localStorage.getItem('user_id')}&username=${localStorage.getItem('user')}`,
  }
  const socket = io(`${window.location.hostname}`, socketOptions)
  Vue.use(VueSocketIOExt, socket)
}
