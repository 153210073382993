<template>
  <div>
    <v-card outlined class="card-height" v-bind:key="renderOccurence">
      <v-card-title class="pb-0">
        <span class="sm-text text--secondary ml-3">Price Index Distribution</span>
        <span class="ml-auto mt-0 pa-0 pt-0">
          <v-switch
            label="Chart view"
            color="error"
            class="mt-0 pt-0 sm-text"
            v-model="distrubutionChartMode"
            dense
          ></v-switch>
        </span>
      </v-card-title>
      <div v-if="statistics.equalData">
        <span class="font-weight-bold ml-3 text--secondary pl-4"> {{ statistics.equalData }} </span
        ><span>{{ statistics.equalData > 1 ? 'products' : 'product' }}</span>
        <span class="sm-text ml-1 text--secondary">
          {{ ((this.equal_than_average / this.total) * 100).toFixed(1) }}% equal to average
        </span>
      </div>
      <v-card-text class="d-flex pa-2" v-if="distrubutionChartMode">
        <v-col cols="12" class="ma-0" v-if="loading">
          <loadingProgress :loading="loading" text="Loading price distribution data..." />
        </v-col>
        <v-row v-show="!loading">
          <v-col cols="12" class="d-flex align-center justify-center mt-auto">
            <canvas
              ref="distrubutionchart"
              id="distrubutionchart"
              style="width: 100% !important"
              height="187px"
            ></canvas>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="d-flex align-center pa-3" v-else>
        <v-col cols="12" class="ma-0" v-if="loading">
          <loadingProgress :loading="loading" text="Loading price distribution data..." />
        </v-col>
        <v-row v-show="!loading">
          <v-col cols="6 pl-6">
            <v-list dense>
              <template
                v-for="(item, i) in statistics.labels.slice(0, statistics.labels.length / 2)"
              >
                <v-divider v-bind:key="item"></v-divider>
                <v-list-item @click="filterPriceIndex(statistics.labels[i])" v-bind:key="i">
                  <v-list-item-content class="pa-0">
                    <v-row>
                      <v-col cols="6">
                        <v-list-item-icon class="mr-0">
                          <v-icon :color="statistics.cheaperColors[i]" small>mdi-circle</v-icon>
                          <div class="ml-0 pa-1">{{ i > 0 ? '&gt;' : '&lt;' }} {{ item }}</div>
                        </v-list-item-icon>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-end">
                        <span class="text-right">
                          <v-list-item class="pa-0">
                            <span class="mr-2">
                              {{ statistics.cheaperData[i] }}
                            </span>
                            <span>{{
                              statistics.cheaperData[i] > 1 ? 'products' : 'product'
                            }}</span>
                          </v-list-item>
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="6 pl-3  pr-6">
            <v-list dense>
              <template
                v-for="(item, i) in statistics.labels.slice(
                  statistics.labels.length / 2 + 1,
                  statistics.labels.length,
                )"
              >
                <v-divider v-bind:key="item"></v-divider>
                <v-list-item
                  v-bind:key="i"
                  @click="
                    filterPriceIndex(
                      statistics.labels.slice(
                        statistics.labels.length / 2 + 1,
                        statistics.labels.length,
                      )[i],
                    )
                  "
                >
                  <v-list-item-content class="pa-0">
                    <v-row>
                      <v-col cols="4">
                        <v-list-item-icon class="mr-0">
                          <v-icon :color="statistics.higherColors[i]" small>mdi-circle</v-icon>
                          <div class="ml-0 pa-1">
                            <span v-text="i < 3 ? '&lt;' : '&gt;'"></span>
                            {{ item }}
                          </div>
                        </v-list-item-icon>
                      </v-col>
                      <v-col cols="8" class="d-flex justify-end">
                        <span class="text-right">
                          <v-list-item class="pa-0">
                            <span class="mr-2">
                              {{ statistics.higherData[i] }}
                            </span>
                            <span>{{ statistics.higherData[i] > 1 ? 'products' : 'product' }}</span>
                          </v-list-item>
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-auto" style="">
        <v-row>
          <v-col cols="6" class="pt-0">
            <v-chip outlined v-if="selectedPriceIndex" @click="clearPriceIndexFilter()" small>
              <v-icon icon="start" small class="text--secondary mr-2"
                >mdi-close-circle-outline</v-icon
              >
              {{ selectedPriceIndex }}</v-chip
            >
          </v-col>
          <v-col>
            <span class="text--secondary sm-text d-flex justify-end">
              Updated {{ dateToDayName(lastUpdateDate) }}
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  import Chart from 'chart.js'
  import ChartDataLabels from 'chartjs-plugin-datalabels'
  Chart.plugins.unregister(ChartDataLabels)
  import axios from 'axios'
  export default {
    props: ['component_name'],
    data() {
      return {
        renderOccurence: 0,
        loading: false,
        distrubutionChartMode: false,
        statistics: {
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0],
          labels: ['-50%', '-20%', '-10%', '-5%', '-1% ~ 1%', '5%', '10%', '20%', '50%'],
          cheaperData: [0, 0, 0, 0],
          higherData: [0, 0, 0, 0],
          cheaperColors: ['#eb2d17', '#ffc94d', '#E5FF00', '#35FF00'],
          higherColors: ['#35FF00', '#E5FF00', '#ffc94d', '#eb2d17'],
          equalData: 0,
          colors: [
            '#FF0000',
            '#FF4600',
            '#FF8C00',
            '#FFD300',
            '#E5FF00',
            '#9FFF00',
            '#58FF00',
            '#35FF00',
            '#12FF00',
          ],
        },
        selectedPriceIndex: null,
        higher_than_average: 0,
        lower_than_average: 0,
        equal_than_average: 0,
        total: 0,
        lastUpdateDate: '',
      }
    },
    methods: {
      dateToDayName(date) {
        //date format YYYY-MM-DD
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const dateParts = date.split('-')
        const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
        //if date equal today return 'Today'
        //if date equal yesterday return 'Yesterday'

        if (dateObj.toDateString() === new Date().toDateString()) return 'Today'
        if (
          dateObj.toDateString() ===
          new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
        )
          return 'Yesterday'
        return days[dateObj.getDay()]
      },
      async initTable() {
        const data = await this.getStatistics()
        let labels = []
        let temp = []
        data.map((datum, index) => {
          temp.push({ key: datum.key, value: datum.doc_count })
        })

        temp.map((elt) => {
          if (elt.key > 0 || elt.key < 0) {
            if (temp.find((a) => a.key == elt.key * -1) == undefined) {
              temp.push({ key: elt.key * -1, value: 0 })
            }
          }
        })
        temp.sort((a, b) => a.key - b.key)
        temp.forEach((elt, index) => {
          //labels.push(elt.key == 0 ? '-1% ~ 1%' : elt.key + '%')
          for (let i = 0; i < this.statistics.labels.length; i++) {
            if (elt.key == 0) {
              if (this.statistics.labels[i] == '-1% ~ 1%') {
                this.statistics.data[i] = elt.value
              }
            }
            if (this.statistics.labels[i] == elt.key + '%') {
              this.statistics.data[i] = elt.value
            }
          }
          //this.statistics.data[index] = elt.value
        })

        for (let i = 0; i < labels.length; i++) {
          if (this.statistics.labels[i] == labels[i]) {
            this.statistics.labels[i] = labels[i]
          }
        }

        //this.statistics.colors = backgroundColors
        this.statistics.cheaperData = this.statistics.data.slice(0, this.statistics.data.length / 2)
        this.statistics.higherData = this.statistics.data.slice(
          this.statistics.data.length / 2 + 1,
          this.statistics.data.length,
        )

        this.statistics.equalData = this.statistics.data.slice(
          this.statistics.data.length / 2,
          this.statistics.data.length / 2 + 1,
        )
        this.statistics.equalData = this.statistics.equalData[0] || ''
      },
      async initChart() {
        const data = await this.getStatistics()
        let labels = []
        let temp = []
        data.map((datum, index) => {
          if (datum.key >= -50 && datum.key <= 50) {
            temp.push({ key: datum.key, value: datum.doc_count })
            if (datum.key == 0) {
              this.statistics.equalData = datum.doc_count
            }
          }
        })

        temp.map((elt) => {
          if (elt.key > 0 || elt.key < 0) {
            if (temp.find((a) => a.key == elt.key * -1) == undefined) {
              temp.push({ key: elt.key * -1, value: 0 })
            }
          }
        })
        temp.sort((a, b) => a.key - b.key)
        temp.forEach((elt, index) => {
          labels.push(elt.key == 0 ? '-1% ~ 1%' : elt.key + '%')
          this.statistics.data[index] = elt.value
        })

        var ctx = document.getElementById('distrubutionchart')
        let colors = [
          '#eb2d17',
          '#ffc94d',
          '#E5FF00',
          '#35FF00',
          '#35FF00',
          '#35FF00',
          '#9FFF00',
          '#ffc94d',
          '#eb2d17',
        ]
        let backgroundColors = []
        for (let i = 0; i < labels.length; i++) {
          backgroundColors.push(colors[i])
        }
        var myChart = new Chart(ctx, {
          type: 'bar',
          plugins: [ChartDataLabels],
          data: {
            labels,
            datasets: [
              {
                label: '',
                data: this.statistics.data,
                borderSkipped: true,
                backgroundColor: backgroundColors,
              },
            ],
          },
          options: {
            onClick: (event) => {
              let chart_element = myChart.getElementAtEvent(event)
              if (chart_element.length) {
                this.selectPriceIndex(labels, chart_element[0]._index)
              }
            },
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  var value = data.datasets[0].data[tooltipItem.index]
                  var label = data.labels[tooltipItem.index]

                  if (value === 0.1) {
                    value = 0
                  }

                  return value + ' products'
                },
              },
            },
            legend: {
              display: false,
            },
            hover: {
              onHover: function (e) {
                var point = this.getElementAtEvent(e)
                if (point.length) e.target.style.cursor = 'pointer'
                else e.target.style.cursor = 'default'
              },
            },
            responsive: false,
            layout: {
              padding: {
                left: 0,
                right: 0,
                top: 30,
                bottom: 0,
              },
            },
            scales: {
              xAxes: [
                {
                  position: 'left',
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    suggestedMin: 0,
                    display: false,
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                align: 'end',
                anchor: 'end',
                font: {
                  size: 11,
                  weight: 600,
                },
                offset: 4,
                color: '#9e9e9e',
                padding: {
                  left: 10,
                  right: 10,
                },
                listeners: {
                  enter: (context) => {
                    ctx.classList.add('enter')
                  },
                  leave: (context) => {
                    ctx.classList.remove('enter')
                  },
                  click: (context) => {
                    this.selectPriceIndex(labels, context.dataIndex)
                  },
                },
              },
            },
          },
        })
      },
      clearPriceIndexFilter() {
        this.selectedPriceIndex = null
        this.$emit('onSelectPriceIndex', null)
      },
      filterPriceIndex(value) {
        const finalValue = value == '-1% ~ 1%' ? 0 : parseInt(value)
        this.selectedPriceIndex = value
        this.$emit('onSelectPriceIndex', finalValue)
      },
      selectPriceIndex(labels, index) {
        let value = parseInt(labels[index]) == -1 ? 0 : parseInt(labels[index])
        this.selectedPriceIndex = labels[index]
        this.$emit('onSelectPriceIndex', value)
      },
      async getStatistics() {
        this.loading = true
        return await axios
          .get(`/api/products/statisticslaststat`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              match: 1,
              component: this.component_name,
            },
          })
          .then((res) => {
            this.lower_than_average = res.data.resume.lower_than_average
            this.higher_than_average = res.data.resume.higher_than_average
            this.equal_than_average = res.data.resume.equal_than_average
            this.total = res.data.resume.total
            this.lastUpdateDate = res.data.resume.lastUpdateDate
            return res.data.statisticsData
          })
          .finally(() => (this.loading = false))
      },
    },
    mounted() {
      if (localStorage.getItem('distrubutionChartMode') == 'true') this.distrubutionChartMode = true
      if (!this.distrubutionChartMode) this.initTable()
    },
    components: {},
    watch: {
      distrubutionChartMode: {
        handler() {
          localStorage.setItem('distrubutionChartMode', this.distrubutionChartMode)
          this.renderOccurence++
          if (this.distrubutionChartMode) this.initChart()
          else this.initTable()
        },
      },
    },
  }
</script>
<style scoped>
  .crawlo-text-size {
    font-size: 18px !important;
  }
  .card-height {
    height: 320px !important;
  }
  .hightprice {
    color: #db4e4e !important;
  }
  .lowprice {
    color: #33de40 !important;
  }
  .enter {
    cursor: pointer !important;
  }
</style>
