import { render, staticRenderFns } from "./comparePricesTable.vue?vue&type=template&id=479f7957&scoped=true"
import script from "./comparePricesTable.vue?vue&type=script&lang=js"
export * from "./comparePricesTable.vue?vue&type=script&lang=js"
import style0 from "./comparePricesTable.vue?vue&type=style&index=0&id=479f7957&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "479f7957",
  null
  
)

export default component.exports