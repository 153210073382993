import axios from 'axios'
export default async function getAccountWebsites(component_name) {
  let websites = []
  await axios
    .get('/api/exportation/websites', {
      params: {
        account: localStorage.getItem('account_id'),
        user: localStorage.getItem('user_id'),
        component: component_name,
      },
    })
    .then((res) => {
      websites = res.data.map((elt) => {
        elt['checked'] = true
        return elt
      })
    })
  return websites
}
