<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title><span class="md-text">{{ title }}</span></v-card-title>
      <v-card-text>
        <v-alert type="warning" class="mb-0 mt-4 mb-4 font-weight-bold">
          {{ text }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <submitButton
          :submitFunction="callback"
          :submit_loading="loading"
          color="primary"
          text="Yes, delete it !"
          :just_text="true"
        />
        <v-btn color="delete" class="white--text" @click="dialog = false"
          >cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "deleteModal",
  props: ["show", "text", "title", "callback", "loading"],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    show: {
      handler() {
        this.dialog = this.dialog ? false : true;
      },
    },
  },
};
</script>
<style scoped>
.delete-text {
  font-size: 1.2rem;
  line-height: 1.5;
}
</style>
