<template>
  <div>
    <v-card
      style="width: 50%; min-height: 400px"
      class="ma-auto mt-0 mb-0 d-flex align-center"
      justify="center"
    >
      <v-col cols="12" class="ma-0" v-if="loading">
        <loadingProgress
          :loading="loading"
          text="Loading settings..."
        />
      </v-col>
      <v-col cols="12" class="ma-0" v-if="!loading">
        <v-list two-line subheader v-if="!loading">
          <v-subheader>
            <span class="lg-text">General User Settings</span>
          </v-subheader>
          <v-list-item>
            <v-list-item-content v-if="!fullname_edit">
              <v-list-item-title>
                <v-row no-gutters class="d-flex align-center">
                  <v-col cols="4">
                    <div>
                      <v-icon class="ml-2 mr-2" size="30">$fullname</v-icon>
                      <span class="ml-4">Fullname</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="font-weight-bold"
                    >{{ fullname }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-if="fullname_edit">
              <v-row no-gutters class="d-flex align-start">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$fullname</v-icon>
                    <span class="ml-4">Fullname</span>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-form
                    ref="edit_fullname_form"
                    v-model="edit_fullname_form"
                    lazy-validation
                  >
                    <v-text-field
                      dense
                      label="New fullname"
                      v-model="new_fullname"
                      :rules="[(v) => !!v || 'Fullname is require']"
                    ></v-text-field>
                  </v-form>
                  <div
                    v-if="error.length"
                    style="color: red; margin-bottom: 10px"
                  >
                    {{ error }}
                  </div>

                  <submitButton
                    :submitFunction="fullnameEdit"
                    :submit_loading="loading"
                    color="secondary"
                    :small="true"
                    text="Save"
                  />
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="fullname_edit = false"
                    class="ml-2"
                    >Cancel</v-btn
                  >
                </v-col>
              </v-row>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                v-if="!fullname_edit"
                @click="
                  fullname_edit = true;
                  password_edit = email_edit = username_edit = false;
                  new_fullname = '';
                  error = '';
                "
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content v-if="!username_edit">
              <v-list-item-title>
                <v-row no-gutters class="d-flex align-center">
                  <v-col cols="4">
                    <div>
                      <v-icon class="ml-2 mr-2" size="30">$username</v-icon>
                      <span class="ml-4">Username</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="font-weight-bold"
                    >{{ username }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-if="username_edit">
              <v-row no-gutters class="d-flex align-start">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$username</v-icon>
                    <span class="ml-4">Username</span>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-form
                    ref="edit_username_form"
                    v-model="edit_username_form"
                    lazy-validation
                  >
                    <v-text-field
                      dense
                      label="New username"
                      v-model="new_username"
                      :rules="[(v) => !!v || 'Username is require']"
                    ></v-text-field>
                  </v-form>
                  <div
                    v-if="error.length"
                    style="color: red; margin-bottom: 10px"
                  >
                    {{ error }}
                  </div>
                  <submitButton
                    :submitFunction="usernameEdit"
                    :submit_loading="loading"
                    color="secondary"
                    :small="true"
                    text="Save"
                  />
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="username_edit = false"
                    class="ml-2"
                  >
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                v-if="!username_edit"
                @click="
                  username_edit = true;
                  password_edit = email_edit = fullname_edit = false;
                  new_username = '';
                  error = '';
                "
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content v-if="!email_edit">
              <v-list-item-title>
                <v-row no-gutters class="d-flex align-center">
                  <v-col cols="4">
                    <div>
                      <v-icon class="ml-2 mr-2" size="30">$email</v-icon>
                      <span class="ml-4">Email</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="font-weight-bold">{{ email }} </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-if="email_edit">
              <v-row no-gutters class="d-flex align-start">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$email</v-icon>
                    <span class="ml-4">Email</span>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-form
                    ref="edit_email_form"
                    v-model="edit_email_form"
                    lazy-validation
                  >
                    <v-text-field
                      :rules="[
                        (v) => !!v || 'E-mail is require',
                        /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(new_email) ||
                          'E-mail must be valid',
                      ]"
                      dense
                      label="New e-mail"
                      v-model="new_email"
                    ></v-text-field>
                    <v-text-field
                      :rules="[
                        (v) => !!v || 'E-mail confirmation is require',
                        (v) => v === new_email || 'E-mail do not match',
                      ]"
                      dense
                      label="E-mail confirmation"
                      v-model="email_confirmation"
                    ></v-text-field>
                  </v-form>
                  <div
                    v-if="error.length"
                    style="color: red; margin-bottom: 10px"
                  >
                    {{ error }}
                  </div>
                  <submitButton
                    :submitFunction="emailEdit"
                    :submit_loading="loading"
                    color="secondary"
                    :small="true"
                    text="Save"
                  />
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="email_edit = false"
                    class="ml-2"
                    >Cancel</v-btn
                  >
                </v-col>
              </v-row>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                v-if="!email_edit"
                @click="
                  email_edit = true;
                  password_edit = username_edit = fullname_edit = false;
                  new_email = '';
                  email_confirmation = '';
                  error = '';
                "
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content v-if="!password_edit">
              <v-list-item-title>
                <v-row no-gutters class="d-flex align-center">
                  <v-col cols="4">
                    <div>
                      <v-icon class="ml-2 mr-2" size="30">$password</v-icon>
                      <span class="ml-4">Password</span>
                    </div>
                  </v-col>
                  <v-col cols="8" class="font-weight-bold"
                    >{{ password }}
                  </v-col>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content v-if="password_edit">
              <v-row no-gutters class="d-flex align-start">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$password</v-icon>
                    <span class="ml-4">Password</span>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-form
                    ref="edit_password_form"
                    v-model="edit_password_form"
                    lazy-validation
                  >
                    <v-text-field
                      dense
                      label="Old password"
                      v-model="old_password"
                      :rules="[
                        (v) => !!v || 'Old password is require',
                        (v) => v.length >= 8 || 'Min 8 characters',
                      ]"
                      :append-icon="edituserpassword1 ? '$eye' : '$eye_off'"
                      :type="edituserpassword1 ? 'text' : 'password'"
                      hint="At least 8 characters"
                      @click:append="edituserpassword1 = !edituserpassword1"
                    ></v-text-field>

                    <v-text-field
                      v-model="new_password"
                      :append-icon="
                        edituserpassword2 ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :rules="[rules.required, rules.min]"
                      label="New password"
                      hint="At least 8 characters"
                      :type="edituserpassword1 ? 'text' : 'password'"
                      counter
                      @click:append="edituserpassword2 = !edituserpassword2"
                      @focus="show_indecator = true"
                      @blur="show_indecator = false"
                      :loading="show_indecator"
                    >
                      <template v-slot:progress v-if="show_indecator">
                        <v-progress-linear
                          :value="progress"
                          :color="password_color"
                          absolute
                          height="7"
                        >
                        </v-progress-linear>
                      </template>
                    </v-text-field>

                    <v-text-field
                      dense
                      label="Password confirmation"
                      v-model="password_confirmation"
                      :rules="[
                        (v) => v === new_password || 'Password do not match',
                        (v) => !!v || 'Confirmation password is require',
                      ]"
                      :append-icon="edituserpassword3 ? '$eye' : '$eye_off'"
                      :type="edituserpassword3 ? 'text' : 'password'"
                      hint="At least 8 characters"
                      @click:append="edituserpassword3 = !edituserpassword3"
                    ></v-text-field>
                  </v-form>

                  <submitButton
                    :submitFunction="passwordEdit"
                    :submit_loading="loading"
                    color="secondary"
                    :small="true"
                    text="Save"
                  />
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="
                      password_edit = edituserpassword1 = edituserpassword2 = edituserpassword3 = false;
                      old_password = '';
                      new_password = '';
                      password_confirmation = '';
                      error = '';
                    "
                    class="ml-2"
                    >Cancel</v-btn
                  >
                </v-col>
              </v-row>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                icon
                v-if="!password_edit"
                @click="
                  password_edit = true;
                  username_edit = email_edit = fullname_edit = false;
                "
              >
                <v-icon>$edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>

          <v-subheader>
            <span class="lg-text">Account Information</span>
          </v-subheader>

          <v-list-item>
            <v-list-item-content>
              <v-row no-gutters class="d-flex align-center">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$account</v-icon>
                    <span class="ml-4">Account name</span>
                  </div>
                </v-col>
                <v-col cols="8" class="font-weight-bold"
                  >{{ account_name }}
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-row no-gutters class="d-flex align-center">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$fullname</v-icon>
                    <span class="ml-4">Company name</span>
                  </div>
                </v-col>
                <v-col cols="8" class="font-weight-bold">{{ company }} </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-row no-gutters class="d-flex align-center">
                <v-col cols="4">
                  <div>
                    <v-icon class="ml-2 mr-2" size="30">$website</v-icon>
                    <span class="ml-4">Website</span>
                  </div>
                </v-col>
                <v-col cols="8" class="font-weight-bold">{{ website }} </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="admin"></v-divider>

          <v-subheader v-if="admin">
            <v-row>
              <v-col cols="8">
                <h3 class="mt-0">Account users List</h3>
              </v-col>
              <v-col cols="4" class="d-flex">
                <v-btn class="ma-1 ml-auto" text @click="showAddUserModal()">
                  <v-icon left>$add</v-icon> Add user
                </v-btn>
              </v-col>
            </v-row>
          </v-subheader>

          <v-list-item v-if="admin">
            <v-list-item-content>
              <v-list-item class="pa-0">
                <v-list-item-content>
                  <v-list-item-title style="max-height: 400px; overflow: auto">
                    <div class="mb-4 text-center" v-if="!users.length">
                      Please click on add user to add some users to this account
                    </div>
                    <v-row
                      no-gutters
                      v-for="(user, index) in users"
                      :key="user.username + index"
                      class="mb-4 d-flex align-center"
                    >
                      <v-col cols="4" class="font-weight-bold">
                        <div>
                          <v-icon class="ml-2 mr-2" size="30">$fullname</v-icon>
                          <span class="ml-4">{{ user.fullname }}</span>
                        </div>
                      </v-col>
                      <v-col cols="3" class="font-weight-bold">
                        <div>
                          <v-icon class="ml-2 mr-2" size="30">$username</v-icon>
                          <span class="ml-4">{{ user.username }}</span>
                        </div>
                      </v-col>
                      <v-col cols="4" class="font-weight-bold">
                        <div>
                          <v-icon class="ml-2 mr-2" size="30">$email</v-icon>
                          <span class="ml-4">{{ user.email }}</span>
                        </div>
                      </v-col>
                      <v-col cols="1" class="d-flex">
                        <v-btn
                          :disabled="user.loading"
                          icon
                          class="ml-auto"
                          @click="showDeleteUserModal(user)"
                        >
                          <v-icon>$delete</v-icon>
                        </v-btn></v-col
                      >
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    </v-card>

    <v-dialog v-model="show_add_user" eager max-width="30%">
      <v-card>
        <v-card-title>
          <span class="md-text">Add User Form</span>
          <v-btn icon class="ml-auto" @click="show_add_user = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="add_user_form" v-model="add_user_form" lazy-validation>
            <v-text-field
              v-model="add_fullname"
              :rules="fullnameRules"
              label="Fullname"
              required
            ></v-text-field>

            <v-text-field
              v-model="add_username"
              :counter="15"
              :rules="usernameRules"
              label="Username"
              required
            ></v-text-field>

            <v-text-field
              v-model="add_email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field>

            <v-text-field
              v-model="add_email_confirmation"
              :rules="[
                (v) => v === this.add_email || 'E-mail do not match',
                (v) => !!v || 'E-mail confirmation is require',
              ]"
              label="E-mail confirmation"
              required
            ></v-text-field>

            <v-text-field
              v-model="add_password"
              :append-icon="adduserpassword1 ? '$eye' : '$eye_off'"
              :type="adduserpassword1 ? 'text' : 'password'"
              @click:append="adduserpassword1 = !adduserpassword1"
              :rules="[
                (v) => !!v || 'Password is require',
                (v) => v.length >= 8 || 'Min 8 characters',
              ]"
              label="Password"
              required
            ></v-text-field>

            <v-text-field
              v-model="add_password_confirmation"
              :append-icon="adduserpassword2 ? '$eye' : '$eye_off'"
              :type="adduserpassword2 ? 'text' : 'password'"
              :rules="[
                (v) => v === add_password || 'Password do not match',
                (v) => !!v || 'Password confirmation is require',
              ]"
              label="Password confirmation"
              required
              @click:append="adduserpassword2 = !adduserpassword2"
            ></v-text-field>
            <span style="color: red">{{ error }}</span>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="show_add_user = false"
            >cancel</v-btn
          >
          <submitButton
            :submitFunction="addUser"
            :submit_loading="loading"
            color="secondary"
            text="Yes, add it !"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <deleteModal
      :show="show_delete_user"
      title="Delete user confirmation"
      :text="`Do you really want to delete this user '${user_to_action.username}'`"
      :callback="deleteUser"
      :loading="loading"
    />

    <snackbar :show="snackbar" :color="snackbar_color" :msg="snackbar_msg" />
  </div>
</template>
<script>
import axios from "axios";
//  
export default {
  name: "Settings",
  data: () => {
    return {
      snackbar_timeout: 20000,
      snackbar_color: "",
      snackbar: false,
      snackbar_msg: "",
      loading: true,
      add_user_form: "",
      edit_username_form: "",
      edit_fullname_form: "",
      edit_email_form: "",
      edit_password_form: "",
      error: "",
      admin: false,
      username: "example",
      fullname: "jhon",
      email: "example@example.com",
      password: "********",
      account_name: "example",
      company: "example",
      website: "www.example.com",
      fullname_edit: false,
      username_edit: false,
      email_edit: false,
      password_edit: false,
      new_fullname: "",
      new_username: "",
      new_password: "",
      old_password: "",
      password_confirmation: "",
      new_email: "",
      email_confirmation: "",
      add_fullname: "",
      add_username: "",
      add_email: "",
      add_email_confirmation: "",
      add_password: "",
      add_password_confirmation: "",
      adduserpassword1: false,
      adduserpassword2: false,
      edituserpassword1: false,
      edituserpassword2: false,
      edituserpassword3: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      emailRules: [
        (v) => !!v || "E-mail is require",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      usernameRules: [
        (v) => !!v || "Username is require",
        (v) =>
          (v && v.length <= 15) || "Username must be less than 15 characters",
      ],
      fullnameRules: [(v) => !!v || "Fullname is require"],
      users: [],
      password_color: "error",
      count: 20,
      show_indecator: false,
      show_add_user: false,
      show_delete_user: false,
      user_to_action: {},
    };
  },
  computed: {
    progress() {
      return Math.min(100, this.count);
    },
  },
  methods: {
    showAddUserModal() {
      this.show_add_user = !this.show_add_user;
    },
    showDeleteUserModal(user) {
      this.user_to_action = user;
      this.show_delete_user = !this.show_delete_user;
    },
    deleteUser() {
      this.loading = true;
      axios
        .post("/api/settings/deleteuser", {
          params: {
            account_id: localStorage.getItem("account_id"),
            user_id: this.user_to_action.id,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data) {
            this.showNotification({
              msg: "User has been successfully deleted",
              type: "success",
            });
          }
          this.show_delete_user = false;
          this.refrechInfo();
        })
        .catch(function () {
          console.log("delete user error");
        })
        .finally(() => (this.loading = false));
    },
    addUser() {
      if (this.$refs.add_user_form.validate()) {
        this.loading = true;
        axios
          .post("/api/settings/adduser", {
            params: {
              account_id: localStorage.getItem("account_id"),
              user_id: localStorage.getItem("user_id"),
              fullname: this.add_fullname,
              username: this.add_username,
              email: this.add_email,
              password: this.add_password,
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (!res.data.status) {
              this.error = res.data.msg;
            } else {
              this.refrechInfo();
              this.showNotification({ msg: res.data.msg, type: "success" });
              this.show_add_user = false;
            }
          })
          .catch(function () {
            console.log("FAILURE!! get all requests");
          })
          .finally(() => (this.loading = false));
      }
    },

    fullnameEdit() {
      if (this.$refs.edit_fullname_form.validate()) {
        this.loading = true;
        axios
          .post("/api/settings/fullname", {
            params: {
              fullname: this.new_fullname,
              user_id: localStorage.getItem("user_id"),
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (!res.data.status) {
              this.error = res.data.msg;
            } else {
              localStorage.setItem("user", this.new_fullname);
              this.refrechInfo();

              this.showNotification({
                msg: "Fullname has been well updated",
                type: "success",
              });
            }
          })
          .catch(function () {
            console.log("FAILURE!! get all requests");
          })
          .finally(() => (this.loading = false));
      }
    },
    usernameEdit() {
      if (this.$refs.edit_username_form.validate()) {
        this.loading = true;
        axios
          .post("/api/settings/username", {
            params: {
              username: this.new_username,
              user_id: localStorage.getItem("user_id"),
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (!res.data.status) {
              this.error = res.data.msg;
            } else {
              localStorage.setItem("user", this.new_username);
              this.refrechInfo();

              this.showNotification({
                msg: "Username has been well updated",
                type: "success",
              });
            }
          })
          .catch(function () {
            console.log("FAILURE!! get all requests");
          })
          .finally(() => (this.loading = false));
      }
    },
    emailEdit() {
      if (this.$refs.edit_email_form.validate()) {
        this.loading = true;
        axios
          .post("/api/settings/email", {
            params: {
              email: this.new_email,
              user_id: localStorage.getItem("user_id"),
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (!res.data.status) {
              this.error = res.data.msg;
            } else {
              this.refrechInfo();

              this.showNotification({
                msg: "Email has been well updated",
                type: "success",
              });
            }
          })
          .catch(function () {
            console.log("FAILURE!! get all requests");
          })
          .finally(() => (this.loading = false));
      }
    },
    passwordEdit() {
      if (this.$refs.edit_password_form.validate()) {
        this.loading = true;
        axios
          .post("/api/settings/password", {
            params: {
              new_password: this.new_password,
              old_password: this.old_password,
              username: localStorage.getItem("user"),
              user_id: localStorage.getItem("user_id"),
            },
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res.data) {
              this.refrechInfo();
            }
            this.showNotification({
              msg: "Password has been well updated",
              type: "success",
            });
          })
          .catch(function () {
            console.log("FAILURE!! get all requests");
          })
          .finally(() => (this.loading = false));
      }
    },
    async refrechInfo() {
      this.error = "";
      this.loading = true;
      await axios
        .get("/api/settings", {
          params: {
            account_id: localStorage.getItem("account_id"),
            user_id: localStorage.getItem("user_id"),
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.new_fullname = "";
          this.new_username = "";
          this.new_email = "";
          this.email_confirmation = "";
          this.old_password = "";
          this.new_password = "";
          this.password_confirmation = "";
          this.fullname = res.data.fullname;
          this.username = res.data.username;
          this.email = res.data.email;
          this.account_name = res.data.account_name;
          this.company = res.data.company;
          this.website = res.data.website;
          this.admin = res.data.admin;
          this.fullname_edit = false;
          this.username_edit = false;
          this.email_edit = false;
          this.password_edit = false;
          this.loading = false;
          if (this.admin) {
            this.users = res.data.users
              .filter((elt) => elt.username != "")
              .map((elt) => {
                elt.loading = false;
                return elt;
              });
          }
        })
        .catch(function () {
          console.log("FAILURE!! get all requests");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showNotification(payload) {
      this.snackbar_color = payload.type;
      this.snackbar_msg = payload.msg;
      this.snackbar = true;
    },
  },
  mounted() {
    this.refrechInfo();
  },
  watch: {
    new_password: {
      handler() {
        this.count = 20;
        if (this.new_password != undefined) {
          if (8 <= this.new_password.length && this.new_password.length <= 32) {
            if (RegExp(".*\\d.*").test(this.new_password)) this.count += 20;
            if (RegExp(".*[a-z].*").test(this.new_password)) this.count += 20;
            if (RegExp(".*[A-Z].*").test(this.new_password)) this.count += 20;
            if (
              RegExp("(?=.*[!@#\\$%\\^&\\*])(?=.{8,})").test(this.new_password)
            )
              this.count += 20;
          }
        }
        this.password_color =
          this.count <= 40
            ? "error"
            : this.count == 60
            ? "warning"
            : this.count == 80
            ? "light-blue"
            : "success";
      },
    },
  },
};
</script>

<style>
.v-messages.theme--light.error--text {
  color: red;
}
.list-title {
  margin: 0px 10px;
}
.save-btn {
  margin-right: 10px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
