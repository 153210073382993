<template>
  <v-dialog v-model="dialog" persistent max-width="60%">
    <v-card>
      <v-card-title>
        <span class="md-text">New alert</span>
        <v-btn icon class="ml-auto" @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-stepper v-model="e1" class="w-100">
              <v-stepper-header>
                <v-stepper-step :complete="e1 > 1" step="1"> Filter selection </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 2" step="2">
                  {{ type_name }}
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="e1 > 3" step="3"> Alert configuration </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1" class="pa-1">
                  <div class="mb-4 pl-3">
                    <v-radio-group v-model="radioGroup">
                      <v-radio value="0" @change="radioChanged('All products')">
                        <template v-slot:label>
                          <div>
                            Create an alert on
                            <strong class="error--text">all products</strong>
                          </div>
                        </template>
                      </v-radio>
                      <v-radio value="1" @change="radioChanged('Favorite products')">
                        <template v-slot:label>
                          <div>
                            Create an alert on
                            <strong class="error--text">favorite products</strong>
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </div>

                  <div class="d-flex">
                    <div class="ml-auto">
                      <v-btn text class="mr-2" @click="dialog = false">Cancel</v-btn>
                      <v-btn class="ml-auto" color="primary" @click="e1 = 2">
                        Continue <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="2" class="pa-1">
                  <v-col cols="12" class="ma-0" v-if="loading">
                    <loadingProgress
                      :loading="loading"
                      :text="type == 0 ? 'Loading webistes...' : 'Loading favorite products...'"
                    />
                  </v-col>
                  <v-row v-if="type == 0" class="pa-4" v-show="!loading">
                    <v-col cols="12" class="ma-0">
                      <h4 class="ma-0">
                        Here you can cancel the products of some websites.
                        <br />
                        <p class="text--secondary ma-0">Uncheck to exclude website</p>
                      </h4>
                    </v-col>
                    <v-col cols="12" class="ma-0">
                      <v-checkbox
                        dense
                        hide-details
                        class="ma-0 font-weight-medium"
                        label="Select all websites"
                        v-model="select_all"
                        @change="selectAll"
                      >
                      </v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="4"
                      md="4"
                      class="ma-0"
                      v-for="(website, index) in websites"
                      :key="index"
                    >
                      <v-checkbox dense hide-details class="ma-0" v-model="website.checked">
                        <template v-slot:label>
                          <div class="d-flex align-center">
                            <img
                              :src="
                                'https://8181.integ.crawlo.com/favicon?size=xs&website=' +
                                website.url
                              "
                              @error="replaceByDefault"
                              width="30"
                              height="30"
                              class="mr-2 favicon-img"
                            />{{ website.alias }}
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-else>
                    <v-col cols="12" class="ma-0">
                      <div class="text--secondary ma-0 mt-2 md-text">Favorite products preview</div>
                    </v-col>
                    <v-col cols="12" class="ma-0">
                      <pricesSimpleTable :favorite="true" :component_name="component_name" />
                    </v-col>
                  </v-row>
                  <div class="d-flex mt-4">
                    <v-btn text @click="e1 = 1"> <v-icon left>$back</v-icon>Back </v-btn>
                    <div class="ml-auto">
                      <v-btn text class="mr-2" @click="dialog = false">Cancel</v-btn>
                      <v-btn class="ml-auto" color="primary" @click="e1 = 3">
                        Continue <v-icon right>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-stepper-content>
                <v-stepper-content step="3" class="pa-1">
                  <v-row class="pa-4">
                    <v-col cols="12" sm="6" md="6">
                      <v-col cols="12" class="pl-0 pr-0 ma-0 pa-0">
                        <v-text-field
                          dense
                          outlined
                          label="Alert name"
                          clearable
                          color="primary"
                          v-model="alert_name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pl-0 pr-0 ma-0 pa-0">
                        <v-select
                          v-model="search_filter.tracking"
                          :items="filter.tracking_items"
                          label="Tracking type"
                          small
                          clearable
                          color="secondary"
                          hide-details
                          outlined
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="12"
                        class="pl-0 pr-0"
                        v-if="search_filter.tracking == 'Profit margin changes'"
                      >
                        <div>
                          <span class="subheading font-weight-light mr-1">Profit margin</span>
                          <span class="subheading font-weight-light mr-1">less than or equal </span>
                          <span class="display-2 font-weight-light">{{ profit_slider }}%</span>
                          <v-row>
                            <v-col cols="9" class="pt-4">
                              <v-slider
                                v-model="profit_slider"
                                color="primary"
                                always-dirty
                                min="0"
                                max="100"
                                step="0.01"
                                hide-details
                              >
                              </v-slider>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                v-model="profit_slider"
                                dense
                                hide-details
                                outlined
                                max="100"
                                min="-100"
                                type="number"
                                color="primary"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="pl-0 pr-0"
                        v-if="search_filter.tracking == 'Price changes'"
                      >
                        <div class="v-label d-flex align-center">
                          <span>Receive an alert when</span>
                          <div class="d-flex ml-auto align-center">
                            <span class="mr-2">{{
                              with_percentage ? 'Percentage' : 'Absolute'
                            }}</span>
                            <v-switch
                              dense
                              hide-details="true"
                              class="mt-0"
                              color="error"
                              v-model="with_percentage"
                            ></v-switch>
                          </div>
                        </div>
                        <div v-if="!with_percentage">
                          <div class="d-flex justify-center mt-2">
                            <v-chip
                              class="type justify-center"
                              label
                              ripple
                              :color="setting.trigger.higher ? 'success' : ''"
                              :outlined="!setting.trigger.higher"
                              @click="
                                setting.trigger.higher = true
                                setting.trigger.cheaper = false
                              "
                              large
                            >
                              I'm Higher
                            </v-chip>
                            <v-chip
                              class="type justify-center"
                              label
                              ripple
                              :color="setting.trigger.cheaper ? 'success' : ''"
                              :outlined="!setting.trigger.cheaper"
                              @click="
                                setting.trigger.higher = false
                                setting.trigger.cheaper = true
                              "
                              large
                            >
                              I'm Cheaper
                            </v-chip>
                          </div>
                        </div>
                        <div v-else>
                          <span class="subheading font-weight-light mr-1">My products </span>
                          <span class="display-2 font-weight-light">{{ slider }}%</span>
                          <span class="subheading font-weight-light mr-1"
                            >{{ slider > 0 ? ' more expensive' : 'cheaper' }} than the
                            competitors</span
                          >
                          <v-row>
                            <v-col cols="9" class="pt-4">
                              <v-slider
                                v-model="slider"
                                color="primary"
                                always-dirty
                                min="-100"
                                max="100"
                                step="0.01"
                                hide-details
                              >
                              </v-slider>
                            </v-col>
                            <v-col cols="3">
                              <v-text-field
                                v-model="slider"
                                dense
                                hide-details
                                outlined
                                max="100"
                                min="-100"
                                type="number"
                                color="primary"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="filter.exclude_brands"
                          dense
                          hide-details
                          color="error"
                          class="mt-2"
                          :label="filter.exclude_brands ? 'Brands Exluded' : 'Brands Included'"
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-autocomplete
                            v-model="search_filter.brands"
                            :items="filter.brands_items"
                            label="Brands"
                            color="secondary"
                            multiple
                            outlined
                            hide-details
                            clearable
                          >
                            <template v-slot:item="{ active, item, attrs, on }">
                              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-action class="mr-3">
                                  <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <v-row no-gutters align="center">
                                      <span>{{ item.brand.toUpperCase() }}</span>
                                      <v-spacer></v-spacer>
                                      <v-chip
                                        v-if="item.count > 0"
                                        text-color="white"
                                        color="primary"
                                        small
                                        >{{ item.count }}</v-chip
                                      >
                                    </v-row>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <template v-slot:selection="{ item, index }">
                              <v-chip color="primary" style="height: auto" v-if="index === 0">
                                <span>{{ item.brand.toUpperCase() }}</span>
                              </v-chip>
                              <span v-if="index === 1" class="grey--text caption">
                                (+{{ search_filter.brands.length - 1 }} others)
                              </span>
                            </template>
                          </v-autocomplete>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-switch
                          v-model="filter.exclude_categories"
                          dense
                          hide-details
                          color="error"
                          class="mt-2"
                          :label="
                            filter.exclude_categories
                              ? 'Categories Excluded'
                              : 'Categories Included'
                          "
                        ></v-switch>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-autocomplete
                            v-model="search_filter.categories"
                            :items="filter.categories_items"
                            label="Categories"
                            color="secondary"
                            multiple
                            outlined
                            hide-details
                            clearable
                          >
                            <template v-slot:item="{ active, item, attrs, on }">
                              <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                <v-list-item-action class="mr-3">
                                  <v-checkbox :input-value="active"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <v-row no-gutters align="center">
                                      <span>{{ item.category.toUpperCase() }}</span>
                                      <v-spacer></v-spacer>
                                      <v-chip
                                        v-if="item.count > 0"
                                        text-color="white"
                                        color="primary"
                                        small
                                        >{{ item.count }}</v-chip
                                      >
                                    </v-row>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>
                            <template v-slot:selection="{ item, index }">
                              <v-chip color="primary" style="height: auto" v-if="index === 0">
                                <span>{{ item.category.toUpperCase() }}</span>
                              </v-chip>
                              <span v-if="index === 1" class="grey--text caption">
                                (+{{ search_filter.categories.length - 1 }} others)
                              </span>
                            </template>
                          </v-autocomplete>
                        </v-row>
                      </v-col>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-col cols="12" class="pl-0 pr-0">
                        <div>Receive email these days of the week</div>
                        <div class="d-flex justify-left mt-4">
                          <v-chip
                            class="day justify-center"
                            label
                            ripple
                            v-for="day in Object.keys(setting.days)"
                            :key="day"
                            :color="setting.days[day] ? 'success' : ''"
                            :outlined="!setting.days[day]"
                            @click="selectDay(day)"
                            v-text="day"
                            large
                          >
                          </v-chip>
                        </div>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col cols="12" class="pl-0 pr-0">
                        <v-menu open-on-click offset-y bottom :close-on-content-click="false">
                          <template v-slot:activator="{ on: menu }" class="pa-4">
                            <v-btn text outlined color="primary" light v-on="{ ...menu }">
                              <v-icon left dark>$add</v-icon>
                              <span> Add secondary emails </span>
                              <span v-if="secondary_emails.length">
                                |{{ secondary_emails.length }}</span
                              >
                            </v-btn>
                          </template>
                          <v-card class="pa-0" flat>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-on:keyup.enter="
                                      isValidEmail(newSecondaryEmail) &&
                                        !secondary_emails.includes(newSecondaryEmail) &&
                                        secondary_emails.push(newSecondaryEmail) &&
                                        (newSecondaryEmail = '')
                                    "
                                    dense
                                    v-model="newSecondaryEmail"
                                    small
                                    outlined
                                    hide-details
                                    label="Add email"
                                    color="primary"
                                    clearable
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row
                                v-if="
                                  isValidEmail(newSecondaryEmail) &&
                                  !secondary_emails.includes(newSecondaryEmail)
                                "
                                :class="{
                                  'text-danger': secondary_emails.includes(newSecondaryEmail),
                                }"
                                style="cursor: pointer"
                                @click="
                                  !secondary_emails.includes(newSecondaryEmail)
                                    ? secondary_emails.push(newSecondaryEmail) &&
                                      (newSecondaryEmail = '')
                                    : null
                                "
                              >
                                <v-col cols="1">
                                  <v-icon small>mdi-plus-circle-outline</v-icon>
                                </v-col>
                                <v-col cols="10">
                                  {{ newSecondaryEmail }}
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" class="pa-0">
                                  <v-list dense class="pa-2">
                                    <v-list-item
                                      class="pa-1"
                                      v-for="(email, index) in secondary_emails"
                                      v-bind:key="email"
                                    >
                                      <v-list-item-content>
                                        <v-row>
                                          <v-col cols="9" color="grey">{{ email }}</v-col>
                                          <v-col
                                            cols="2"
                                            @click="removeEmail(index)"
                                            style="cursor: pointer"
                                            ><v-icon small>mdi-close-circle-outline</v-icon></v-col
                                          >
                                        </v-row>
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" class="pl-0 pr-0" hidden>
                        <v-text-field
                          dense
                          outlined
                          hide-details
                          label="Add secondary email (optional)"
                          clearable
                          color="primary"
                          v-model="secondary_email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pl-0 pr-0">
                        <v-row>
                          <v-col>
                            <div class="v-label">Alert status</div>
                            <v-switch
                              v-model="alert_status"
                              dense
                              hide-details
                              color="error"
                              class="mt-2"
                              :label="alert_status ? 'Enabled' : 'Disabled'"
                            ></v-switch>
                          </v-col>
                          <v-col>
                            <div
                              class="v-label"
                              :class="{
                                'text--disabled': search_filter.tracking != 'Price changes',
                              }"
                            >
                              Include Full changes report
                            </div>
                            <v-switch
                              :disabled="search_filter.tracking != 'Price changes'"
                              v-model="include_full_report"
                              dense
                              hide-details
                              color="error"
                              class="mt-2"
                              :label="include_full_report ? 'Enabled' : 'Disabled'"
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-switch
                              v-model="realTimeAlert"
                              dense
                              hide-details
                              color="error"
                              class="mt-2"
                              :label="
                                realTimeAlert
                                  ? 'Auto alert'
                                  : `Recieve alert each ${alertIntervalSlider == 1 ? 'One' : alertIntervalSlider} hour`
                              "
                            ></v-switch>
                            <v-slider
                              v-if="!realTimeAlert"
                              v-model="alertIntervalSlider"
                              color="primary"
                              always-dirty
                              min="1"
                              max="5"
                              step="1"
                              dense
                              hide-details
                            >
                              <template v-slot:prepend>
                                <v-icon color="primary" @click="alertIntervalSlider -= 1">
                                  mdi-minus
                                </v-icon>
                              </template>
                              <template v-slot:append>
                                <v-icon color="primary" @click="alertIntervalSlider += 1">
                                  mdi-plus
                                </v-icon>
                              </template>
                            </v-slider>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                  <div class="d-flex">
                    <v-btn text @click="e1 = 2"> <v-icon left>$back</v-icon>Back </v-btn>
                    <div class="ml-auto">
                      <v-btn text class="mr-2" @click="dialog = false">Cancel</v-btn>
                      <submitButton
                        :submitFunction="saveAlert"
                        :submit_loading="submit_loading"
                        text="Save"
                        color="secondary"
                      />
                      <!-- <v-btn color="secondary" @click="saveAlert">Save</v-btn> -->
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  import pricesSimpleTable from '../common/pricesSimpleTable.vue'
  export default {
    name: 'newAlertModal',
    props: ['show', 'component_name'],
    data: () => ({
      user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
      filter_data: null,
      submit_loading: false,
      slider_interval: false,
      radioGroup: '0',
      slider: 0.01,
      profit_slider: 0.01,
      alertIntervalSlider: 1,
      alert_name: '',
      secondary_email: '',
      secondary_emails: [],
      selected: [],
      dialog: false,
      alert_status: true,
      include_full_report: false,
      e1: 1,
      select_all: true,
      loading: false,
      pagination: { rowsPerPage: 5, totalItems: 0 },
      data_total: 0,
      with_percentage: false,
      realTimeAlert: true,
      newSecondaryEmail: '',
      setting: {
        data_to_send: {
          summary_only: true,
          full_report: false,
        },
        type: {
          notification: false,
          email: true,
        },
        trigger: {
          higher: true,
          cheaper: false,
        },
        stock_filter: {
          in_stock: false,
          all_availabilities: true,
        },
        days: {
          Mon: true,
          Tue: false,
          Wed: false,
          Thu: false,
          Fri: false,
          Sat: false,
          Sun: false,
        },
      },
      websites: [],
      type_name: 'All products',
      type: 0,
      websites_loaded: false,
      favorites_loaded: false,
      data_loading: false,
      mapping: {
        tracking_type: {
          'Price changes': 'price',
          'Stock changes': 'stock',
          'Promotion price': 'promotion',
          'Profit margin changes': 'profit_margin',
          'Rating changes': 'rating',
          'Reviews changes': 'review',
        },
      },
      filter: {
        exclude_brands: true,
        exclude_categories: true,
        brands_items: [],
        categories_items: [],
        tracking_items: [
          'Price changes',
          'Stock changes',
          'Promotion price',
          'Profit margin changes',
          'Rating changes',
          'Reviews changes',
        ],
      },
      search_filter: {
        tracking: 'Price changes',
        brands: [],
        categories: [],
      },
    }),
    components: {
      pricesSimpleTable,
    },
    computed: {},
    mounted() {
      this.getFilterData().then((res) => {
        this.filter_data = res.data
        this.filter.brands_items = this.filter_data.brands
          .map((elt) => {
            elt.text = elt.brand
            return elt
          })
          .filter((elt) => elt.brand.length)
        this.filter.categories_items = this.filter_data.categories
          .map((elt) => {
            elt.text = elt.category
            return elt
          })
          .filter((elt) => elt.category.length)
      })
    },
    methods: {
      isValidEmail(email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      },
      removeEmail(index) {
        this.secondary_emails.splice(index, 1)
      },
      changeAlertType(type) {
        if (type == 0)
          this.setting.type.email = this.setting.type.email
            ? !this.setting.type.notification
              ? true
              : false
            : true
        else
          this.setting.type.notification = this.setting.type.notification
            ? !this.setting.type.email
              ? true
              : false
            : true
      },
      selectDay(day) {
        if (this.validateDays() && this.setting.days[day]) this.setting.days[day] = false
        else this.setting.days[day] = true
      },
      validateDays() {
        let keys = Object.keys(this.setting.days)
        let found = 0
        for (let i = 0; i < keys.length; i++) {
          if (this.setting.days[keys[i]]) found++
        }
        return found > 1
      },
      selectAll() {
        this.websites.map((elt) => {
          elt.checked = this.select_all
        })
      },
      radioChanged(type) {
        if (type == 'All products') this.type = 0
        else this.type = 1
        this.type_name = type
      },
      clearInputs() {
        this.alert_name = ''
        this.secondary_email = ''
        this.secondary_emails = []
        this.slider = 0.01
        this.profit_slider = 0.01
        this.setting.type.notification = false
        this.setting.type.email = true
        this.setting.data_to_send.summary_only = true
        this.setting.data_to_send.full_report = false
        this.setting.days = {
          Mon: true,
          Tue: false,
          Wed: false,
          Thu: false,
          Fri: false,
          Sat: false,
          Sun: false,
        }
        this.alert_status = true
        this.include_full_report = false
        this.with_percentage = false
        this.setting.trigger = {
          higher: true,
          cheaper: false,
        }
      },
      saveAlert() {
        let config = {}
        let product_type = this.type_name
        let name = this.alert_name

        if (this.alert_name == '') {
          switch (this.search_filter.tracking) {
            case 'Price changes':
              name = `All my products ${this.slider}% ${this.slider > 0 ? ' more expensive' : 'cheaper'}`
              break
            case 'Stock changes':
              name = 'Stock changes'
              break
            case 'Promotion price':
              name = 'Promotion price'
              break
            case 'Rating changes':
              name = 'Rating changes'
              break
            case 'Reviews changes':
              name = 'Reviews changes'
              break
            case 'Profit margin changes':
              name = `All my products when i'm ${this.profit_slider}% less than or equal profit margin`
              break
          }
        } else {
          name = this.alert_name
        }

        let status = this.alert_status
        if (this.type_name == 'All products') {
          config.exluded_websites = this.websites
            .filter((elt) => !elt.checked)
            .map((elt) => {
              return { alias: elt.alias, url: elt.url }
            })
        }
        config.trigger = this.slider
        config.tracking_type = this.mapping.tracking_type[this.search_filter.tracking]
        config.alert_type = {
          email: this.setting.type.email,
          notification: this.setting.type.notification,
        }
        let days_key = Object.keys(this.setting.days)
        config.days = []
        days_key.map((elt) => {
          if (this.setting.days[elt]) config.days.push(elt)
        })
        config.secondary_email = this.secondary_email
        config.secondary_emails = this.secondary_emails
        config.include_full_report = this.include_full_report
        config.data_type = this.setting.data_to_send.summary_only ? true : false

        if (this.search_filter.tracking == 'Profit margin changes') {
          config.trigger_profit_margin = this.profit_slider
        }

        if (!this.with_percentage) {
          config.with_percentage = false
          config.trigger = 0.01
          config.trigger_when = {
            cheaper: this.setting.trigger.cheaper,
            higher: this.setting.trigger.higher,
          }
        } else {
          config.with_percentage = true
          config.trigger = this.slider
        }

        if (this.realTimeAlert) {
          config.real_time = true
          config.interval = 0
        } else {
          config.real_time = false
          config.interval = this.alertIntervalSlider
        }

        config.exclude_categories = []
        config.exclude_brands = []
        config.include_categories = []
        config.include_brands = []

        if (this.filter.exclude_categories && this.search_filter.categories.length)
          config.exclude_categories = this.search_filter.categories

        if (this.filter.exclude_brands && this.search_filter.brands.length)
          config.exclude_brands = this.search_filter.brands

        if (!this.filter.exclude_categories && this.search_filter.categories.length)
          config.include_categories = this.search_filter.categories

        if (!this.filter.exclude_brands && this.search_filter.brands.length)
          config.include_brands = this.search_filter.brands

        config.userEmail = localStorage.getItem('email')

        this.submitData(name, product_type, status, config)
      },
      submitData(name, product_type, status, config) {
        this.submit_loading = true
        axios
          .post('/api/alerts', {
            data: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              name,
              product_type,
              status,
              config,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            if (res.data.status) {
              this.$emit('show-notification', {
                msg: `the alert named ( ${name} ) has been successfully added to your alert list`,
                type: 'success',
                icon: 'plus-box-outline',
              })
              this.$emit('show-added-message', { days: config.days })
              // this.showNotification({ msg: res.data.msg, type: "default" });
            }
            this.dialog = false
          })
          .catch(function () {
            console.log('FAILURE!! submitData alert')
          })
          .finally(() => {
            this.submit_loading = false
          })
      },
      setItemPerPage(val) {
        this.pagination.rowsPerPage = val
      },
      async getProducts() {
        this.data_loading = true
        let from = this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage
        let size = this.pagination.rowsPerPage
        this.table.items = await axios
          .get('/api/products/list', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              favorite_only: true,
              all_data: false,
              from,
              size,
            },
          })
          .then((res) => {
            if (res) {
              this.favorites_loaded = true
              this.data_total = res.data.total
              return res.data.data
            }
            return []
          })
        this.data_loading = false
      },
      getFilterData() {
        return axios
          .get('/api/products/getfilterdata', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: 'Pricing',
            },
          })
          .then((res) => res)
      },
    },
    watch: {
      show: {
        handler() {
          this.select_all = true
          this.clearInputs()
          this.websites_loaded = false
          this.radioGroup = '0'
          this.radioChanged('All products')
          this.e1 = 1
          this.dialog = true
        },
      },
      e1: {
        handler() {
          if (this.e1 == 2 && this.type_name == 'All products' && !this.websites_loaded) {
            this.loading = true
            this.getAccountWebsites(this.component_name)
              .then((res) => {
                this.websites = res
                this.websites_loaded = true
              })
              .finally(() => (this.loading = false))
          }

          if (this.e1 == 2 && this.type_name == 'Favorite products' && !this.favorites_loaded) {
            this.getProducts()
          }
        },
      },
      websites: {
        handler() {
          let all_checked = true
          for (let i = 0; i < this.websites.length; i++) {
            if (!this.websites[i].checked) {
              all_checked = false
              break
            }
          }
          this.select_all = all_checked
        },
        deep: true,
      },
      pagination: {
        handler() {
          try {
            this.getProducts()
          } catch (error) {
            console.log('pagination', error)
          }
        },
        deep: true,
      },
    },
  }
</script>
<style scoped>
  .product-image {
    height: 70px;
    background-size: cover;
    margin: 10px;
  }
  .day {
    margin: 0px 2px;
    width: 60px;
    cursor: pointer;
  }
  .type {
    margin: 0px 2px;
    width: 150px;
    cursor: pointer;
  }
  .statistics {
    width: 200px;
  }
</style>
