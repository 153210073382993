<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card width="25%">
          <v-col
            cols="12"
            style="background-color: #f96654"
            class="d-flex align-center"
          >
            <img
              :src="require('../../assets/Dynamic_Pricing.svg')"
              width="220"
            />
          </v-col>
          <v-card-text class="pb-0">
            <v-form ref="login_form" id="login_form" lazy-validation>
              <v-row
                ><v-col cols="12" class="pa-8 pt-4">
                  <div class="font-weight-bold lg-text mb-8 mt-4">Sign In</div>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0 mb-4">
                      <v-text-field
                        placeholder="Username or Email"
                        prepend-inner-icon="mdi-account"
                        ref="username"
                        v-model="username"
                        :rules="[rules.required]"
                        label="Username or Email"
                        :error="isWrong"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 mb-4">
                      <v-text-field
                        placeholder="Password"
                        prepend-inner-icon="mdi-form-textbox-password"
                        ref="password"
                        v-model="password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show_password ? 'text' : 'password'"
                        label="Password"
                        hint="At least 8 characters"
                        @click:append="show_password = !show_password"
                        :error="isWrong"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 mb-4">
                      <div class="secondart--text text-center">
                        Forgot your password ?
                        <a @click="gotoEmailInsert()">reset your password</a>
                      </div>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <submitButton
                        id="login_btn"
                        :submitFunction="login"
                        :submit_loading="loading"
                        class="w-100"
                        color="error"
                        text="Sign In"
                      />
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <div class="secondart--text text-center">
                        No account yet?
                        <a @click="gotoSubscription()">Sign up now</a>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" class="mb-0 d-flex justify-center">
        <v-alert
          width="30%"
          :value="isWrong"
          color="error"
          dark
          border="right"
          icon="mdi-alert"
          transition="scale-transition"
        >
          E-mail or password not valid
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "Login",
  props: {
    msg: String,
  },
  data: () => {
    return {
      password: "",
      username: "",
      alert: false,
      isWrong: false,
      show_password: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  methods: {
    login() {
      if (this.$refs.login_form.validate()) {
        const { username, password } = this;
        this.loading = true;
        this.$store
          .dispatch("AUTH_REQUEST", { username, password })
          .then((res) => {
            this.loading = false;
            if (!res) {
              this.isWrong = true;
            } else {
              this.isWrong = false;
              this.$router.push("/Pricing");
            }
          });
      }
    },
    enterClicked() {
      document.addEventListener("keyup", function(event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
          // Cancel the default action, if needed
          event.preventDefault();
          document.getElementById("login_btn").click();
        }
      });
    },
    gotoEmailInsert() {
      this.$router.push("/EmailInsert");
    },
    gotoSubscription() {
      this.$router.push("/subscription");
    },
  },
  async mounted() {
    if (!this.$store.getters.isAuthenticated) {
      try {
        this.$socket.client.emit(
          "DisconnectListening",
          {
            user_id: localStorage.getItem("user_id"),
          },
          (resp) => {
            this.$store.dispatch("socketDestroyed");
          }
        );
      } catch (ex) {
        console.log(ex);
      }
      await this.$store.dispatch("AUTH_LOGOUT");
      this.$router.push("/login");
    }
    if (this.$route.query.from_insert_email || false) this.alert = true;
    this.enterClicked();
  },
};
</script>
<style scoped>
a {
  color: #42b983 !important;
}
</style>
