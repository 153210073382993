<template>
  <div
    class="text-center w-100 align-self-center"
    v-if="loading"
  >
    <v-row :style="'height:'+_height+'px;'" class="fill-height" align-content="center" justify="center">
      <v-col class="md-text text-center" cols="12">
        {{ text }}
      </v-col>
      <v-col cols="8">
        <v-progress-linear
          :active="loading"
          :indeterminate="loading"
          color="#e86f5c"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "loadingProgress",
  props: ["loading", "text", "_height"],
};
</script>