<template>
  <v-card
  outlined
  >
    <v-card-title>
      <span class="md-text">Calls history</span>
    </v-card-title>
    <v-card-text style="height: 280px" class="d-flex align-center">
      <v-col cols="12" class="ma-0" v-if="loading">
        <loadingProgress
          :loading="loading"
          text="Loading price distribution data..."
        />
      </v-col>
      <v-row v-show="!loading">
        <v-col cols="12" class="d-flex align-center justify-center">
          <canvas
            ref="callschart"
            id="callschart"
            style="width: 100% !important; height: auto !important"
          ></canvas>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.plugins.unregister(ChartDataLabels);
import axios from "axios";
export default {
  props: ["component_name"],
  data() {
    return {
      loading: false,
      higher_than_average: 0,
      lower_than_average: 0,
      equal_than_average: 0,
      total: 0,
    };
  },
  methods: {
    async initChart() {
      const data = await this.getStatistics();
      let labels = [];

      var ctx = document.getElementById("callschart");
    //   var myChart = new Chart(ctx, {
    //     type: "bar",
    //     plugins: [ChartDataLabels],
    //     data: {
    //       labels,
    //       datasets: [
    //         {
    //           label: "",
    //           data: this.statistics.data,
    //           borderSkipped: true,
    //           backgroundColor: backgroundColors,
    //         },
    //       ],
    //     },
    //     options: {
    //       maintainAspectRatio: false,
    //       legend: {
    //         display: false,
    //       },
    //       responsive: false,
    //       layout: {
    //         padding: {
    //           left: 0,
    //           right: 0,
    //           top: 30,
    //           bottom: 0,
    //         },
    //       },
    //       scales: {
    //         xAxes: [
    //           {
    //             position: "left",
    //             gridLines: {
    //               display: false,
    //               drawBorder: false,
    //             },
    //           },
    //         ],
    //         yAxes: [
    //           {
    //             ticks: {
    //               beginAtZero: true,
    //               min: 0,
    //               suggestedMin: 0,
    //               display: false,
    //             },
    //             gridLines: {
    //               display: false,
    //               drawBorder: false,
    //             },
    //           },
    //         ],
    //       },

    //       plugins: {
    //         datalabels: {
    //           align: "end",
    //           anchor: "end",
    //           font: {
    //             size: 11,
    //             weight: 600,
    //           },
    //           offset: 4,
    //           color: "#9e9e9e",
    //           padding: {
    //             left: 10,
    //             right: 10,
    //           }
    //         },
    //       },
    //     },
    //   });
    },
    async getStatistics() {
    //   this.loading = true;
    //   return await axios
    //     .get(`/api/products/statisticslaststat`, {
    //       params: {
    //         account: localStorage.getItem("account_id"),
    //         user: localStorage.getItem("user_id"),
    //         match: 1,
    //         component: this.component_name,
    //       },
    //     })
    //     .then((res) => {
    //       return res.data.statisticsData;
    //     })
    //     .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.initChart();
  },
  components: {},
};
</script>