<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card width="60%">
          <v-col cols="12" style="background-color: #f96654" class="d-flex align-center">
            <img :src="require('../../assets/Dynamic_Pricing.svg')" width="220" />
            <span v-if="plan.length" @click="showPlansModal" class="ml-auto mr-2">
              <badge style="cursor: pointer" small="true" :text="plan" color="white" />
            </span>
          </v-col>
          <v-card-text class="pb-0">
            <v-form ref="subscription_form" id="subscription_form" lazy-validation>
              <v-row
                ><v-col
                  cols="12"
                  sm="5"
                  md="5"
                  class="pa-8 pt-4"
                  style="border-right: 1px solid rgba(181, 181, 181, 0.2)"
                >
                  <span class="font-weight-bold lg-text">Sign Up</span>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                        v-model="name"
                        label="Full name *"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                        autocomplete="on"
                        ref="email_crawlo"
                        v-model="email"
                        :rules="[rules.required, rules.emailValidation]"
                        label="Email *"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                        v-model="password"
                        :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        label="Password *"
                        hint="At least 8 characters"
                        counter
                        :is-password="!show_password"
                        @click:append="show_password = !show_password"
                        @focus="show_indecator = true"
                        @blur="show_indecator = false"
                        :loading="show_indecator"
                        name="crawlo_password"
                      >
                        <template v-slot:progress v-if="show_indecator">
                          <v-progress-linear
                            :value="progress"
                            :color="password_color"
                            absolute
                            height="7"
                          >
                          </v-progress-linear>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                        :append-icon="show_password_conf ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[(v) => v === password || 'Password do not match', rules.required]"
                        label="Confirm password *"
                        hint="At least 8 characters"
                        :is-password="!show_password_conf"
                        @click:append="show_password_conf = !show_password_conf"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-checkbox :rules="[rules.required]" required v-model="conditions">
                        <template v-slot:label>
                          <div @click.stop="">
                            I accept the &nbsp;
                            <a href="/Privacy" target="_blank" style="color: #42b983">
                              Privacy Policy
                            </a>
                            &nbsp;and the&nbsp;
                            <a href="/TermsConditions" target="_blank" style="color: #42b983">
                              Legal Notice</a
                            >
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0 pb-8">
                      <v-btn text color="primary" @click="gotoLogin()">
                        <v-icon left>$back</v-icon> Sign In
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="mb-0">
                      <v-btn
                        large
                        light
                        color="error"
                        class="w-100"
                        :loading="submit_loading"
                        @click="validateSubscription"
                      >
                        Get Started !
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="7" md="7" class="pa-8 pt-4">
                  <span class="font-weight-bold lg-text">Business information</span>
                  <v-row>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field v-model="company" :rules="[]" label="Company"></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" class="pt-0 pb-0">
                      <v-text-field
                        v-model="adress"
                        :rules="[rules.required]"
                        label="Address *"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="4" class="pt-0 pb-0">
                      <v-text-field
                        v-model="city"
                        :rules="[rules.required]"
                        label="City *"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="4" class="pt-0 pb-0">
                      <v-text-field
                        v-model="zip"
                        :rules="[rules.required]"
                        label="Zip *"
                      ></v-text-field>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="4" class="pt-0 pb-0">
                      <v-autocomplete
                        :rules="[rules.required]"
                        :items="countries"
                        v-model="country"
                        label="Country"
                        required
                      >
                      </v-autocomplete>
                    </v-col> -->
                    <!-- <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field v-model="tax" label="Vat number ( optional )"></v-text-field>
                    </v-col> -->
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <div class="my-2">
                        <v-btn color="error" class="w-100" @click="showPlansModal"
                          >Change your plan</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-0 d-flex justify-center">
        <v-alert
          width="30%"
          v-for="error in errors"
          :key="error"
          color="error"
          dark
          border="right"
          icon="mdi-alert"
          transition="scale-transition"
        >
          {{ error.message }}
        </v-alert>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="70%">
      <v-card>
        <v-card-title>
          <span class="md-text">Choose a plan</span>
          <v-btn icon class="ml-auto" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text><plans @choosen-plan="choosenPlan" /> </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { loadStripe } from '@stripe/stripe-js'

  import plans from '../common/plans.vue'

  import axios from 'axios'
  export default {
    name: 'Subscription',
    components: {
      plans,
    },
    props: {
      msg: String,
    },
    data: () => ({
      dialog: false,
      alert: true,
      conditions: '',
      name: '',
      email: '',
      password: '',
      show_indecator: false,
      website: '',
      zip: '',
      city: '',
      adress: '',
      company: '',
      country: '',
      tax: '',
      errors: [],
      selected_plan: '',
      count: 20,
      availabl_plans: [
        process.env.VUE_APP_PLAN_PREMIUM.replace('price_', ''), //premium
        process.env.VUE_APP_PLAN_PRO.replace('price_', ''), //pro
        process.env.VUE_APP_PLAN_MINI.replace('price_', ''), //mini
      ],
      loading: false,
      checkout_session_id: '',
      publishableKey: process.env.VUE_APP_PK_STRIPE_KEY,
      show_password: false,
      show_password_conf: false,
      submit_loading: false,
      countries: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antigua &amp; Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia',
        'Bosnia &amp; Herzegovina',
        'Botswana',
        'Brazil',
        'British Virgin Islands',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Cape Verde',
        'Cayman Islands',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Congo',
        'Cook Islands',
        'Costa Rica',
        'Cote D Ivoire',
        'Croatia',
        'Cruise Ship',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French West Indies',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kuwait',
        'Kyrgyz Republic',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macau',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Namibia',
        'Nepal',
        'Netherlands',
        'Netherlands Antilles',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palestine',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Pierre &amp; Miquelon',
        'Samoa',
        'San Marino',
        'Satellite',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'South Korea',
        'Spain',
        'Sri Lanka',
        'St Kitts &amp; Nevis',
        'St Lucia',
        'St Vincent',
        'St. Lucia',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        "Timor L'Este",
        'Togo',
        'Tonga',
        'Trinidad &amp; Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks &amp; Caicos',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Venezuela',
        'Vietnam',
        'Virgin Islands (US)',
        'Yemen',
        'Zambia',
        'Zimbabwe',
      ],
      rules: {
        required: (value) => !!value || 'This field is required.',
        min: (v) => v.length >= 8 || 'Min 8 characters',
        emailValidation: (value) => /.+@.+\..+/.test(value) || 'Email not valid.',
      },
      plan: '',
      password_color: 'error',
    }),
    computed: {
      progress() {
        return Math.min(100, this.count)
      },
    },
    methods: {
      alertMessage(error) {
        return error.message.replace(error.field, `<strong>${error.field}</strong>`)
      },
      choosenPlan(plan) {
        this.selected_plan = this.availabl_plans[plan]
      },
      showPlansModal() {
        this.dialog = true
      },
      clearErrors() {
        this.errors = []
      },
      validateSubscription() {
        let dataToSubmit = {}
        if (this.$refs.subscription_form.validate() && this.selected_plan) {
          this.submit_loading = true
          dataToSubmit.fullname = this.name
          dataToSubmit.email = this.email
          dataToSubmit.password = this.password
          dataToSubmit.company = this.company
          dataToSubmit.adress = this.adress
          dataToSubmit.city = this.city
          dataToSubmit.zip = this.zip
          dataToSubmit.country = this.country
          dataToSubmit.conditions = this.conditions ? 'accept' : ''

          if (this.tax.length) dataToSubmit.tax = this.tax
          if (this.selected_plan) this.newCustomer(dataToSubmit)
        }
      },
      async checkoutStripe() {
        const stripe = await loadStripe(process.env.VUE_APP_PK_STRIPE_KEY)
        const { error } = await stripe.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: this.checkout_session_id,
        })
      },
      gotoLogin() {
        this.$router.push('/login')
      },
      getFieldError(field) {
        const error = this.errors.filter((err) => {
          return err.field == field
        })

        return error.length ? error[0].message : ''
      },
      async newCustomer(dataToSubmit) {
        this.errors = []

        //post to to create customer and new seesion for this customer
        await axios
          .post('/api/customer/new', {
            ...dataToSubmit,
            plan: this.selected_plan,
          })
          .then((res) => {
            if (res.data.errors) this.errors = res.data.errors

            if (!this.errors.length) {
              console.log(res.data)
              //session details
              this.checkout_session_id = res.data.result.checkout_session.checkout_session.id
              this.checkoutStripe()
            } else {
              //##show error text here
            }
          })
          .catch((err) => {
            console.log(err)
          })

        this.submit_loading = false
      },
    },
    mounted() {
      this.selected_plan = this.$route.query.p || '1Op7CVKBQj3UOQ3bsWXuLkdF'
    },
    watch: {
      selected_plan: {
        handler() {
          this.plan =
            this.selected_plan == process.env.VUE_APP_PLAN_PREMIUM.replace('price_', '')
              ? 'PREMIUM / 249€'
              : this.selected_plan ==
                  process.env.VUE_APP_PLAN_PREMIUM_WITHOU_TRIAL.replace('price_', '')
                ? 'PREMIUM / 249€'
                : this.selected_plan == process.env.VUE_APP_PLAN_PRO.replace('price_', '')
                  ? 'PROFESSIONAL / 149€'
                  : this.selected_plan == process.env.VUE_APP_PLAN_MINI.replace('price_', '')
                    ? 'MINI / 49€'
                    : ''
        },
      },
      password: {
        handler() {
          this.count = 20
          if (this.password != undefined) {
            if (8 <= this.password.length && this.password.length <= 32) {
              if (RegExp('.*\\d.*').test(this.password)) this.count += 20
              if (RegExp('.*[a-z].*').test(this.password)) this.count += 20
              if (RegExp('.*[A-Z].*').test(this.password)) this.count += 20
              if (RegExp('(?=.*[!@#\\$%\\^&\\*])(?=.{8,})').test(this.password)) this.count += 20
            }
          }
          this.password_color =
            this.count <= 40
              ? 'error'
              : this.count == 60
                ? 'warning'
                : this.count == 80
                  ? 'light-blue'
                  : 'success'
        },
      },
    },
  }
</script>

<style scoped>
  a {
    color: #42b983;
  }
</style>
<style>
  input[is-password='true'] {
    -webkit-text-security: disc;
  }
</style>
