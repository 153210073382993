<template>
  <v-data-table
    :headers="headers"
    :items="data.items"
    :options.sync="pagination"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }"
    :server-items-length="data.count"
    :loading="loading"
    :items-per-page="10"
  >
    <template v-slot:item.status="{ item }">
      <v-tooltip
        bottom
        :disabled="
          item.status != 'uncompleted' &&
          item.status != 'suspending' &&
          item.status != 'error'
        "
      >
        <template v-slot:activator="{ on }">
          <span v-on="on">
            <badge
              :text="item.status == 'done' ? 'success' : item.status"
              :color="badgeColor(item.status)"
              :progress="
                item.status == 'pending' || item.status == 'processing'
              "
              cursor="help"
            />
          </span>
        </template>
        <span>{{ item.msg }}</span>
      </v-tooltip>
    </template>

    <template v-slot:item.errors="{ item }">
      <v-tooltip bottom :disabled="item.status != 'uncompleted'">
        <template v-slot:activator="{ on }">
          <span v-on="on" @click="handleErrors(item)">
            <badge
              :text="item.errors"
              :color="item.errors > 0 ? 'red' : 'grey'"
              small="true"
              cursor="pointer"
            />
          </span>
        </template>
        <span>Click to handle Errors</span>
      </v-tooltip>
    </template>

    <template v-slot:item.action="{ item }">
      <v-tooltip
        bottom
        v-if="item.status != 'uncompleted' && item.type_num != '2'"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            @click="downloadFile(false, item.id)"
            v-on="on"
            icon
            small
            :disabled="item.status == 'pending'"
          >
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </template>
        <span>Download Origin</span>
      </v-tooltip>

      <v-menu
        open-on-hover
        bottom
        offset-y
        v-if="item.status == 'uncompleted' && item.type_num != '2'"
      >
        <template v-slot:activator="{ on: menu }">
          <v-btn class="action-icon" v-on="{ ...menu }" small icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="downloadFile(false, item.id)">
            <v-list-item-title>Download Origin</v-list-item-title>
          </v-list-item>
          <v-list-item @click="downloadFile(true, item.id)">
            <v-list-item-title>Download Errors</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            @click="confirmModal(item)"
            v-on="on"
            icon
            small
            :class="item.status == 'suspending' ? 'display' : 'hide'"
          >
            <v-icon> mdi-alert-box </v-icon>
          </v-btn>
        </template>
        <span>Confirm Insertion</span>
      </v-tooltip>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      data: [],
      loading: false,
      pagination: { totalItems: 0 },
      headers: [
        { text: "Importations", value: "filename", sortable: false },
        { text: "Date", value: "date", align: "center", sortable: false },
        { text: "Type", value: "type", align: "center", sortable: false },
        {
          text: "Processed",
          value: "processed",
          align: "center",
          sortable: false,
          width: "1%",
        },
        {
          text: "Added",
          value: "added",
          align: "center",
          sortable: false,
          width: "1%",
        },
        {
          text: "Errors",
          value: "errors",
          align: "center",
          sortable: false,
          width: "1%",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          sortable: false,
          width: "1%",
        },
        { text: "Actions", value: "action", align: "center", sortable: false },
      ],
    };
  },
  props: ["importations"],
  components: {},
  mounted() {
    this.data = this.importations;
    this.$socket.client.on("globalListener", (data) => {
      if (data.dataChanged && data.cause == "importationChanges") {
        this.getRequests();
      }
    });
  },
  methods: {
    badgeColor(status) {
      let color = "success";
      switch (status) {
        case "pending":
          color = "secondary";
          break;
        case "processing":
          color = "secondary";
          break;
        case "error":
          color = "red";
          break;
        case "done":
          color = "green";
          break;
        case "suspending":
          color = "grey";
          break;
        case "uncompleted":
          color = "orange";
          break;
      }
      return color;
    },
    confirmModal(item) {
      this.$emit("confirm-modal-event", item);
    },
    handleErrors(payload) {
      if (payload.status == "uncompleted")
        this.$emit("show-handle-error-modal", payload);
    },
    blobToFile(data, filename) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    downloadFile(error, id) {
      axios
        .get("/api/importation/getfile", {
          params: {
            account_id: localStorage.getItem("account_id"),
            id,
            error,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        })
        .then((res) => {
          let filename = res.headers.filename;
          this.blobToFile(res.data, filename);
        })
        .catch(function () {
          console.log("FAILURE!! get all requests");
        });
    },
    getRequests() {
      this.loading = true;
      let from =
        this.pagination.page * this.pagination.itemsPerPage -
        this.pagination.itemsPerPage;
      let size = this.pagination.itemsPerPage;

      axios
        .get("/api/importation", {
          params: {
            account_id: localStorage.getItem("account_id"),
            from,
            size,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$emit("update-statistics", {});
          this.data = res.data;
          this.loading = false;
          this.$forceUpdate();
        })
        .catch(function () {
          console.log("FAILURE!! get all requests");
        });
    },
  },
  watch: {
    pagination: {
      handler() {
        try {
          this.getRequests();
        } catch (error) {
          console.log("pagination", error);
        }
      },
      deep: true,
    },
    importations: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.data = this.importations;
      },
    },
  },
};
</script>
<style>

.action-icon {
  font-size: 24px !important;
}
.pending-badge,
.processing-badge {
  background-color: #bed9f3 !important;
  color: #0477ea;
}
.error-badge {
  background-color: #fceaed;
  color: #bc3343;
  cursor: help;
}
.done-badge {
  background-color: #effae8;
  color: #63ac75;
  /* background-color: #51a951 !important; */
}
.suspending-badge {
  background-color: #e4e4e4 !important;
  color: #717171;
  cursor: help;
}
.uncompleted-badge {
  background-color: #f9ede3 !important;
  color: #ff7001;
  cursor: help;
}
.error-color {
  color: #bc3343;
}
.error_msg {
  color: #bc3343;
}
.display {
  display: inline-flex !important;
}
.hide {
  display: none !important;
}
</style>
