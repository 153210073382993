<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-0"><callsChart /> </v-col>
      <!-- <v-col cols="6" class="pt-0"> </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="md-text">Events logs</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <eventsLogsTable @show-details="showDetails" />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <eventDetailsModal
      :show="details_modal"
      :selected_event="selectedProductToAction"
    />
  </div>
</template>
<script>
import eventsLogsTable from "./eventsLogsTable.vue";
import eventDetailsModal from "./eventDetailsModal.vue";
import callsChart from "./callsChart.vue";
export default {
  name: "administration",
  components: {
    eventsLogsTable,
    eventDetailsModal,
    callsChart,
  },
  data() {
    return {
      details_modal: false,
      selectedProductToAction: {},
    };
  },
  methods: {
    showDetails(payload) {
      // console.log("adiministration.vue : "+payload);
      this.selectedProductToAction = payload;
      this.details_modal = !this.details_modal;
    },
  },
};
</script>
