<template>
  <v-data-table
    class="matches-table"
    :headers="table.headers"
    :items="table.items"
    :loading="data_loading"
    :items-per-page="pagination.rowsPerPage"
    :options.sync="pagination"
    @update:items-per-page="setItemPerPage"
    :server-items-length="data_total"
    no-data-text="No data available"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }"
  >
    <template v-slot:item.image="{ item }">
      <img class="product-image" :src="item.product_image" />
    </template>
    <template v-slot:item.price="{ item }">
      <span
        v-html="priceFormatter(item[user_website.alias], item.hight_price, item.low_price)"
      ></span>
      <div>
        <badge
          v-if="item[user_website.alias] != undefined"
          :text="item[user_website.alias].product.offers[0].availability"
          :color="
            item[user_website.alias].product.offers[0].availability == 'InStock'
              ? 'success'
              : 'error'
          "
          small="true"
        />
        <badge v-else text="Not Found" color="grey" small="true" />
      </div>
    </template>
    <template v-slot:item.product_name="{ item }">
      <div>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="d-inline-block text-truncate secondary--text"
              style="max-width:400px"
            >
              {{ item["product_name"] }}
            </span>
          </template>
          <span>{{ item["product_name"] }}</span>
        </v-tooltip> -->
        <!-- <div class="ma-0 text-truncate md-text secondary--text">
          {{ item["product_name"] }}
        </div> -->
        <div class="ma-0 text-truncate md-text secondary--text">
          {{ item['product_name'] }}
        </div>
        <div v-if="item.product_brand" class="font-weight-medium text-truncate">
          {{ item['product_brand'].toUpperCase() }}
        </div>
        <div v-if="item.match_user_ref" class="text-truncate">
          Sku : {{ item['match_user_ref'] }}
        </div>
        <div
          v-if="
            item[user_website.alias] != undefined &&
            item[user_website.alias].product.offers[0].changeDate.length
          "
        >
          Update date :
          {{ item[user_website.alias].product.offers[0].changeDate }}
        </div>
      </div>
    </template>
  </v-data-table>
</template>
<script>
  import axios from 'axios'
  export default {
    props: ['filter', 'changed', 'favorite', 'component_name'],
    data: () => ({
      user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
      pagination: { rowsPerPage: 5, totalItems: 0 },
      data_loading: false,
      data_total: 0,
      table: {
        headers: [
          {
            text: 'Image',
            value: 'image',
            width: '3%',
            sortable: false,
          },
          {
            text: 'Product',
            value: 'product_name',
            width: '80%',
            sortable: false,
          },
          {
            text: 'Price',
            value: 'price',
            width: '16%',
            sortable: false,
            align: 'center',
          },
        ],
        items: [
          {
            product_image: '',
            product_name: '',
            brand: '',
            price: 0,
          },
        ],
      },
    }),
    methods: {
      setItemPerPage(val) {
        this.pagination.rowsPerPage = val
      },
      async getProducts() {
        this.data_loading = true
        let from = this.pagination.page * this.pagination.rowsPerPage - this.pagination.rowsPerPage
        let size = this.pagination.rowsPerPage

        let params = null
        if (this.favorite) {
          params = {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
            favorite_only: this.favorite,
            from,
            size,
          }
        } else
          params = {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
            ...this.filter,
            raw_price_index: this.filter.price_index,
            from,
            size,
          }

        this.table.items = await axios.get('/api/products/list', { params }).then((res) => {
          if (res) {
            this.favorites_loaded = true
            this.data_total = res.data.total
            return res.data.data
          }
          return []
        })
        this.data_loading = false
      },
    },
    watch: {
      pagination: {
        handler() {
          try {
            this.getProducts()
          } catch (error) {
            console.log('pagination', error)
          }
        },
        deep: true,
      },
      changed: {
        deep: true,
        async handler() {
          await this.getProducts()
        },
      },
    },
  }
</script>
<style scoped>
  .matches-table >>> tbody tr :hover {
    cursor: pointer;
  }
  .matches-table >>> tbody tr td:nth-child(2) {
    max-width: 300px;
  }
  .matches-table >>> tbody tr td:nth-child(4) {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 800px;
    padding: 0px;
  }
</style>
