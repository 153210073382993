<template>
  <v-data-table
    class="events-table"
    :headers="table.headers"
    :items="table.data"
    :loading="loading"
    :items-per-page="10"
    :options.sync="pagination"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }"
    :server-items-length="total_items"
    @click:row="showDetails"
  >
    <template v-slot:item.errors="{ item }">
      <badge
        :text="item.errors.length"
        color="error"
        small="true"
        cursor="pointer"
      />
    </template>
    <template v-slot:item.accounts="{ item }">
      {{ Object.keys(item.from).length }}
    </template>
    <template v-slot:item.users="{ item }">
      {{ item.users_count }}
    </template>
    <template v-slot:item.pages="{ item }">
      {{ Object.keys(item.pages).length }}
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";
import moment from "moment";
import badge from "../common/badge.vue";

export default {
  components: { badge },
  name: "eventsLogsTable",
  data() {
    return {
      loading: false,
      pagination: {},
      total_items: 0,
      table: {
        headers: [
          {
            text: "Function",
            value: "tag",
            sortable: false,
            width: "20%",
          },
          {
            text: "Call count",
            value: "call_count",
            sortable: false,
            width: "10%",
            align: "center",
          },

          {
            text: "Errors",
            value: "errors",
            sortable: false,
            width: "10%",
            align: "center",
          },
          {
            text: "Accounts count",
            value: "accounts",
            sortable: false,
            width: "10%",
            align: "center",
          },
          {
            text: "Users count",
            value: "users",
            sortable: false,
            width: "10%",
            align: "center",
          },
          {
            text: "Pages count",
            value: "pages",
            sortable: false,
            width: "10%",
            align: "center",
          },
          {
            text: "Date",
            value: "date",
            sortable: false,
            width: "20%",
          },
        ],
        data: [],
      },
    };
  },
  computed: {},
  methods: {
    showDetails(item) {
      // console.log(item);
      this.$emit("show-details", item);
    },
    async getEventLogs() {
      this.loading = true;
      let from =
        this.pagination.page * this.pagination.itemsPerPage -
        this.pagination.itemsPerPage;
      let size = this.pagination.itemsPerPage;
      await axios
        .get("/api/admin", {
          params: {
            from,
            size,
            date: moment(Date.now()).format("YYYY-MM-DD"),//"2020-12-06"//
          },
        })
        .then((res) => {
          this.table.data = res.data.items;
          this.total_items = res.data.count;

          this.table.data.map((elt) => {
            let item = elt;
            Object.keys(item.from).map((account) => {
              if (account != "count") {
                item.from[account].info = {
                  company: res.data.accounts[account].company,
                  website: res.data.accounts[account].website.alias,
                };
                Object.keys(item.from[account]).map((user) => {
                  if (user != "count" && user != "info") {
                    item.from[account][user] = {
                      count: item.from[account][user],
                      fullname: res.data.users[user].fullname,
                      email: res.data.users[user].email,
                      username: res.data.users[user].username,
                    };
                  }
                });
              }
            });
            return item;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    this.getEventLogs();
    // this.$socket.client.on("globalListener", (data) => {
    //   if (data.dataChanged && data.cause == "alertChanges") {
    //     this.getAlerts();
    //   }
    // });
  },
  watch: {
    pagination: {
      handler() {
        this.loading = true;
        try {
          this.getEventLogs();
        } catch (error) {
          console.log("pagination", error);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.events-table {
  width: 100%;
}
.events-table >>> tbody tr :hover {
  cursor: pointer;
}
</style>
