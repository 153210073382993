<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="md-text">Favorite products table</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <loadingProgress
            :loading="loading"
            text="Loading favorite products..."
          />
          <comparePricesTable
            :changed="data_changed"
            :loading="loading"
            :favorite_page="true"
            :total="total_data"
            :component_name="component_name"
            @confirm-unfavorite="showUnfavoriteConfirm"
            @show-price-chart="showPriceChart"
            @show-edit-modal="showEditModal"
            @confirm-delete="confirmDelete"
            @show-notification="showNotification"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <snackbar
      :show="snackbar"
      :color="snackbar_color"
      :msg="snackbar_msg"
      :icon="snackbar_icon"
    />
    <deleteMatchModal
      :ProductToDelete="selectedProductToAction"
      :show="show_delete_modal"
      :component_name="component_name"
      @callback-function="dataChanged"
    />

    <editMatchModalCustom
      :ProductToEdit="selectedProductToAction"
      :show="show_edit_modal"
      :component_name="component_name"
      @confirm-edit="dataChanged"
      @show-notification="showNotification"
      v-if="user_website.id == source_id"
    />
    <editMatchModal
      :ProductToEdit="selectedProductToAction"
      :show="show_edit_modal"
      :component_name="component_name"
      @confirm-edit="dataChanged"
      @show-notification="showNotification"
      v-else
    />
    <deleteModal
      :show="unfavorite_dialog"
      title="Delete alert"
      :text="`Are you sure you want to delete this product from favorite list ( ${to_unfavorite.product_name} ) ?`"
      :callback="removeFavorite"
      :component_name="component_name"
    />

    <histogramchart
      :selected_product="selectedProductToAction"
      :show="show_histograme_modal"
      :component_name="component_name"
    />
  </div>
</template>
<script>
import axios from "axios";

import comparePricesTable from "../common/comparePricesTable.vue";
import deleteMatchModal from "../common/deleteMatchModal.vue";
import editMatchModal from "../common/editMatchModal.vue";
import editMatchModalCustom from "../common/editMatchModalCustom.vue";
import histogramchart from "../common/histogramChart";

export default {
  name: "favoriteProduct",
  components: {
    comparePricesTable,
    editMatchModal,
    editMatchModalCustom,
    deleteMatchModal,
    histogramchart,
  },
  data() {
    return {
      user_website: localStorage.user_website
        ? JSON.parse(localStorage.user_website)
        : {},
      source_id: "6019aee744817b0f85ff85bb",
      snackbar: false,
      snackbar_msg: "",
      snackbar_color: "",
      snackbar_icon: "",
      selectedProductToAction: {
        match_id: "",
        match_ref: "",
        product_brand: "",
        product_name: "",
        product_image: "",
      },
      loading: false,
      data_changed: false,
      to_unfavorite: "",
      unfavorite_dialog: "",
      edit_dialog: false,
      total_data: 0,
      show_histograme_modal: false,
      show_delete_modal: false,
      show_edit_modal: false,
      component_name: this.$router.history.current.fullPath,
    };
  },
  methods: {
    showUnfavoriteConfirm(payload) {
      this.to_unfavorite = payload;
      this.unfavorite_dialog = !this.unfavorite_dialog;
    },
    dataChanged() {
      this.data_changed = !this.data_changed;
    },
    async removeFavorite() {
      await axios
        .post(`/api/products/favorite`, {
          account: localStorage.getItem("account_id"),
          user: localStorage.getItem("user_id"),
          component: this.component_name,
          id_match: this.to_unfavorite.match_id,
          favorite: false,
        })
        .then((res) => {
          if (res.data.status) {
            this.dataChanged();
            this.showNotification({
              msg: `the product named ( ${this.to_unfavorite.product_name} ) has been successfully removed from favorites, check your favorites list`,
              type: "error",
              icon: "heart-off",
            });
          }
        })
        .finally(() => (this.unfavorite_dialog = false));
    },
    showPriceChart(item) {
      this.selectedProductToAction = item;
      this.show_histograme_modal = !this.show_histograme_modal;
    },
    showEditModal(item) {
      this.selectedProductToAction = item;
      this.show_edit_modal = !this.show_edit_modal;
    },
    confirmDelete(item) {
      this.selectedProductToAction = item;
      this.show_delete_modal = !this.show_delete_modal;
    },
    showNotification(payload) {
      this.snackbar_color = payload.type;
      this.snackbar_msg = payload.msg;
      this.snackbar_icon = payload.icon;
      this.snackbar = !this.snackbar;
    },
  },
  mounted() {},
};
</script>
