<template>
  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">
    <template v-slot:activator="{ on }">
      <v-icon v-on="on" class="mx-4 cursor-pointer" color="grey">$info</v-icon>
    </template>
    <span><slot/></span>
  </v-tooltip>
</template>
<script>
export default {
    name:"v-info",
    props:['bottom','top','left','right']
}
</script>
