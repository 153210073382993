import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // default
    values: {
      fullname: 'mdi-form-textbox',
      username: 'mdi-account',
      password: 'mdi-form-textbox-password',
      email: 'mdi-email',
      account: 'mdi-account-multiple',
      website: 'mdi-web',
      edit: 'mdi-pencil',
      delete: 'mdi-delete',
      eye: 'mdi-eye',
      eye_off: 'mdi-eye-off',
      add: 'mdi-plus',
      download: 'mdi-download-outline',
      file_download: 'mdi-file-download-outline',
      eye_outline: 'mdi-eye-outline',
      circle: 'mdi-checkbox-blank-circle',
      heart: 'mdi-heart',
      heart_off: 'mdi-heart-off',
      search: 'mdi-magnify',
      chart_arc:'mdi-chart-arc',
      back:'mdi-chevron-left',
      arrow_right:'mdi-arrow-right',
      arrow_left:'mdi-arrow-left',
      arrow_up:'mdi-chevron-up',
      arrow_down:'mdi-chevron-down',
      image:'mdi-file-image-outline',
      info:'mdi-information-outline',
      filter:'mdi-filter-outline',
      reset:'mdi-refresh'
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#464c5b',
        secondary: '#20c2de',
        error: '#f96654',
        delete: '#f96654',
        success: '#42b983',
        red: "#bc3343",
        green: "#63ac75",
        warning: "#ff7001", 
        facebook:"#3b5998",
        twitter:"#00acee",
        linkedin:"#0e76a8",
        youtube:"#c4302b",
        crawloOrange:"#e86f5c"
      },
      dark: {
        primary: '#464c5b',
        secondary: '#20c2de',
        error: '#f96654',
        delete: '#f96654',
        success: '#42b983',
        red: "#bc3343",
        green: "#63ac75",
        warning: "#ff7001",
        facebook:"#3b5998",
        twitter:"#00acee",
        linkedin:"#0e76a8",
        youtube:"#c4302b",
        crawloOrange:"#e86f5c"
      },
    },
  },
});
