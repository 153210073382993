<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <span class="md-text"> Competitor price comparison </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="flex-grow-1 flex-shrink-0">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              v-model="filter.q"
              label="Search"
              hide-details="auto"
              clearable
              color="secondary"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-select
              class="w-100"
              v-model="filter.sort"
              label="Sort"
              :items="filter.sort_items"
              color="secondary"
              outlined
              hide-details
            ></v-select>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon class="ml-2" v-on="on" @click="sortOrder()">
                  <v-icon>{{
                    filter.sort_order == 'desc'
                      ? 'mdi-sort-numeric-ascending'
                      : 'mdi-sort-numeric-descending'
                  }}</v-icon>
                </v-btn>
              </template>
              <span>{{ filter.sort_order == 'desc' ? 'Ascending sort' : 'Descending sort' }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2">
            <v-select
              class="w-100"
              v-model="filter.availability"
              :items="filter.availability_items"
              label="Availability"
              color="secondary"
              outlined
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="auto" class="d-flex align-center">
            <v-btn-toggle mandatory>
              <v-btn
                @click="
                  is_percentage = display_text[0]
                  formatData()
                "
              >
                {{ display_text[0] }}
              </v-btn>
              <v-btn
                @click="
                  is_percentage = display_text[1]
                  formatData()
                "
              >
                {{ display_text[1] }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" class="d-flex align-center flex-row-reverse">
            <div class="pl-2"><v-icon color="#ffe9ad">$circle</v-icon> I am Equal</div>
            <div class="pl-2"><v-icon color="#d7f6ca">$circle</v-icon> I am Cheaper</div>
            <div class="pl-2"><v-icon color="#ffadbc">$circle</v-icon> I am Higher</div>
          </v-col>

          <v-col cols="12" md="12" sm="12" style="min-height: 200px" class="d-flex align-center">
            <loadingProgress
              :loading="loading"
              text="Loading competitors price comparison data..."
            />
            <v-simple-table v-if="!loading" class="w-100">
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, index) in data" :key="index">
                    <td style="width: 20%">
                      <div class="font-weight-bold">Me vs {{ aliasTitle(item.alias) }}</div>
                      <div class="text--secondary">{{ item.total }} products</div>
                    </td>
                    <td class="d-flex flex-row align-center px-0" style="height: 80px">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <div
                            v-on="on"
                            class="d-flex align-center justify-center"
                            style="
                              color: #e44e69;
                              background: #ffadbc;
                              height: 50px;
                              overflow: auto;
                            "
                            :style="'width: ' + percentage(item.higher.value, item.total) + '%'"
                          >
                            <strong class="text-center">{{
                              is_percentage == '% Percentage'
                                ? item.higher.percentage > 0
                                  ? item.higher.percentage + ' %'
                                  : ''
                                : item.higher.value > 0
                                  ? item.higher.value +
                                    (item.higher.percentage > 5 ? ' product' : '')
                                  : ''
                            }}</strong>
                          </div>
                        </template>
                        <span
                          >I'm higher<br />
                          {{ item.higher.percentage + ' %' }} <br />{{
                            item.higher.value + ' product'
                          }}</span
                        >
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <div
                            v-on="on"
                            class="d-flex align-center justify-center"
                            style="
                              color: #50c021;
                              background: #d7f6ca;
                              height: 50px;
                              overflow: auto;
                            "
                            :style="'width: ' + percentage(item.cheaper.value, item.total) + '%'"
                          >
                            <strong class="text-center">{{
                              is_percentage == '% Percentage'
                                ? item.cheaper.percentage > 0
                                  ? item.cheaper.percentage + ' %'
                                  : ''
                                : item.cheaper.value > 0
                                  ? item.cheaper.value +
                                    (item.cheaper.percentage > 5 ? ' product' : '')
                                  : ''
                            }}</strong>
                          </div>
                        </template>
                        <span
                          >I'm cheaper<br />
                          {{ item.cheaper.percentage + ' %' }} <br />{{
                            item.cheaper.value + ' product'
                          }}</span
                        >
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <div
                            v-on="on"
                            class="d-flex align-center justify-center"
                            style="
                              color: #e0a500;
                              background: #ffe9ad;
                              height: 50px;
                              overflow: auto;
                            "
                            :style="'width: ' + percentage(item.equal.value, item.total) + '%'"
                          >
                            <strong class="text-center">{{
                              is_percentage == '% Percentage'
                                ? item.equal.percentage > 0
                                  ? item.equal.percentage + ' %'
                                  : ''
                                : item.equal.value > 0
                                  ? item.equal.value + (item.equal.percentage > 5 ? ' product' : '')
                                  : ''
                            }}</strong>
                          </div>
                        </template>
                        <span
                          >Equal<br />
                          {{ item.equal.percentage + ' %' }} <br />{{
                            item.equal.value + ' product'
                          }}</span
                        >
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import axios from 'axios'
  export default {
    name: 'competitorPriceComparison',
    components: {},
    data() {
      return {
        loading: false,
        is_percentage: '% Percentage',
        display_text: ['% Percentage', 'Absolute'],
        filter: {
          q: '',
          availability: 'All',
          sort_order: 'desc',
          sort: 'Product count',
          availability_items: ['All', 'In Stock', 'Out Of Stock'],
          sort_items: ['Higher', 'Cheaper', 'Equal', 'Product count'],
        },
        data: [],
        competitors_compare: {},
        component_name: this.$router.history.current.fullPath,
      }
    },
    methods: {
      percentage(value, total) {
        return (value / total) * 100
      },
      async getProductsComparison() {
        this.loading = true
        await axios
          .get('/api/products/productcomparaison', {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
            },
          })
          .then((res) => {
            if (res) {
              this.competitors_compare = res.data.productComparaisonData
              this.formatData()
            }
          })
        this.loading = false
      },
      sortOrder() {
        this.filter.sort_order = this.filter.sort_order == 'desc' ? 'asc' : 'desc'
        this.sortData()
      },
      sortData() {
        let sort_order = this.filter.sort_order
        let is_percentage = this.is_percentage
        switch (this.filter.sort) {
          case 'Higher':
            this.data = this.data.sort((a, b) => {
              return sort_order == 'desc'
                ? is_percentage == '% Percentage'
                  ? b.higher.percentage - a.higher.percentage
                  : b.higher.value - a.higher.value
                : is_percentage == '% Percentage'
                  ? a.higher.percentage - b.higher.percentage
                  : a.higher.value - b.higher.value
            })
            break
          case 'Cheaper':
            this.data = this.data.sort((a, b) => {
              return sort_order == 'desc'
                ? is_percentage == '% Percentage'
                  ? b.cheaper.percentage - a.cheaper.percentage
                  : b.cheaper.value - a.cheaper.value
                : is_percentage == '% Percentage'
                  ? a.cheaper.percentage - b.cheaper.percentage
                  : a.cheaper.value - b.cheaper.value
            })
            break
          case 'Equal':
            this.data = this.data.sort((a, b) => {
              return sort_order == 'desc'
                ? is_percentage == '% Percentage'
                  ? b.equal.percentage - a.equal.percentage
                  : b.equal.value - a.equal.value
                : is_percentage == '% Percentage'
                  ? a.equal.percentage - b.equal.percentage
                  : a.equal.value - b.equal.value
            })
            break
          case 'Product count':
            this.data = this.data.sort((a, b) => {
              return sort_order == 'desc' ? b.total - a.total : a.total - b.total
            })
            break
        }
      },
      formatData() {
        this.data = Object.keys(this.competitors_compare).map((elt) => {
          let higher =
            this.filter.availability == 'In Stock'
              ? this.competitors_compare[elt].higher.in_stock
              : this.filter.availability == 'Out Of Stock'
                ? this.competitors_compare[elt].higher.out_of_stock
                : this.competitors_compare[elt].higher.all
          let cheaper =
            this.filter.availability == 'In Stock'
              ? this.competitors_compare[elt].cheaper.in_stock
              : this.filter.availability == 'Out Of Stock'
                ? this.competitors_compare[elt].cheaper.out_of_stock
                : this.competitors_compare[elt].cheaper.all
          let equal =
            this.filter.availability == 'In Stock'
              ? this.competitors_compare[elt].equals.in_stock
              : this.filter.availability == 'Out Of Stock'
                ? this.competitors_compare[elt].equals.out_of_stock
                : this.competitors_compare[elt].equals.all
          let total =
            this.filter.availability == 'In Stock'
              ? this.competitors_compare[elt].total.in_stock
              : this.filter.availability == 'Out Of Stock'
                ? this.competitors_compare[elt].total.out_of_stock
                : this.competitors_compare[elt].total.all

          let obj = {
            alias: elt,
            higher: {
              value: higher,
              percentage: total > 0 ? this.percentageCalc(higher, total) : 0,
            },
            cheaper: {
              value: cheaper,
              percentage: total > 0 ? this.percentageCalc(cheaper, total) : 0,
            },
            equal: {
              value: equal,
              percentage: total > 0 ? this.percentageCalc(equal, total) : 0,
            },
            total,
          }
          return obj
        })
        this.sortData()
        if (this.filter.q != null && this.filter.q.length) {
          let search = this.filter.q.toLowerCase()
          this.data = this.data.filter((elt) => {
            return elt.alias.toLowerCase().indexOf(search) > -1
          })
        }
      },
      aliasTitle(alias) {
        return alias[0].toUpperCase() + alias.slice(1)
      },
    },
    mounted() {
      this.getProductsComparison()
    },
    watch: {
      filter: {
        deep: true,
        handler() {
          this.formatData()
        },
      },
    },
  }
</script>
<style scoped>
  .indecator {
    width: 35%;
  }
</style>
