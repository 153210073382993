let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const interceptor = (axiosInstance, router, store) => (error) => {
  const _axios = axiosInstance
  const originalRequest = error.config
  //if response equal 403 let's redirect user to subscription page
  if (error.response.status === 403) {
    router.push({
      name: 'Billing',
      path: '/billing',
      params: {
        email: window.localStorage.getItem('email'),
        account_id: window.localStorage.getItem('account_id'),
        customer_id: window.localStorage.getItem('customer_id'),
      },
    })
  }
  if (error.response.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({
          resolve,
          reject,
        })
      })
        .then((token) => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token
          return _axios.request(originalRequest)
        })
        .catch((err) => {
          return Promise.reject(err)
        })
    }

    originalRequest._retry = true
    isRefreshing = true

    let id = localStorage.getItem('account_id')
    let username = localStorage.getItem('user')
    const refreshToken = window.localStorage.getItem('refresh_token') || 'refresh123xyz'

    return new Promise((resolve, reject) => {
      axiosInstance
        .post('/api/user/refresh', {
          id,
          username,
          refreshToken,
        })
        .then(({ data }) => {
          if (data.access_token) {
            window.localStorage.setItem('user-token', data.access_token)
            window.localStorage.setItem('refresh-Token', data.refresh_token)
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token
            originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token
            processQueue(null, data.token)
            resolve(_axios(originalRequest))
          } else {
            store.dispatch('AUTH_LOGOUT')
            if (router.currentRoute.path !== '/login') {
              router.push('/login')
            }
            processQueue(Error('error auth'), null)
            reject(Error('error auth'))
          }
        })
        .catch((err) => {
          store.dispatch('AUTH_LOGOUT')
          if (router.currentRoute.path !== '/login') {
            router.push('/login')
          }
          processQueue(err, null)
          reject(err)
        })
        .then(() => {
          isRefreshing = false
        })
    })
  }
  return Promise.reject(error)
}

export default interceptor
