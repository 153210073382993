<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <!-- <th></th> -->
          <th>Competitors</th>
          <th>Product</th>
          <th>Price</th>
          <th class="text-center">Stock</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="competitor in competitors"
          :key="competitor.website.id"
          class="tabel-row"
          :class="competitor.product.not_found ? 'not_found_product' : ''"
        >
          <!-- <td class="pa-0">
            <div class="w-100 d-flex justify-center" v-if="competitor.product.image">
              <img class="product-image" :src="competitor.product.image" />
            </div>
          </td> -->
          <td class="pa-0">
            <div class="d-flex align-center">
              <img
                :src="getWebsiteFavicon(competitor.website.url)"
                :alt="competitor.website.alias"
                height="25"
                class="mr-3 favicon-img"
              />
              <v-icon class="mr-2" size="medium" :color="competitor.website.config.color"
                >$circle</v-icon
              >
              <span>
                <a :href="competitor.product.url" target="_balank">
                  {{ competitor.website.alias }}
                </a>
              </span>
              <span class="pa-2">
                <img
                  v-if="competitor.product.isGoogleShoppingProduct"
                  :src="require(`../../assets/google_shopping_favicon.png`)"
                  width="15"
                  height="15"
                  class="mr-2 favicon-img"
                />
              </span>
            </div>
          </td>
          <td class="pa-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="d-inline-block text-truncate"
                  style="max-width: 300px"
                >
                  {{ competitor.product.name }}
                </span>
              </template>
              <span>{{ competitor.product.name }}</span>
            </v-tooltip>
            <!-- <span
              ><a
                :href="`${competitor.product.url}`"
                class="text-truncate"
                target="_blank"
                >{{
                  competitor.product.name.length > 20
                    ? competitor.product.name.substring(0, 20).concat("...")
                    : competitor.product.name
                }}</a
              ></span
            > -->
          </td>
          <td class="pa-0">
            <span
              v-html="
                priceFormatter(competitor, product_match.hight_price, product_match.low_price, true)
              "
            ></span>
            <div
              class="sm-text tex--secondary"
              v-if="competitor.product.offers && competitor.product.offers.length"
            >
              {{ competitor.product.offers[0].changeDate }}
            </div>
          </td>
          <td class="text-center pa-0">
            <badge
              class="sm-text"
              small="true"
              :text="competitor.product.offers[0].availability"
              :color="competitor.product.offers[0].availability == 'InStock' ? 'success' : 'error'"
            />
          </td>
          <td>
            <v-icon v-if="competitor.product.validated" color="success" small
              >mdi-check-decagram</v-icon
            >
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
  export default {
    props: {
      product_match: Object,
    },
    data() {
      return {
        competitors: [],
      }
    },
    async mounted() {
      this.initialisation()
    },
    methods: {
      initialisation() {
        this.competitors = []
        Object.keys(this.product_match).forEach((key) => {
          if (this.product_match[key].product) {
            if (this.product_match[key].website.alias == localStorage.getItem('website_alias'))
              this.competitors.unshift(this.product_match[key])
            else this.competitors.push(this.product_match[key])
          }
        })
      },
    },
    watch: {
      product_match: {
        deep: true,
        handler() {
          this.initialisation()
        },
      },
    },
  }
</script>
<style scoped>
  .tabel-row {
    height: 60px;
  }
  .product-image {
    object-fit: contain;
    max-width: 80px;
    max-height: 60px;
    background-size: cover;
  }
</style>
