<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title><span class="md-text">Delete confirmation</span></v-card-title>
      <v-card-text>
        <v-alert type="error" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
          Before proceeding, please confirm if you want to delete the selected
          {{ ProductsToDelete.length }} products ?
        </v-alert>

        <div v-if="submit_loading">
          <v-progress-linear
            v-model="deletingProgressPercentage"
            color="error"
            style="pointer-events: none"
            striped
          ></v-progress-linear>
          Deleting {{ deletingProgress }}/{{ ProductsToDelete.length }}
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <submitButton
          :submitFunction="deleteProducts"
          :submit_loading="submit_loading"
          text="Yes, delete it!"
          color="primary"
          :just_text="true"
        />
        <v-btn color="error" @click="dialog = false">cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  export default {
    name: 'deleteMatchModalMultipl',
    props: ['ProductsToDelete', 'show', 'component_name'],
    data() {
      return {
        dialog: false,
        deleting: false,
        deletingProgress: 0,
        deletingProgressPercentage: 0,
        submit_loading: false,
        deletingErrors: [],
      }
    },
    methods: {
      async wait(time = 500) {
        return await new Promise((resolve) => setTimeout(resolve, time))
      },
      async getPercentageFromProgress() {
        this.deletingProgressPercentage =
          (this.deletingProgress / this.ProductsToDelete.length) * 100
      },
      async deleteProducts() {
        this.submit_loading = true
        for (let i = 0; i < this.ProductsToDelete.length; i++) {
          /**
           * waiting to reduce the rate of the requests to the server
           */
          await this.wait(100)
          await axios
            .get(`/api/products/delete`, {
              params: {
                account: localStorage.getItem('account_id'),
                user: localStorage.getItem('user_id'),
                component: this.component_name,
                ref: this.ProductsToDelete[i].match_ref,
                match: this.ProductsToDelete[i].match_id,
              },
            })
            .catch((err) => {
              this.deletingErrors.push({
                ref: this.ProductsToDelete[i].match_ref,
                match: this.ProductsToDelete[i].match_id,
                error: err.response.data.error,
              })
            })
          this.deletingProgress = i + 1
          this.getPercentageFromProgress()
        }
        /**
         * we are waiting to make sure that the data is sync between mongodb and elasticsearch
         * so we can avoid some data displaying issues in the dashboard
         */
        await this.wait(1000)
        this.submit_loading = false
        this.dialog = false
        this.deletingProgress = 0
        this.$emit('callback-function')
      },
    },
    watch: {
      show: {
        handler() {
          this.dialog = this.dialog ? false : true
        },
      },
    },
  }
</script>
<style scoped>
  .product-image-delete {
    height: 100px;
  }
  .brand-text {
    font-size: 1rem;
    font-weight: 600;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
  .delete-msg {
    font-size: 1.4em;
  }
</style>
