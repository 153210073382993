<template>
  <v-dialog v-model="dialog" eager max-width="60%">
    <v-card>
      <v-card-title class="pb-0">
        <v-row>
          <v-col cols="6" class="py-0">
            <span class="md-text">Price index history</span>
            <v-info top>Represents the history of price index</v-info>
          </v-col>
          <v-col cols="6" class="py-0 d-flex align-center">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              dense
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  placeholder="select a range"
                  prepend-icon="mdi-calendar-range-outline"
                  readonly
                  dense
                  hide-details
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker small v-model="dates" :max="max_date" no-title dense range scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="changeRange"> OK </v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn color="grey" icon light @click="reset" class="mx-2">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              class="ml-auto"
              v-on="{ ...menu }"
              outlined
              small
              color="primary"
              @click="exportChart()"
            >
              Export chart
              <v-icon right dark> $file_download </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-btn icon class="ml-4" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pb-0 d-flex align-center" style="min-height: 280px">
        <v-container class="pt-0">
          <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
            <loadingProgress :loading="loading" text="Loading price index history..." />
          </v-col>
          <v-row v-show="!loading">
            <v-col cols="12" class="d-flex align-center justify-center">
              <span v-if="no_data" class="md-text text-center">
                There is no history in this range
                <span class="error--text">({{ dateRangeText }})</span> !
                <br />
                please try to select a valid range or go back
                <br />
                <v-btn color="primary" outlined light small @click="reset" class="ml-2">
                  init
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </span>
              <canvas
                v-show="!no_data"
                ref="priceindexhistorychart"
                id="priceindexhistorychart"
                style="width: 100% !important; height: auto !important"
              ></canvas>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'
  import Chart from 'chart.js'
  import XLSX from 'xlsx/xlsx.mini'
  export default {
    name: 'notificationModal',
    props: ['show', 'component_name'],
    data() {
      return {
        myChart: {},
        min: 0,
        max: 100,
        labels: [],
        dialog: false,
        loading: false,
        gradient: '',
        gradient2: '',
        dates: [],
        menu: false,
        no_data: false,
        statistics: {
          data: [],
          price_index: 0,
        },
        allowed_start_date: 0,
        max_date: moment(Date.now()).format('YYYY-MM-DD'),
      }
    },
    computed: {
      dateRangeText() {
        return this.dates.join(' ~ ')
      },
    },
    methods: {
      async changeRange() {
        let data = await this.getStatistics()
        let labels = []
        let dataset_data = []
        data.map((datum) => {
          labels.push(datum.date)
          dataset_data.push(datum.price_index)
        })
        this.myChart.data.labels = labels
        this.myChart.data.datasets[0].data = dataset_data
        this.myChart.update()
      },
      reset() {
        this.dates = []
        this.changeRange()
      },
      exportChart() {
        let data_to_export = []
        this.myChart.data.labels.map((elt, index) => {
          let indexs = { date: elt }
          indexs.index = this.myChart.data.datasets[0].data[index]
          data_to_export.push(indexs)
        })
        let workSheet = XLSX.utils.json_to_sheet(data_to_export)
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, workSheet, 'index chart data')
        XLSX.writeFile(wb, `index_chart_export.xlsx`)
      },
      async initPriceIndexHistory() {
        const data = await this.getStatistics()

        let labels = []
        let datasets = [
          {
            label: '',
            data: [],
            borderSkipped: true,
            fill: true,
            pointBorderColor: [],
            hoverBorderWidth: 8,
            borderWidth: 3,
          },
        ]

        const colors = data.map((value) => {
          labels.push(value.date)
          this.statistics.data.push(value.price_index)

          return value.price_index < 0 ? '#63ac75' : value.price_index > 0 ? '#bc3343' : ''
        })

        datasets[0].pointBorderColor = colors
        datasets[0].data = this.statistics.data
        this.statistics.price_index =
          this.statistics.data[this.statistics.data.length - 1].toFixed(2)
        this.labels = labels.map((elt) => elt.split(' ')[0])
        this.max = labels.length - 1
        let temp = labels.length - 30 > 0 ? labels.length - 30 : 0
        this.range = [temp, labels.length]

        let ctx = document.getElementById('priceindexhistorychart')
        ctx.height = 300
        // console.log(labels, datasets);
        this.myChart = new Chart(ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: datasets,
          },
          options: {
            layout: {
              padding: {
                left: 5,
                right: 5,
                top: 15,
                bottom: 15,
              },
            },
            legend: {
              display: false,
            },
            responsive: false,
            scales: {
              xAxes: [
                {
                  ticks: {
                    padding: 50,
                    display: false,
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
            },
          },
          plugins: [
            {
              beforeRender: function (x, options) {
                let c = x.chart
                let dataset = x.data.datasets[0]
                let yScale = x.scales['y-axis-0']
                let yPos = yScale.getPixelForValue(0)
                let gradientFill = c.ctx.createLinearGradient(0, 0, 0, c.height)
                let up = true,
                  down = true
                let temp = yPos / c.height
                if (temp > 1) {
                  up = false
                  temp = 0
                } else if (temp < 0) {
                  down = false
                  temp = 0
                }
                if (down) {
                  gradientFill.addColorStop(0, '#d35f6c')
                  gradientFill.addColorStop(temp == 0 ? 0 : temp - 0.001, '#d35f6c')
                }
                if (up) {
                  gradientFill.addColorStop(temp == 0 ? 0.001 : temp + 0.001, '#94c7a1')
                  gradientFill.addColorStop(1, '#94c7a1')
                }

                let model = x.data.datasets[0]._meta[Object.keys(dataset._meta)[0]].dataset._model
                model.backgroundColor = gradientFill
              },
            },
          ],
        })
      },
      async getStatistics() {
        this.$refs.menu.save(this.dates)
        let from, to, temp1, temp2

        if (this.dates.length) {
          temp1 = this.dates[0].split('-')
          let x = new Date(
            Date.UTC(temp1[0], temp1[1] - 1, temp1[2] != undefined ? temp1[2] : 1, 0, 0, 0),
          )

          temp2 = this.dates[1] == undefined ? this.dates[0].split('-') : this.dates[1].split('-')
          let y = new Date(
            Date.UTC(temp2[0], temp2[1] - 1, temp2[2] != undefined ? temp2[2] : 1, 22, 0, 0),
          )

          if (+x <= +y) {
            from = x.getTime()
            to = y.getTime()
          } else {
            y.setHours(0)
            x.setHours(22)
            from = y.getTime()
            to = x.getTime()
          }
        } else {
          to = 0
          from = 0
        }
        this.loading = true
        this.no_data = false
        return await axios
          .get(`/api/products/statisticshistory`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              from,
              to,
            },
          })
          .then((res) => {
            if (!res.data.statisticsData.length) this.no_data = true
            return res.data.statisticsData
          })
          .finally(() => {
            this.loading = false
          })
      },
    },
    watch: {
      show: {
        handler() {
          this.dialog = true
          this.initPriceIndexHistory()
        },
      },
    },
  }
</script>
