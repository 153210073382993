<template>
  <div>
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card>
          <v-card-text>
            <v-col cols="12" class="ma-0" v-if="loading_billing">
              <loadingProgress :loading="loading_billing" text="Loading..." />
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="false">
      <v-col cols="4" class="pt-0">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card>
              <v-card-title>
                <span class="md-text">Plan details</span>
              </v-card-title>
              <v-card-text style="min-height: 200px" class="d-flex align-center">
                <v-col cols="12" class="ma-0" v-if="customer_loading">
                  <loadingProgress :loading="customer_loading" text="Loading plan details..." />
                </v-col>
                <div class="w-100" v-show="!customer_loading">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="w-100 d-flex justify-center">
                        <badge
                          class="lg-text"
                          :text="selected_subscription_object.status"
                          :color="
                            selected_subscription_object.status == 'canceled' ? 'red' : 'green'
                          "
                          icon="checkbox-marked-circle-outline"
                          icon_align="right"
                        />
                      </div>
                    </template>
                    <span>your payement status is {{ selected_subscription_object.status }}</span>
                  </v-tooltip>
                  <div class="w-100 d-flex justify-center mt-2">
                    <badge
                      v-if="selected_subscription_object_cancel_at"
                      :text="'Cancels' + getDateShort(selected_subscription_object_cancel_at)"
                      color="warning"
                    />
                  </div>
                  <div class="text-center mt-6">
                    <span class="lg-text font-weight-bold">
                      {{ availabl_plans_names[selected_plan] }} plan
                    </span>
                    <p class="text--secondary ma-2">
                      You are currently subscribed to the
                      <span class="font-weight-bold">{{
                        availabl_plans_names[selected_plan]
                      }}</span>
                      plan with
                      <span class="font-weight-bold">monthly billing</span>
                    </p>
                    <div class="mt-4">
                      <v-btn
                        v-if="selected_subscription_object_cancel_at"
                        color="delete"
                        class="white--text w-100 mb-2"
                        @click="cancelCancelation"
                        >Don't cancel</v-btn
                      >

                      <v-btn
                        v-if="!selected_subscription_object_cancel_at"
                        color="delete"
                        class="white--text w-100 mb-2"
                        @click="showPlans"
                        >Upgrade</v-btn
                      >

                      <v-btn
                        class="w-100 mb-2"
                        color="primary"
                        text
                        @click="confirmCancelSubscription"
                        >Cancel Subscription</v-btn
                      >
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-title>
                <span class="md-text"> Payment method </span>
                <v-btn
                  class="ml-auto"
                  outlined
                  small
                  light
                  color="primary"
                  @click="updatePayementMethod"
                  >Update Payement Method
                  <v-icon right dark>mdi-credit-card-outline</v-icon>
                </v-btn></v-card-title
              >
              <v-card-text style="min-height: 200px" class="d-flex align-center justify-center">
                <v-col cols="12" class="ma-0" v-if="card_loading">
                  <loadingProgress :loading="card_loading" text="Loading card details..." />
                </v-col>
                <v-card outlined v-show="!card_loading">
                  <v-card-text class="pa-6">
                    <div class="md-text font-weight-bold" style="letter-spacing: 3px">
                      {{ card.name }}
                    </div>
                    <div class="md-text font-weight-bold mt-4 mb-4" style="letter-spacing: 10px">
                      xxxx xxxx xxxx {{ card.last4 }}
                    </div>
                    <div class="mt-3 d-flex">
                      <div>
                        <span class="md-text mr-2 text--secondary">expires</span>
                        <span class="md-text font-weight-bold" style="letter-spacing: 3px">{{
                          card.exp_date
                        }}</span>
                      </div>

                      <img
                        :src="require(`../../assets/cardsIcones/${card.brand || 'Visa'}.png`)"
                        width="70"
                        class="ml-auto"
                      />
                    </div>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="8" class="pt-0">
        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card>
              <v-card-title><span class="md-text">Invoices</span></v-card-title>
              <v-card-text style="min-height: 100px" class="d-flex align-center">
                <v-col cols="12" class="ma-0" v-if="invoices_loading">
                  <loadingProgress :loading="invoices_loading" text="Loading invoices..." />
                </v-col>
                <v-data-table
                  v-show="!invoices_loading"
                  class="w-100"
                  :headers="headers"
                  :items="invoices"
                  :loading="loading"
                  :items-per-page="5"
                >
                  <template v-slot:item.status="{ item }">
                    <div class="d-flex justify-center mt-4 mb-4">
                      <badge
                        class="w-100 d-flex justify-center"
                        :text="item.status"
                        :color="item.status == 'paid' ? 'green' : 'primary'"
                        :icon="item.status == 'paid' ? 'cash-check' : null"
                        icon_align="right"
                      />
                    </div>
                  </template>

                  <template v-slot:item.date="{ item }">
                    <div>{{ getDate(item.created) }}</div>
                    <div class="sm-text text--secondary">{{ item.number }}</div>
                  </template>

                  <!-- <template v-slot:item.type="{ item }">
                    <div>{{ getPlanName(item.lines.data) }}</div>
                  </template> -->
                  <template v-slot:item.price="{ item }">
                    <div class="font-weight-bold">
                      {{ priceFormatter(item.amount_paid) }}
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn color="grey" v-on="on" icon light :href="item.invoice_pdf">
                          <v-icon>$download</v-icon>
                        </v-btn>
                      </template>
                      <span>Download invoice</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider></v-divider>
          <v-col cols="12">
            <v-card>
              <v-card-title><span class="md-text">Upcoming Invoice</span></v-card-title>
              <v-card-text style="min-height: 100px" class="d-flex align-center">
                <v-col cols="12" class="ma-0" v-if="upcoming_invoice_loading">
                  <loadingProgress
                    :loading="upcoming_invoice_loading"
                    text="Loading upcoming invoice..."
                  />
                </v-col>
                <v-simple-table class="w-100" v-show="!upcoming_invoice_loading">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Status</th>
                        <th class="text-center">Date</th>
                        <!--<th class="text-center">Type</th>-->
                        <th class="text-center">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="w-15">
                          <div class="d-flex justify-center mt-4 mb-4">
                            <badge
                              class="w-100 d-flex justify-center"
                              :text="upcomingInvoice.status"
                              color="warning"
                              icon="progress-clock"
                              icon_align="right"
                            />
                          </div>
                        </td>
                        <td class="text-center">
                          <span>{{ getDate(upcomingInvoice.next_payment_attempt) }}</span>
                        </td>
                        <!-- <td class="text-center">
                          <span v-if="upcomingInvoice.lines">{{
                            getPlanName(upcomingInvoice.lines.data)
                          }}</span>
                        </td> -->
                        <td class="text-center">
                          <span class="font-weight-bold">{{
                            priceFormatter(upcomingInvoice.amount_remaining)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- </v-card-text>
    </v-card> -->

    <v-dialog v-model="plans_dialog" max-width="70%">
      <v-card>
        <v-card-title>
          <span class="md-text">Choose a plan</span>
          <v-btn icon class="ml-auto" @click="plans_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          ><plans
            :selected_plan="availabl_plans.indexOf(selected_plan)"
            :show="plans_dialog"
            @choosen-plan="choosenPlan"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showConfirmModal">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="switch_plans_confirm_dialog" eager max-width="30%">
      <v-card>
        <v-card-title>
          <span class="md-text">Upgrade Confirmation</span>
          <v-btn icon class="ml-auto" @click="switch_plans_confirm_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="warning" class="mb-0 mt-4 font-weight-bold delete-text">
            Are you sure you want to switch to
            {{ availabl_plans_names[selected_plan_to_switch] }} plan ?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="switch_plans_confirm_dialog = false">cancel</v-btn>
          <submitButton
            :submitFunction="upgrade"
            :submit_loading="upgrading_plan"
            color="secondary"
            text="Yes, upgrade it !"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="cancel_confirm_dialog" eager max-width="30%">
      <v-card>
        <v-card-title>
          <span class="md-text"> Cancel Subscription Confirmation </span>
          <v-btn icon class="ml-auto" @click="cancel_confirm_dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-alert type="warning" class="mb-0 mt-4 font-weight-bold">
            Are you sure you want to cancel your subscription ?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <submitButton
            :submitFunction="
              () => {
                cancelSubscription(true)
              }
            "
            :submit_loading="canceling_subscription"
            color="primary"
            text="Yes, cancel it !"
            :just_text="true"
          />
          <v-btn color="delete" class="white--text" @click="cancel_confirm_dialog = false"
            >cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
  import axios from 'axios'
  import moment from 'moment'

  import numeral from 'numeral'
  import plans from '../common/plans.vue'
  import { loadStripe } from '@stripe/stripe-js'

  export default {
    name: 'Billing',
    props: {
      msg: String,
    },
    components: {
      plans,
    },
    data: () => {
      return {
        loading: false,
        plans_dialog: false,
        switch_plans_confirm_dialog: false,
        cancel_confirm_dialog: false,
        customer_id: '',
        availabl_plans: [
          process.env.VUE_APP_PLAN_PREMIUM.replace('price_', ''), //premium
          process.env.VUE_APP_PLAN_PRO.replace('price_', ''), //pro
          process.env.VUE_APP_PLAN_MINI.replace('price_', ''), //mini
        ],
        availabl_plans_names: {
          [process.env.VUE_APP_PLAN_PREMIUM.replace('price_', '')]: 'PREMIUM',
          [process.env.VUE_APP_PLAN_PRO.replace('price_', '')]: 'PROFESSIONAL',
          [process.env.VUE_APP_PLAN_MINI.replace('price_', '')]: 'MINI',
        },
        publishableKey: process.env.VUE_APP_PK_STRIPE_KEY,
        card: {
          lastDigi: '',
        },
        invoices: [],
        upcomingInvoice: {},
        selected_plan: '',
        selected_subscription_object_cancel_at: 0,
        selected_subscription_object: {},
        selected_subscription: '',
        select_plans: false,
        selected_plan_to_switch: '',
        upgrading_plan: false,
        canceling_subscription: false,
        checkout_session_id: '',
        headers: [
          {
            text: 'Status',
            value: 'status',
            width: '15%',
            sortable: true,
          },
          {
            text: 'Date',
            value: 'date',
            width: '30%',
            sortable: true,
            align: 'left',
          },
          // {
          //   text: "Type",
          //   value: "type",
          //   width: "25%",
          //   sortable: false,
          //   align: "center",
          // },
          {
            text: 'Price',
            value: 'price',
            width: '20',
            sortable: false,
            align: 'center',
          },
          {
            text: 'Action',
            value: 'action',
            width: '10%',
            sortable: false,
            align: 'center',
          },
        ],
        customer_loading: false,
        invoices_loading: false,
        upcoming_invoice_loading: false,
        card_loading: false,
        loading_billing: true,
      }
    },
    methods: {
      getPlanName(invoice) {
        return invoice.length
          ? invoice[0].plan.nickname != null && invoice[0].plan.nickname.length
            ? invoice[0].plan.nickname
            : 'No type'
          : ''
      },
      getPlanId() {
        this.selected_plan = this.invoices[0].lines.data.length
          ? this.invoices[0].lines.data[0].plan.id
          : ''
      },
      getSubscriptionId() {
        this.selected_subscription = this.invoices.length ? this.invoices[0].subscription : ''
      },
      getDate(timestamp) {
        if (timestamp) return moment(timestamp * 1000).format('YYYY-MM-DD hh:mm')
        return ''
      },
      getDateShort(timestamp) {
        if (timestamp) return moment(timestamp * 1000).format(' DD MMMM')
        return ''
      },
      getCustomerInfor: async function () {
        this.customer_loading = true

        const subscription_data = await axios
          .get('/api/customer/subscription', {
            params: {
              subscription_id: this.selected_subscription,
            },
          })
          .then((res) => res.data)

        this.selected_subscription_object = subscription_data
        this.selected_subscription_object_cancel_at = subscription_data.cancel_at
        this.selected_plan = subscription_data.plan.id.split('price_')[1]
        this.customer_loading = false
      },
      //get upcoming invoices for the currenct customer
      async getUpcomingInvoices() {
        this.upcoming_invoice_loading = true

        const data = await axios
          .get('/api/billing/upcomingunvoices?customer_id=' + localStorage.getItem('customer_id'), {
            customer_id: localStorage.getItem('customer_id'),
          })
          .then((res) => res.data)

        this.upcomingInvoice = data
        this.upcoming_invoice_loading = false
      },
      //get invoices for connected client
      async getInvoices() {
        this.invoices_loading = true
        const data = await axios
          .get('/api/billing/invoices/?customer_id=' + localStorage.getItem('customer_id'), {
            customer_id: localStorage.getItem('customer_id'),
          })
          .then((res) => res.data.data)

        this.invoices = data
        this.invoices_loading = false
        this.getSubscriptionId()
        this.getCustomerInfor()
      },
      async getCardInfo() {
        this.card_loading = true
        const customer_id = localStorage.getItem('customer_id')
        const customer_cards_data = await axios
          .get('/api/customer/cards', {
            params: {
              customer_id,
            },
          })
          .then((res) => res.data.data)

        if (customer_cards_data.length) {
          let card_details = customer_cards_data[0].card
          let temp = card_details.brand.split('')
          temp[0] = temp[0].toUpperCase()
          card_details.brand = temp.join('')
          card_details.name = customer_cards_data[0].billing_details.name.toUpperCase()
          card_details.exp_date =
            (customer_cards_data[0].card.exp_month > 9
              ? customer_cards_data[0].card.exp_month
              : '0' + customer_cards_data[0].card.exp_month) +
            '/' +
            (customer_cards_data[0].card.exp_year % 2000)
          this.card = card_details
        }
        this.card_loading = false
      },
      async checkoutStripe() {
        const stripe = await loadStripe(this.publishableKey)
        const { error } = await stripe.redirectToCheckout({
          sessionId: this.checkout_session_id,
        })
      },
      async subscribe() {
        const email = localStorage.getItem('email') || this.$route.params.email || ''
        const account_id = localStorage.getItem('account_id') || this.$route.params.account_id || ''
        const response = await axios.post('/api/customer/subscribe', {
          email,
          account_id,
        })
        if (response && response.data && response.data.result.checkout_session.id) {
          this.checkout_session_id = response.data.result.checkout_session.id
          if (this.checkout_session_id) this.checkoutStripe()
        }
      },
      async portalSession() {
        const customer_id =
          localStorage.getItem('customer_id') || this.$route.params.customer_id || ''
        const customerExist = await axios.post('/api/customer/getcustomer', {
          email: localStorage.getItem('email') || this.$route.params.email || '',
        })
        if (customerExist.data && customerExist.data.result && customerExist.data.result.customer) {
          console.log('customer_id', customer_id)
          await axios
            .post('/api/customer/managebilling', {
              customer_id: customerExist.data.result.customer,
              email: localStorage.getItem('email'),
            })
            .then((res) => {
              if (res.data && res.data.portal_session) {
                window.location.href = res.data.portal_session.url
              }
            })
        } else {
          this.subscribe()
        }
      },
      async updatePayementMethod() {
        const customer_id = localStorage.getItem('customer_id')
        const subscription_id = this.selected_subscription

        await axios
          .post('/api/customer/updatesubscription', {
            customer_id,
            subscription_id,
          })
          .then((res) => {
            this.checkout_session_id = res.data.checkout_session.id
            if (this.checkout_session_id) this.checkoutStripe()
          })
      },
      showPlans() {
        this.plans_dialog = !this.plans_dialog
      },
      showConfirmModal() {
        this.switch_plans_confirm_dialog = !this.switch_plans_confirm_dialog
      },
      choosenPlan(plan) {
        this.selected_plan_to_switch = this.availabl_plans[plan]
      },
      //upgrade subscription
      async upgrade() {
        this.upgrading_plan = true
        await axios
          .get('/api/customer/upgrade', {
            params: {
              plan_id: this.selected_plan_to_switch,
              subscription_id: this.selected_subscription,
              account_id: localStorage.getItem('account_id'),
            },
          })
          .then((res) => {})
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.switch_plans_confirm_dialog = false
            this.upgrading_plan = false
            this.plans_dialog = false
          })

        this.loadAllData()
      },
      //confirm subscription canceling
      confirmCancelSubscription() {
        this.cancel_confirm_dialog = !this.cancel_confirm_dialog
      },
      //cancel subscription
      async cancelSubscription(cancel = true) {
        this.canceling_subscription = true
        await axios
          .post('/api/customer/cancelsubscription', {
            subscription_id: this.selected_subscription,
            cancel_subscription: cancel,
          })
          .then((res) => {
            this.selected_subscription_object_cancel_at = res.data.subscription.cancel_at
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.cancel_confirm_dialog = false
            this.canceling_subscription = false
          })

        this.loadAllData()
      },
      //cancel cancelation
      cancelCancelation() {
        this.cancelSubscription(false)
      },
      priceFormatter(amount) {
        // return numeral().format('00.0')
        if (amount >= 0)
          return new Intl.NumberFormat('es-ES', {
            style: 'currency',
            currency: 'EUR',
          }).format(amount / 100)
        else return ''
      },
      async loadAllData() {
        //await this.getInvoices()
        //await this.getUpcomingInvoices()
        //await this.getCardInfo()
      },
    },
    async beforeMount() {
      this.customer_id = localStorage.getItem('customer_id')
      await this.portalSession()
    },
    async created() {},
    async mounted() {
      //console params
    },
  }
</script>
<style scoped>
  .uk-badge {
    padding: 14px;
    font-size: 14px;
    cursor: pointer;
  }
  .notReady {
    background-color: #8ec6ff;
    pointer-events: none;
  }
  .error {
    background-color: #db0f0f;
    pointer-events: none;
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .theme--light.v-application {
    background: none !important;
  }

  .selected_plan {
    border-bottom: solid salmon;
  }

  input,
  .StripeElement {
    height: 40px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #c9c9c9;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
</style>
