<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Competitors</th>
          <th>Product</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="competitor in competitors" :key="competitor.website.id">
          <td class="d-flex image-td">
            <div class="image-container d-flex justify-center">
              <img class="product-image" :src="competitor.product.image" />
            </div>
            <div class="d-flex align-center">
              <img
                :src="
                  'https://8181.integ.crawlo.com/favicon?size=xs&website=' + competitor.website.url
                "
                @error="replaceByDefault"
                :alt="competitor.website.alias"
                height="25"
                class="mr-4 favicon-img"
              />

              <v-icon class="mr-4" :color="competitor.website.config.color">$circle</v-icon>
              <span>
                <a :href="competitor.website.url" target="_balank">
                  {{ competitor.website.alias }}
                </a>
              </span>
            </div>
          </td>

          <td>
            <div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-inline-block text-truncate secondary--text"
                      style="max-width: 400px"
                    >
                      {{ competitor.product.name }}
                    </div>
                  </template>
                  <span>{{ competitor.product.name }}</span>
                </v-tooltip>
              </div>
              <div v-if="competitor.product.brand" class="font-weight-medium text-truncate">
                {{ competitor.product.brand.toUpperCase() }}
              </div>
            </div>
          </td>
          <td>
            <span
              v-html="
                priceFormatter(competitor, product_match.hight_price, product_match.low_price)
              "
            ></span>
            <div>
              <badge
                v-if="competitor != undefined"
                :text="competitor.product.offers[0].availability"
                :color="
                  competitor.product.offers[0].availability == 'InStock' ? 'success' : 'error'
                "
                small="true"
              />
              <badge v-else text="Not Found" color="grey" small="true" />
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
<script>
  export default {
    props: {
      product_match: Object,
    },
    data() {
      return {
        competitors: [],
      }
    },
    async mounted() {
      this.initialisation()
    },
    methods: {
      initialisation() {
        this.competitors = []
        Object.keys(this.product_match).forEach((key) => {
          if (this.product_match[key].product) {
            if (this.product_match[key].website.alias == localStorage.getItem('website_alias'))
              this.competitors.unshift(this.product_match[key])
            else this.competitors.push(this.product_match[key])
          }
        })
      },
    },
    watch: {
      product_match: {
        deep: true,
        handler() {
          this.initialisation()
        },
      },
    },
  }
</script>
<style scoped>
  .tabel-row {
    height: 55px;
  }
  .product-image {
    object-fit: contain;
    max-width: 200px;
    max-height: 80px;
    background-size: cover;
  }
  .image-container {
    width: 100px;
  }
  .image-td {
    height: auto !important;
  }
</style>
