<template>
  <v-dialog v-model="dialog" eager max-width="65%">
    <v-card>
      <v-card-title class="pb-0 d-flex">
        <span class="md-text">{{ selected_event.tag }}</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0 mt-4">
        <v-container class="pt-0">
          <v-row class="d-flex justify-center">
            <v-col cols="auto">
              <cartInfo
                color="success"
                :value="selected_event.call_count"
                title="Total"
                icon="mdi-pound"
                @click="showSection('global')"
              />
            </v-col>
            <v-col cols="auto">
              <cartInfo
                color="error"
                :value="
                  selected_event.errors != undefined
                    ? selected_event.errors.length
                    : 0
                "
                title="Fail"
                icon="mdi-alert-circle-outline"
                @click="showSection('errors')"
              />
            </v-col>
            <v-col cols="auto">
              <cartInfo
                color="info"
                :value="
                  selected_event.pages != undefined
                    ? Object.keys(selected_event.pages).length
                    : 0
                "
                title="Pages"
                icon="mdi-book-open-page-variant-outline"
                @click="showSection('pages')"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center" v-if="section == 'global'">
            <v-col
              cols="6"
              v-for="(item, index) in Object.keys(selected_event.from)"
              :key="index"
            >
              <v-card>
                <v-card-title class="pb-0">
                  <div class="d-flex w-100">
                    <span class="sm-text info--text"
                      >{{ item }}
                      <span
                        class="font-weight-bold"
                        v-if="selected_event.from[item].info.company.length"
                        >({{ selected_event.from[item].info.company }})</span
                      ></span
                    >
                    <span
                      v-if="selected_event.from[item].info.website.length"
                      class="sm-text ml-2"
                    >
                       -{{ selected_event.from[item].info.website }}-
                    </span>
                    <span class="md-text font-weight-bold ml-auto">{{
                      selected_event.from[item].count
                    }}</span>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>user id</th>
                          <th>username</th>
                          <th class="text-center">call count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(child, child_index) in Object.keys(
                            selected_event.from[item]
                          )"
                        >
                          <v-tooltip bottom :key="child_index">
                            <template v-slot:activator="{ on, attrs }">
                              <tr
                                v-bind="attrs"
                                v-on="on"
                                v-if="child != 'count' && child != 'info'"
                              >
                                <td>
                                  {{ child }}
                                </td>
                                <td class="text-truncate">
                                  {{
                                    selected_event.from[item][child].username
                                  }}
                                </td>
                                <td class="text-center">
                                  {{ selected_event.from[item][child].count }}
                                </td>
                              </tr>
                            </template>
                            <span>
                              {{ selected_event.from[item][child].fullname }} -
                              {{ selected_event.from[item][child].email }}
                            </span>
                          </v-tooltip>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="d-flex justify-center" v-if="section == 'errors'">
            <v-col
              cols="12"
              v-for="(error, index) in selected_event.errors"
              :key="index"
            >
              <v-card>
                <v-card-title class="pb-0">
                  <div class="w-100 md-text text-center">
                    {{ formatDate(error.timestamp) }}
                  </div>
                </v-card-title>
                <v-card-text>
                  <codemirror
                    :options="options"
                    class="codemirror-input"
                    :value="
                      JSON.stringify(
                        { params: error.params, error: error.error },
                        null,
                        2
                      )
                    "
                  ></codemirror>
                  <!-- <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>user</th>
                          <th class="text-center">call count</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(child, child_index) in Object.keys(
                            selected_event.from[item]
                          )"
                        >
                          <tr
                            v-if="child != 'count' && child != 'info'"
                            :key="child_index"
                          >
                            <td>{{ child }}</td>
                            <td class="text-center">
                              {{ selected_event.from[item][child].count }}
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table> -->
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import cartInfo from "./cardInfo.vue";
import moment from "moment";

import { codemirror } from "vue-codemirror";
import "codemirror/theme/dracula.css";
import "codemirror/addon/selection/active-line.js";
import "codemirror/mode/javascript/javascript.js";
import CodeMirror from "codemirror/lib/codemirror";
// CodeMirror.defineMode("mymode", () => {
//   return {
//     token(stream, state) {
//       if (isUrl(stream.string)) {
//         stream.next();
//         return "variable";
//       } else {
//         stream.next();
//         return "keyword";
//       }
//     },
//   };
// });
export default {
  name: "eventDetailsModal",
  components: { cartInfo },
  props: ["show", "selected_event"],
  data() {
    return {
      dialog: false,
      tree_items: [],
      section: "global",
    };
  },
  computed: {
    options() {
      return {
        mode: "application/json",
        styleActiveLine: true,
        readOnly: true,
        lineNumbers: true,
        // lineWrapping: false,
        viewportMargin: Infinity,
        // dragDrop: true,
        // inputStyle: "textarea",
        theme: "dracula",
        // lineNumberFormatter: (line) => {
        //   if (this.error == "true")
        //     return line == 1 ? "Your product : " : `Competitor ${line - 1} : `;
        //   else return `Competitor ${line} : `;
        // },

        // lineNumbers: true,
        // mode: "application/json",
        // gutters: ["CodeMirror-lint-markers"],
        // theme: "rubyblue",
        // lint: true,
      };
    },
  },
  methods: {
    formatDate(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD hh:mm:ss");
    },
    showSection(section) {
      this.section = section;
    },
  },
  watch: {
    show: {
      handler() {
        this.dialog = true;
      },
    },
  },
};
</script>
