<template>
  <div>
    <v-row>
      <v-col cols="12"
        ><chartCardExclusive :component_name="component_name"
      /></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card
        outlined
        >
          <v-card-title>
            <span class="md-text">Exclusive Products</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <loadingProgress
                :loading="loading"
                text="Loading pricing data..."
              />
              <comparePricesTable
                :changed="data_changed"
                :loading="loading"
                :exclusive_page="true"
                :component_name="component_name"
                @show-price-chart="showPriceChart"
                @show-edit-modal="showEditModal"
                @confirm-delete="confirmDelete"
                @show-notification="showNotification"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <snackbar
      :show="snackbar"
      :color="snackbar_color"
      :msg="snackbar_msg"
      :icon="snackbar_icon"
    />
    <deleteMatchModal
      :ProductToDelete="selectedProductToAction"
      :show="show_delete_modal"
      :component_name="component_name"
      @callback-function="dataChanged"
    />

    <editMatchModalCustom
      :ProductToEdit="selectedProductToAction"
      :show="show_edit_modal"
      :component_name="component_name"
      @confirm-edit="dataChanged"
      @show-notification="showNotification"
      v-if="user_website.id == source_id"
    />
    <editMatchModal
      :ProductToEdit="selectedProductToAction"
      :show="show_edit_modal"
      :component_name="component_name"
      @confirm-edit="dataChanged"
      @show-notification="showNotification"
      v-else
    />
    <histogramchart
      :selected_product="selectedProductToAction"
      :show="show_histograme_modal"
      :component_name="component_name"
    />
  </div>
</template>
<script>
import axios from "axios";

import comparePricesTable from "../common/comparePricesTable.vue";
import deleteMatchModal from "../common/deleteMatchModal.vue";
import editMatchModal from "../common/editMatchModal.vue";
import editMatchModalCustom from "../common/editMatchModalCustom.vue";
import histogramchart from "../common/histogramChart";
import chartCardExclusive from "../common/chartCardExclusive";
export default {
  name: "favoriteProduct",
  components: {
    comparePricesTable,
    editMatchModal,
    editMatchModalCustom,
    deleteMatchModal,
    histogramchart,
    chartCardExclusive,
  },
  data() {
    return {
      user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
      source_id: "6019aee744817b0f85ff85bb",
      snackbar: false,
      snackbar_msg: "",
      snackbar_color: "",
      snackbar_icon: "",
      selectedProductToAction: {
        match_id: "",
        match_ref: "",
        product_brand: "",
        product_name: "",
        product_image: "",
      },
      loading: false,
      data_changed: false,
      to_unfavorite: "",
      edit_dialog: false,
      show_histograme_modal: false,
      show_delete_modal: false,
      show_edit_modal: false,
      component_name: this.$router.history.current.fullPath,
    };
  },
  methods: {
    dataChanged() {
      this.data_changed = !this.data_changed;
    },
    showPriceChart(item) {
      this.selectedProductToAction = item;
      this.show_histograme_modal = !this.show_histograme_modal;
    },
    showEditModal(item) {
      this.selectedProductToAction = item;
      this.show_edit_modal = !this.show_edit_modal;
    },
    confirmDelete(item) {
      this.selectedProductToAction = item;
      this.show_delete_modal = !this.show_delete_modal;
    },
    showNotification(payload) {
      this.snackbar_color = payload.type;
      this.snackbar_msg = payload.msg;
      this.snackbar_icon = payload.icon;
      this.snackbar = !this.snackbar;
    },
  },
};
</script>
