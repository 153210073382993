<template>
  <v-dialog v-if="dialog" v-model="dialog" persistent max-width="100%">
    <v-card>
      <v-card-title>
        <span class="md-text" ref="modalTitle">Validate product</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
            <div v-if="false">
              <span>Match id : {{ ProductToEdit.match_id }}</span
              ><br />
              <span>Available matches : {{ matches.length }}</span
              ><br />
              <span>Page : </span>{{ searchParams.page }}
            </div>
            <div v-if="submit_loading">
              <v-progress-linear
                indeterminate
                color="error"
                style="pointer-events: none"
                striped
              ></v-progress-linear>
              Updating...
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!submit_loading">
          <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
            <loadingProgress :loading="loading" text="Loading..." />
          </v-col>
          <v-col v-else cols="12" sm="12" md="12">
            <v-card class="sticky-top pa-0">
              <v-card-text class="pa-0">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="pa-2 w-10">
                          <div class="w-100 d-flex justify-center">
                            <img class="product-image" :src="defaultProduct.product.image" />
                          </div>
                        </td>
                        <td class="p2-0 w-15">
                          <div class="d-flex align-center">
                            <img
                              :src="getWebsiteFavicon(defaultProduct.website.url)"
                              :alt="defaultProduct.website.alias"
                              height="25"
                              class="mr-3 favicon-img"
                            />
                            <v-icon
                              class="mr-2"
                              size="medium"
                              :color="defaultProduct.website.config.color"
                              >$circle</v-icon
                            >
                            <span>
                              <a :href="defaultProduct.product.url" target="_balank">
                                {{ defaultProduct.website.alias }}
                              </a>
                            </span>
                          </div>
                        </td>
                        <td class="pa-2 more-info w-40">
                          <div>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <span
                                  v-bind="attrs"
                                  v-on="on"
                                  class="d-inline-block"
                                  style="max-width: 100%"
                                >
                                  {{ defaultProduct.product.name }} </span
                                ><br />
                                <div class="mt-1">
                                  <span class="mr-1" v-if="defaultProduct.product.user_ref">
                                    <v-chip label color="success" small>
                                      ID: {{ defaultProduct.product.user_ref }}
                                    </v-chip>
                                  </span>
                                  <span class="mr-1" v-if="defaultProduct.product.brand">
                                    <v-chip label color="default" small>
                                      BRAND: {{ defaultProduct.product.brand }}
                                    </v-chip>
                                  </span>
                                  <span v-if="defaultProduct.product.model">
                                    <v-chip label color="default" small>
                                      MPN : {{ defaultProduct.product.model }}
                                    </v-chip>
                                  </span>
                                </div>
                              </template>
                              <span>{{ defaultProduct.product.name }}</span>
                            </v-tooltip>
                          </div>
                          <div class="mt-1" v-if="defaultProduct.product.ean">
                            <v-chip label color="success">
                              <span>Ean: &nbsp;</span>
                              {{ defaultProduct.product.ean }}
                            </v-chip>
                          </div>
                        </td>
                        <td class="pa-2">
                          <span
                            v-html="
                              priceFormatter(
                                defaultProduct,
                                ProductToEdit.hight_price,
                                ProductToEdit.low_price,
                                true,
                              )
                            "
                          ></span>
                          <div
                            class="sm-text text--secondary"
                            v-if="
                              defaultProduct.product.offers && defaultProduct.product.offers.length
                            "
                          >
                            {{ defaultProduct.product.offers[0].changeDate }}
                          </div>
                        </td>
                        <td class="text-center pa-0">
                          <badge
                            class="sm-text"
                            small="true"
                            :text="defaultProduct.product.offers[0].availability"
                            :color="
                              defaultProduct.product.offers[0].availability == 'InStock'
                                ? 'success'
                                : 'error'
                            "
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
            <v-simple-table height="900px">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                    <th>Competitors</th>
                    <th>Product</th>
                    <th>Price</th>
                    <th>Stock</th>
                    <ht></ht>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(competitor, i) in competitors"
                    :key="competitor.website.id"
                    :ref="i === 0 ? 'firstProduct' : ''"
                    class="tabel-row"
                    :class="[competitor.product.not_found ? 'not_found_product' : '']"
                  >
                    <td>
                      <div v-bind:key="incrSelectionAction">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              large
                              color="grey"
                              @click.stop="deleteProduct(competitor.product)"
                            >
                              <v-icon v-if="!competitor.product.delete" color="success"
                                >mdi-minus-box</v-icon
                              >
                              <v-icon v-if="competitor.product.delete" color="error"
                                >mdi-plus-box</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>{{
                            competitor.product.delete ? 'Add match' : 'Delete match'
                          }}</span>
                        </v-tooltip>
                      </div>
                    </td>
                    <td class="pa-2 w-10">
                      <div class="w-100 d-flex justify-center" v-if="competitor.product.image">
                        <img class="product-image" :src="competitor.product.image" />
                      </div>
                    </td>
                    <td class="p2-0 w-15">
                      <div class="d-flex align-center">
                        <img
                          :src="getWebsiteFavicon(competitor.website.url)"
                          :alt="competitor.website.alias"
                          height="25"
                          class="mr-3 favicon-img"
                        />
                        <v-icon class="mr-2" size="medium" :color="competitor.website.config.color"
                          >$circle</v-icon
                        >
                        <span>
                          <a :href="competitor.product.url" target="_balank">
                            {{ competitor.website.alias }}
                          </a>
                        </span>
                      </div>
                    </td>
                    <td class="pa-2 more-info w-40" @click.stop="deleteProduct(competitor.product)">
                      <div>
                        <v-tooltip bottom>
                          <span>{{ competitor.product.name }}</span>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                              class="d-inline-block"
                              style="max-width: 100%"
                            >
                              {{ competitor.product.name }}
                            </span>
                            <div>
                              <v-chip
                                class="mr-1 mt-1"
                                label
                                v-for="(identifier, i) in competitor.product.meta.identifiers.slice(
                                  0,
                                  5,
                                )"
                                v-bind:key="i"
                                :color="
                                  identifier
                                    ? identifier.trim() == 'Image matching'
                                      ? 'warning'
                                      : 'gray'
                                    : 'gray'
                                "
                                >{{ identifier }}
                              </v-chip>
                            </div>
                            <div class="mt-2">
                              <v-chip
                                class="mr-1"
                                label
                                :color="
                                  competitor.product.meta.matching_confidence > 0.8
                                    ? 'success'
                                    : 'gray'
                                "
                                v-if="competitor.product.meta.matching_confidence"
                              >
                                <span>Matching confidence</span>:
                                <span class="ml-1"
                                  >{{ competitor.product.meta.matching_confidence * 100 }}%</span
                                >
                              </v-chip>
                              <v-chip
                                class="mr-1"
                                label
                                color="success"
                                v-if="competitor.product.meta.matched_by"
                              >
                                <span>Matched by</span>:
                                <span
                                  class="ml-1 d-inline-block text-truncate"
                                  style="display: inline-block; max-width: 100px"
                                  >{{ competitor.product.meta.matched_by }}</span
                                >
                              </v-chip>
                            </div>
                          </template>
                        </v-tooltip>
                      </div>
                    </td>
                    <td class="pa-2">
                      <span
                        v-html="
                          priceFormatter(
                            competitor,
                            ProductToEdit.hight_price,
                            ProductToEdit.low_price,
                            true,
                          )
                        "
                      ></span>
                      <div
                        class="sm-text tex--secondary"
                        v-if="competitor.product.offers && competitor.product.offers.length"
                      >
                        {{ competitor.product.offers[0].changeDate }}
                      </div>
                    </td>
                    <td class="text-center pa-0">
                      <badge
                        class="sm-text"
                        small="true"
                        :text="competitor.product.offers[0].availability"
                        :color="
                          competitor.product.offers[0].availability == 'InStock'
                            ? 'success'
                            : 'error'
                        "
                      />
                    </td>
                    <td>
                      <v-icon v-if="!competitor.product.delete" color="success">mdi-check</v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex sticky-bottom pb-0">
        <v-row justify="end">
          <v-col cols="12" class="text-lg-right pa-0 ma-0">
            <v-card flat>
              <v-card-text>
                <!-- <div v-if="confirm">
                  <v-alert type="warning" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
                    <div>
                      Are you sure you want to apply your changes ? Unselected products will be removed.
                    </div>
                  </v-alert>
                </div> -->
                <v-btn class="mr-auto" color="primary" plain @click="dialog = false">cancel</v-btn>
                <v-btn class="mr-auto" color="primary" plain @click="showEditModal(ProductToEdit)"
                  >edit</v-btn
                >
                <v-btn v-if="!confirm" @click="next" color="primary" class="ml-2">Save</v-btn>
                <v-btn v-if="!confirm" @click="nextProduct()" color="primary" class="ml-2"
                  >Skip</v-btn
                >
                <submitButton
                  v-if="confirm"
                  :submitFunction="() => confirmDeleteProducts(false)"
                  text="Save without validate"
                  color="primary"
                  class="ml-2"
                />
                <submitButton
                  v-if="confirm"
                  :submitFunction="() => confirmDeleteProducts(true)"
                  text="Save and validate"
                  color="primary"
                  class="ml-2"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  export default {
    name: 'validateMatchModal',
    componenets: {},
    props: ['productToEdit', 'show', 'component_name', 'matchesList', 'pagination', 'filter'],
    data() {
      return {
        ProductToEdit: {},
        searchParams: {},
        defaultProduct: {},
        dialog: false,
        loading: false,
        competitors: [],
        selectedProductsToDelete: false,
        incrSelectionAction: 0,
        productsToDelete: [],
        submit_loading: false,
        confirm: false,
        verified: false,
        matches: [],
        productPosition: 0,
        initialList: true,
      }
    },
    mounted() {},
    methods: {
      showEditModal(item) {
        this.$emit('show-edit-modal', item)
      },
      deleteProduct(item) {
        this.incrSelectionAction++
        item.delete = !item.delete
        this.selectedProductsToDelete = this.competitors.filter(
          (competitor) => competitor.product.delete,
        ).length
          ? true
          : false
      },
      next() {
        this.confirm = true
      },
      submitChanges() {
        const productsToDelete = this.competitors.filter((competitor) => competitor.product.delete)
        this.submit_loading = true
        const payload = {
          data: {
            match_id: this.ProductToEdit.match_id,
            match_ref: this.ProductToEdit.match_ref,
            verified: this.verified,
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
            to_delete: productsToDelete.map((product) => {
              return {
                id: product.product.id,
                ref: product.product.ref,
                url: product.product.url,
                website: product.website.id,
                website_url: product.website.url,
              }
            }),
            to_add: [],
            to_update: [],
            initialList: true,
          },
        }
        axios
          .post('/api/products/editMatch', payload)
          .catch(() => {
            this.$emit('show-notification', {
              msg: `An error occured while processing your request, please try again.`,
              type: 'error',
            })
          })
          .finally(() => {
            this.$emit('callback-function')
            this.submit_loading = false
            this.competitors = []
            this.productsToDelete = []
            //this.dialog = false
            this.nextProduct().then(() => {
              this.$emit('show-notification', {
                msg: `The change that you have made, has been well processed`,
                type: 'success',
              })
            })
          })
      },
      confirmDeleteProducts(verified = true) {
        this.confirm = false
        this.verified = verified
        this.submitChanges()
      },
      setMatchesList(data = []) {
        if (data.length) {
          data = data.filter((match) => !match.verified)
          this.ProductToEdit = data[this.productPosition]
          return data
        } else {
          if (this.productPosition == 0) {
            if (this.productToEdit.match_id !== this.matchesList[0].match_id) {
              this.productPosition = this.matchesList.findIndex(
                (match) => match.match_id === this.productToEdit.match_id,
              )
            }
          }

          this.ProductToEdit = (this.matches.length ? this.matches : this.matchesList)[
            this.productPosition
          ]

          return this.matches.length ? this.matches : this.matchesList
        }
      },
      async nextProduct() {
        if (this.productPosition < this.matches.length - 1) {
          this.productPosition++
          this.initialisation(this.productPosition, Object.assign({}, this.searchParams))
        } else {
          //if reached the end of the list, let's use searchParams to get the next list from server
          this.productPosition = 0
          this.searchParams.page++
          this.searchParams.from =
            this.searchParams.page * this.searchParams.rowsPerPage - this.searchParams.rowsPerPage
          this.searchParams.size = this.searchParams.rowsPerPage
          const data = await this.getData()
          this.initialisation(this.productPosition, Object.assign({}, this.searchParams), data)
        }
      },
      previousProduct() {
        if (this.productPosition > 0) this.productPosition--
        else this.productPosition = this.matches.length - 1
        this.initialisation(this.productPosition, this.filter)
      },
      initialisation(productPosition = 0, filter = {}, data = []) {
        this.competitors = []
        this.matches = Object.assign([], this.setMatchesList(data))

        Object.assign(this.searchParams, filter)
        Object.keys(this.ProductToEdit).forEach((key) => {
          if (this.ProductToEdit[key].product) {
            this.ProductToEdit[key].product.match_id = this.ProductToEdit.match_id
            this.ProductToEdit[key].product.match_ref = this.ProductToEdit.match_ref
            this.ProductToEdit[key].product.delete = !this.ProductToEdit[key].product.validated

            if (this.ProductToEdit[key].website.alias == localStorage.getItem('website_alias'))
              this.competitors.unshift(this.ProductToEdit[key])
            else this.competitors.push(this.ProductToEdit[key])
          }
        })
        if (this.competitors.length) this.defaultProduct = this.competitors[0]
        if (this.defaultProduct.product.id)
          this.competitors = this.competitors
            .filter((competitor) => competitor.product.id !== this.defaultProduct.product.id)
            .sort((a, b) => {
              //sort by confidence then by product.delete
              return (
                b.product.delete - a.product.delete &&
                a.product.meta.matching_confidence - b.product.meta.matching_confidence
              )
            })
      },
      async getData() {
        this.loading = true
        return axios
          .get('/api/products/list', {
            params: {
              ...this.searchParams,
            },
          })
          .then((res) => {
            return res.data.data
          })
          .catch((err) => {
            console.error('comparePricesTable - getProducts: ', err)
            return []
          })
          .finally(() => {
            this.loading = false
          })
      },
      async modalOpened() {
        this.matches = []
        this.productPosition = 0
        this.initialisation(this.productPosition, this.filter)
        this.confirm = false
      },
    },
    watch: {
      dialog: {
        handler(value) {
          if (!value) {
            this.competitors = []
            this.defaultProduct = {}
          }
        },
      },
      show: {
        handler() {
          this.dialog = true
          this.modalOpened()
          this.to_add = []
          this.to_update = []
        },
      },
    },
  }
</script>
<style scoped>
  .tabel-row {
    height: 120px;
  }
  .product-image {
    object-fit: contain;
    max-height: 120px;
    max-width: 100px;
    height: 100px;
    background-size: cover;
  }
  .more-info {
    cursor: pointer;
  }
  .sticky-bottom {
    position: sticky;
    bottom: 10px;
    z-index: 1000;
  }
  .sticky-top {
    position: sticky;
    top: 10px;
    z-index: 1000;
  }
  .low-confidence {
    opacity: 0.6;
  }
  .low-confidence:hover {
    opacity: 1;
  }
</style>
