<template>
  <v-dialog v-model="dialog" persistent max-width="600">
    <v-card>
      <v-card-title><span class="md-text">Delete confirmation</span></v-card-title>
      <v-card-text>
        <v-alert type="warning" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
          Are you sure you want to permanently delete this match ?
        </v-alert>
        <div class="d-flex justify-center align-center">
          <div class="mr-4">
            <img class="product-image-delete" :src="ProductToDelete.product_image" />
          </div>
          <div>
            <h4 class="ma-0 text-truncate">
              {{
                ProductToDelete.product_name
                  ? ProductToDelete.product_name.length > 35
                    ? ProductToDelete.product_name.substring(0, 35).concat('...')
                    : ProductToDelete.product_name
                  : ''
              }}
            </h4>
            <span v-if="ProductToDelete.product_brand" class="font-italic font-weight-bold">
              {{ ProductToDelete.product_brand }}
            </span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <submitButton
          :submitFunction="deleteProduct"
          :submit_loading="submit_loading"
          text="Yes, delete it!"
          color="primary"
          :just_text="true"
        />
        <v-btn color="error" @click="dialog = false">cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from 'axios'
  export default {
    name: 'deleteMatchModal',
    props: ['ProductToDelete', 'show', 'component_name'],
    data() {
      return {
        dialog: false,
        submit_loading: false,
      }
    },
    methods: {
      async wait(time = 500) {
        return await new Promise((resolve) => setTimeout(resolve, time))
      },
      async deleteProduct() {
        //delete selected match
        this.submit_loading = true
        await axios.get(`/api/products/delete`, {
          params: {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
            ref: this.ProductToDelete.match_ref,
            match: this.ProductToDelete.match_id,
          },
        })
        await this.wait()
        this.submit_loading = false
        this.dialog = false
        this.$emit('callback-function')
      },
    },
    watch: {
      show: {
        handler() {
          this.dialog = this.dialog ? false : true
        },
      },
    },
  }
</script>
<style scoped>
  .product-image-delete {
    height: 100px;
  }
  .brand-text {
    font-size: 1rem;
    font-weight: 600;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
  .delete-msg {
    font-size: 1.4em;
  }
</style>
