var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items w-100",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"competitors-section v-item-group theme--light v-slide-group v-slide-group--has-affixes v-slide-group--is-overflowing"},[_c('div',{staticClass:"d-flex align-center mr-2"},[_c('v-btn',{attrs:{"icon":"","large":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.scrollTo(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1)],1),_c('div',{staticClass:"v-slide-group__wrapper"},[_c('div',{staticClass:"v-slide-group__content",attrs:{"id":_vm.product_match.match_id}},_vm._l((_vm.competitors),function(competitor){return _c('v-tooltip',{key:competitor.website.id,attrs:{"top":""},scopedSlots:_vm._u([(_vm.includeWebsite(competitor.website.alias))?{key:"activator",fn:function({ on, attrs }){return [_c('v-card',_vm._g(_vm._b({staticClass:"item ma-2 pa-2",attrs:{"flat":""},on:{"click":function($event){$event.stopPropagation();}}},'v-card',attrs,false),on),[_c('a',{staticStyle:{"color":"#42b983"},attrs:{"href":competitor.product.url,"target":"_blank"}},[_c('div',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-4 favicon-img",attrs:{"src":_vm.getWebsiteFavicon(competitor.website.url),"alt":competitor.website.alias,"height":"25"}}),_c('div',{staticClass:"text-truncate",domProps:{"innerHTML":_vm._s(
                      _vm.priceFormatter(
                        competitor,
                        _vm.product_match.hight_price,
                        _vm.product_match.low_price,
                      )
                    )}})]),_c('div',{staticClass:"d-flex"},[_c('span',[_c('v-chip',{staticClass:"mt-2",attrs:{"small":"","color":competitor.product.offers[0].availability == 'InStock'
                          ? 'success'
                          : 'error',"label":"","outlined":""}},[_vm._v(" "+_vm._s(competitor.product.offers[0].availability)+" ")])],1),_c('span',{staticClass:"pa-2"},[_c('a',{attrs:{"href":`https://www.google.com${_vm.product_match.googleShoppingUrl}?gl=${_vm.user_country}`,"target":"_blank"}},[(competitor.product.isGoogleShoppingProduct)?_c('img',{staticClass:"mr-2 favicon-img",attrs:{"src":require(`../../assets/google_shopping_favicon.png`),"width":"20","height":"20"}}):_vm._e()])])])])])]}}:null],null,true)},[_c('span',{staticClass:"d-flex align-center"},[_c('img',{staticClass:"mr-4 favicon-img",attrs:{"src":_vm.getWebsiteFavicon(competitor.website.url),"alt":competitor.website.alias,"height":"25"}}),_vm._v(_vm._s(competitor.website.alias))])])}),1)]),_c('div',{staticClass:"d-flex align-center ml-2"},[_c('v-btn',{attrs:{"icon":"","large":"","color":"grey"},on:{"click":function($event){$event.stopPropagation();return _vm.scrollTo(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }