var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"eager":"","max-width":"40%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"md-text"},[_vm._v("Notification - ("+_vm._s(_vm.item.name)+")")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[(_vm.loading)?_c('v-col',{staticClass:"ma-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('loadingProgress',{attrs:{"loading":_vm.loading,"text":"Loading notification..."}})],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('v-col',{staticClass:"text-center pt-6",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold error--text mt-4 lg-text"},[_vm._v(" "+_vm._s(_vm.notif_header)+" ")])]),_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","md":"12"},domProps:{"innerHTML":_vm._s(_vm.competitors_statistics_html)}}),(_vm.is_full_reporte)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"error"},on:{"click":_vm.downloadData}},[_vm._v("Download Data"),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("$file_download")])],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h3',{staticClass:"mt-0 mb-2"},[_vm._v("Overview")]),_c('v-row',{staticStyle:{"background-color":"#f9f9f9"}},_vm._l((Object.keys(
                  _vm.competitors_compare
                )),function(competitor,index){return _c('v-col',{key:index,staticClass:"mt-2",attrs:{"cols":"12","md":"12"}},[_c('div',[_c('h3',{staticClass:"mt-0 secondary--text font-weight-bold"},[_vm._v(" Me vs "+_vm._s(_vm.aliasTitle(competitor))+" ")]),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("i am higher in "),_c('strong',[_vm._v(_vm._s(_vm.percentageCalc( _vm.competitors_compare[competitor].higher, _vm.competitors_compare[competitor].total ))+"%")]),_vm._v(" products")])]),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"16px","width":"95%","border":"2px solid #e1e4e5","background-color":"#fff"}},[_c('div',{staticClass:"pt-2 pb-2 text-left",staticStyle:{"color":"#e44e69","background":"#ffadbc"},style:('width: ' +
                              _vm.percentageCalc(
                                _vm.competitors_compare[competitor].higher,
                                _vm.competitors_compare[competitor].total
                              ) +
                              '%;')},[_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.competitors_compare[competitor].higher))])])])]),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("i am cheaper in "),_c('strong',[_vm._v(_vm._s(_vm.percentageCalc( _vm.competitors_compare[competitor].cheaper, _vm.competitors_compare[competitor].total ))+"%")]),_vm._v(" products")])]),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"16px","width":"95%","border":"2px solid #e1e4e5","background-color":"#fff"}},[_c('div',{staticClass:"pt-2 pb-2 text-left",staticStyle:{"color":"#50c021","background":"#d7f6ca"},style:('width: ' +
                              _vm.percentageCalc(
                                _vm.competitors_compare[competitor].cheaper,
                                _vm.competitors_compare[competitor].total
                              ) +
                              '%;')},[_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.competitors_compare[competitor].cheaper))])])])]),_c('v-col',{staticClass:"pt-0 pb-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v("i am equal in "),_c('strong',[_vm._v(_vm._s(_vm.percentageCalc( _vm.competitors_compare[competitor].equals, _vm.competitors_compare[competitor].total ))+"%")]),_vm._v(" products")])]),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"text-center",staticStyle:{"font-size":"16px","width":"95%","border":"2px solid #e1e4e5","background-color":"#fff"}},[_c('div',{staticClass:"pt-2 pb-2 text-left",staticStyle:{"color":"#e0a500","background":"#ffe9ad"},style:('width: ' +
                              _vm.percentageCalc(
                                _vm.competitors_compare[competitor].equals,
                                _vm.competitors_compare[competitor].total
                              ) +
                              '%;')},[_c('strong',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.competitors_compare[competitor].equals))])])])])],1)],1)])}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }