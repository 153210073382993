<template>
  <div class="hello">
    <h1></h1>
  </div>
</template>

<script>
export default {
  name: 'About',
  props: {
    msg: String
  },
  methods:{
      init : function(){
          let data = [1,2]
          data.map(v => {
              console.log(v)
          })

          let pr = new Promise((resolve) => {
              resolve('value')
          })

          pr.then(r => {
              console.log(r)
          })
      }
  },
  mounted(){
      this.init()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
