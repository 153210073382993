var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"matches-table",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"loading":_vm.data_loading,"items-per-page":_vm.pagination.rowsPerPage,"options":_vm.pagination,"server-items-length":_vm.data_total,"no-data-text":"No data available","footer-props":{
    itemsPerPageOptions: [5, 10, 15],
    showFirstLastPage: true,
  }},on:{"update:options":function($event){_vm.pagination=$event},"update:items-per-page":_vm.setItemPerPage},scopedSlots:_vm._u([{key:"item.image",fn:function({ item }){return [_c('img',{staticClass:"product-image",attrs:{"src":item.product_image}})]}},{key:"item.price",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.priceFormatter(item[_vm.user_website.alias], item.hight_price, item.low_price))}}),_c('div',[(item[_vm.user_website.alias] != undefined)?_c('badge',{attrs:{"text":item[_vm.user_website.alias].product.offers[0].availability,"color":item[_vm.user_website.alias].product.offers[0].availability == 'InStock'
            ? 'success'
            : 'error',"small":"true"}}):_c('badge',{attrs:{"text":"Not Found","color":"grey","small":"true"}})],1)]}},{key:"item.product_name",fn:function({ item }){return [_c('div',[_c('div',{staticClass:"ma-0 text-truncate md-text secondary--text"},[_vm._v(" "+_vm._s(item['product_name'])+" ")]),(item.product_brand)?_c('div',{staticClass:"font-weight-medium text-truncate"},[_vm._v(" "+_vm._s(item['product_brand'].toUpperCase())+" ")]):_vm._e(),(item.match_user_ref)?_c('div',{staticClass:"text-truncate"},[_vm._v(" Sku : "+_vm._s(item['match_user_ref'])+" ")]):_vm._e(),(
          item[_vm.user_website.alias] != undefined &&
          item[_vm.user_website.alias].product.offers[0].changeDate.length
        )?_c('div',[_vm._v(" Update date : "+_vm._s(item[_vm.user_website.alias].product.offers[0].changeDate)+" ")]):_vm._e()])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }