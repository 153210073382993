import offersHandler from './offersHandler'
export default function simplePriceFormatter(
  productItem,
  hightPrice,
  lowPrice,
  showPreviousPrice = false,
) {
  let availability = ''
  if (productItem && productItem.product && productItem.product.offers.length) {
    availability = productItem.product.offers[0].availability
  }
  const { offer_value, offer_html, offer_previous_value, offer_previous_html } =
    offersHandler(productItem)

  if (productItem != undefined && productItem && productItem.product && offer_value) {
    let offerStatus = productItem.product.offers.length ? productItem.product.offers[0].status : ''
    let offerUpdateDate = productItem.product.offers.length
      ? productItem.product.offers[0].changeDate
      : ''
    let offerUrl = productItem.product.url
    let offerWebsiteUrl = productItem.website.url

    let offerStatusStyle = 'text--secondary'

    offerStatus = ''

    if (offerStatus == 'down') {
      //offerStatus = '&darr;'
      offerStatusStyle = 'success--text'
    }
    if (offerStatus == 'up') {
      //offerStatus = '&uarr;'
      offerStatusStyle = 'error--text'
    }

    let price_style = 'text--secondary'
    if (offer_value == lowPrice) price_style = 'success--text'
    if (offer_value == hightPrice) price_style = 'error--text'
    if (offer_value == lowPrice && offer_value == hightPrice) {
      price_style = 'text--secondary'
      offerStatus = ''
    }

    offerStatus = offerStatus.length
      ? `<span class="${offerStatusStyle} pl-2 text-small sm-text">${offerStatus}</span>`
      : ''
    return showPreviousPrice
      ? `<a href=${offerUrl} target="_blank">
            <span class="${price_style} font-weight-bold mb-0">${offer_html}${offerStatus}</span><br>
            <span class="text--secondary sm-text">${offer_previous_html}</span>
          </a>
          `
      : `<a href=${offerUrl} target="_blank">
            <span class="${price_style} font-weight-bold">${offer_html}${offerStatus}</span><br>
          </a>`
  } else {
    //return `<span class="font-weight-bold text--secondary">Price not found</span>`
    return ``
  }
}
