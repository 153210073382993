<template>
  <v-card flat>
    <v-card-text class="pa-8 pb-0">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="ma-0 pa-0">
            <fileUpload @input="validateFile" :opened="opened" ref="fileUpload" />
          </v-col>
          <v-progress-linear
            v-if="reading_file"
            color="success"
            style="pointer-events: none"
            indeterminate
            striped
          ></v-progress-linear>
          <div v-if="reading_file">Loading...</div>
          <v-col cols="6" class="ma-0 pa-0" v-if="!reading_file && websites_items.length > 0">
            <div>
              <v-row>
                <v-col cols="6">
                  <v-combobox
                    filled
                    v-model="account_website_select"
                    :items="websites_items"
                    label="Select your website"
                    dense
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <div class="pt-2 pb-1 d-flex align-center">
                        <img
                          :src="'https://8181.integ.crawlo.com/favicon?size=xs&website=' + item"
                          @error="replaceByDefault"
                          width="30"
                          height="30"
                          class="mr-2 favicon-img"
                          ref="myImg"
                        />
                        {{ item }}
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <img
                        :src="'https://8181.integ.crawlo.com/favicon?size=xs&website=' + item"
                        @error="replaceByDefault"
                        width="30"
                        height="30"
                        class="mr-2 favicon-img"
                        ref="myImg"
                      />
                      {{ item }}
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6">
                  <div class="d-flex ml-auto align-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span class="mr-2" v-bind="attrs" v-on="on"> Enable auto discovery </span>
                      </template>
                      <span>
                        By activating auto-discovery, our system will search for possible matched
                        products automatically.
                      </span>
                    </v-tooltip>
                    <v-switch
                      dense
                      hide-details="true"
                      class="mt-0"
                      color="error"
                      v-model="autoDiscovery"
                    ></v-switch>
                  </div>
                  <div class="mt-2" v-if="autoDiscovery">
                    <v-menu open-on-click offset-y bottom :close-on-content-click="false">
                      <template v-slot:activator="{ on: menu }" class="pa-4">
                        <v-btn text outlined color="primary" light v-on="{ ...menu }">
                          <v-icon left dark>$add</v-icon>
                          <span> Search keywords </span>
                          <span v-if="searchKeywords.length"> | {{ searchKeywords.length }}</span>
                        </v-btn>
                      </template>
                      <v-card class="pa-0" flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field
                                v-on:keyup.enter="
                                  !searchKeywords.includes(newKeyword) &&
                                    searchKeywords.push(newKeyword) &&
                                    (newKeyword = '')
                                "
                                dense
                                v-model="newKeyword"
                                small
                                outlined
                                hide-details
                                label="Add keyword"
                                color="primary"
                                clearable
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="!searchKeywords.includes(newKeyword) && newKeyword.length"
                            :class="{
                              'text-danger': searchKeywords.includes(newKeyword),
                            }"
                            style="cursor: pointer"
                            @click="
                              !searchKeywords.includes(newKeyword)
                                ? searchKeywords.push(newKeyword) && (newKeyword = '')
                                : null
                            "
                          >
                            <v-col cols="1">
                              <v-icon small>mdi-plus-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="10">
                              {{ newKeyword }}
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="pa-0">
                              <v-list dense class="pa-2">
                                <v-list-item
                                  class="pa-1"
                                  v-for="(keyword, index) in searchKeywords"
                                  v-bind:key="keyword"
                                >
                                  <v-list-item-content>
                                    <v-row>
                                      <v-col cols="9" color="grey">{{ keyword }}</v-col>
                                      <v-col
                                        cols="2"
                                        @click="removeKeyword(index)"
                                        style="cursor: pointer"
                                        ><v-icon small>mdi-close-circle-outline</v-icon></v-col
                                      >
                                    </v-row>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="6" class="ma-0 pa-0"> </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            class="ma-0 pa-0 pt-4"
            v-if="!reading_file && websites_items.length > 0"
          >
            <div v-if="websites.length > 0">
              <div>
                <h3 class="ma-0">We have found these websites in your file</h3>
                <p class="text--secondary ma-0">Uncheck to exclude website</p>
              </div>
              <div>
                <websitesTablePreview :websites="websites" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="closeModal">cancel</v-btn>
      <submitButton
        :submitFunction="sendImport"
        :submit_loading="submit_loading"
        color="secondary"
        text="Looks good, next"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
  import fileUpload from '../common/fileUpload.vue'
  import websitesTablePreview from './websitesTablePreview.vue'
  import axios from 'axios'

  import * as URL from 'url'
  import { extname } from 'path'
  import { parse } from 'querystring'
  import XLSX from 'xlsx/xlsx'
  const isUrl = require('is-url')
  export default {
    name: 'multipleProductsForm',
    data() {
      return {
        autoDiscovery: false,
        searchKeywords: [],
        newKeyword: '',
        googleShopping: false,
        websites_items: [],
        account_website_select: '',
        file: '',
        valid_msg: '',
        error_msg: '',
        overwrite_import: 1,
        websites: [],
        reading_file: false,
        submit_loading: false,
        is_alias: false,
        is_refs: false,
      }
    },
    components: {
      websitesTablePreview,
      fileUpload,
    },
    props: ['opened'],
    mounted() {
      this.clearWebsites()
    },
    methods: {
      removeKeyword(index) {
        this.searchKeywords.splice(index, 1)
      },
      resetState() {
        this.websites = []
        this.websites_items = []
        this.account_website_select = ''
        this.file = ''
        this.valid_msg = ''
        this.error_msg = ''
        this.overwrite_import = 1
        this.reading_file = false
        this.submit_loading = false
        this.is_alias = false
        this.is_refs = false
      },
      closeModal() {
        this.resetState()
        this.$emit('close-model')
      },
      downloadSampleFile() {
        this.$emit('donwload-sample-file')
      },
      toggle(btn) {
        if (btn == 'refs') this.is_refs = !this.is_refs
        else if (btn == 'alias') this.is_alias = !this.is_alias
      },
      validateFile(files) {
        try {
          this.reading_file = true
          this.file = {}
          this.file = files[0]
          let ext = extname(this.file.name)
          if (ext == '.xlsx' || ext == '.csv') {
            this.valid_msg = 'File extension valid'
            this.error_msg = ''
            this.readExcelFile()
          } else {
            this.valid_msg = ''
            this.error_msg = 'File extension not valid, please upload a .csv or .xlsx file'
            this.reading_file = false
          }
        } catch (err) {
          this.error_msg = 'An error occured while reading the file, please try again'
          this.reading_file = false
        }
      },
      clearWebsites() {
        this.websites = []
        this.websites_items = []
      },
      rewadCsvFile() {},
      readExcelFile() {
        let f = this.file
        let reader = new FileReader()
        let headers = []
        reader.onloadend = () => {
          this.reading_file = false
        }
        reader.onload = (e) => {
          let data = e.target.result
          let o = '',
            l = 0,
            w = 10240
          for (; l < data.byteLength / w; ++l)
            o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)))
          let fixedData = o + String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)))

          let workbook = XLSX.read(btoa(fixedData), { type: 'base64' }),
            worksheet = workbook.Sheets[workbook.SheetNames[0]]

          let rows = XLSX.utils.sheet_to_json(worksheet)
          let columns_count = 0
          for (let i = 0; i < rows.length; i++) {
            let row_length = Object.keys(rows[i]).length
            if (columns_count < row_length) columns_count = row_length
          }
          let first_row = rows[0]
          let first_row_keys = Object.keys(first_row)
          let hasSku = true,
            hasAliasRow = false
          if (!isUrl(first_row_keys[0])) {
            hasAliasRow = true
            if (isUrl(first_row[first_row_keys[0]])) hasSku = false
          } else hasSku = false

          let begin = hasSku ? 1 : 0

          for (let i = begin; i < columns_count; i++) {
            headers.push({ alias: '', checked: true, url: '' })
          }

          let index = 0
          for (let i = 0; i < rows.length; i++) {
            let empty = false
            let row = rows[i]
            let keys = Object.keys(row)
            for (let c = begin; c < columns_count; c++) {
              index = c - begin
              for (let k = 0; k < headers.length; k++) {
                if (headers[k].alias.length == 0) {
                  empty = true
                  break
                }
              }
              if (empty) {
                if (row[keys[c]] != undefined && isUrl(row[keys[c]])) {
                  let url_parts = URL.parse(row[keys[c]], false, true)
                  let url = url_parts.protocol + '//' + url_parts.hostname
                  //let alias = url_parts.hostname.match(
                  //  /[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
                  //)[0]
                  let alias = url_parts.hostname.replace('www.', '')
                  let alias_exists = false
                  for (let k = 0; k < headers.length; k++) {
                    if (headers[k].alias == alias) {
                      alias_exists = true
                      break
                    }
                  }
                  if (!alias_exists) {
                    headers[index].url = url
                    headers[index].alias = alias
                  }
                }
              }
            }
            if (!empty) break
          }
          this.websites_items = headers.map((elt) => elt.alias)
          this.account_website_select = this.websites_items[0]
          this.websites = headers
        }
        reader.readAsArrayBuffer(f)
      },
      sendImport() {
        this.submit_loading = true
        let excludeWebsites = this.websites.filter((element) => {
          if (!element.checked) return element.alias
        })
        excludeWebsites = excludeWebsites.map((element) => element.alias)
        this.error_msg = ''
        let account_website = this.websites.find((elt) => {
          return elt.alias == this.account_website_select
        })

        let formData = new FormData()
        formData.append('file', this.file)
        axios
          .post('/api/importation', formData, {
            params: {
              account_id: localStorage.getItem('account_id'),
              user_id: localStorage.getItem('user_id'),
              type: 1,
              excludeWebsites,
              account_website,
              autoDiscovery: this.autoDiscovery,
              searchKeywords: this.searchKeywords,
            },
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((res) => {
            this.closeModal()

            if (res.data.status) {
              this.$emit('show-notification', {
                msg: 'The importation request has been successfully uploaded, it will be processed soon',
                type: 'success',
              })
            } else {
              this.$emit('show-notification', {
                msg: res.data.msg,
                type: 'error',
              })
              this.error_msg = res.data.msg
            }
          })
          .catch(function () {
            this.$emit('show-notification', {
              msg: 'An error occured while sending the importation request, please try again',
              type: 'error',
            })
          })
          .finally(() => {
            this.submit_loading = false
          })
      },
    },
    watch: {
      opened: {
        deep: true,
        handler() {
          this.valid_msg = ''
          this.error_msg = ''
          this.websites = []
          this.websites_items = []
        },
      },
    },
  }
</script>
<style scoped></style>
