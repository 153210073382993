<template>
  <div>
    <deleteModal
      :show="delete_dialog"
      title="Delete alert"
      :text="`Are you sure you want to delete this alert ( ${to_delete.name} ) ?`"
      :callback="deleteAlert"
    />
    <editAlertModal
      :show="edit_dialog"
      :item="to_edit"
      :component_name="component_name"
      @show-notification="showNotification"
    />
    <newAlertModal
      :show="show_new_alert"
      :component_name="component_name"
      @show-notification="showNotification"
      @show-added-message="showAddedMessage"
    />
    <v-card outlined>
      <v-card-title>
        <span class="md-text">Alerts table</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="ml-auto"
              outlined
              light
              small
              color="primary"
              @click="showNewAlert"
              >New alert <v-icon right dark>$add</v-icon>
            </v-btn>
          </template>
          <span>create a new alert</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <alertsTable
            :changed="changed"
            @delete-alert="showDeleteConfirm"
            @edit-alert="showEditModal"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <snackbar :show="snackbar" :color="snackbar_color" :msg="snackbar_msg" :icon="snackbar_icon" />
    <v-dialog v-model="disable_all_dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="md-text font-weight-bold">Alerts disabling</v-card-title>
        <v-card-text class="pb-0">
          <v-alert type="info" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
            All alerts has been disabled
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="disable_all_dialog = false">cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <addedMessage :show="added_message" :days="days" />
  </div>
</template>

<script>
  import axios from 'axios'
  import alertsTable from './alertsTable.vue'
  import newAlertModal from './newAlertModal.vue'
  import editAlertModal from './editAlertModal.vue'
  import addedMessage from './addedMessage.vue'
  export default {
    name: 'Alerts',
    components: {
      alertsTable,
      newAlertModal,
      editAlertModal,
      addedMessage,
    },
    data() {
      return {
        snackbar: false,
        snackbar_msg: '',
        snackbar_color: '',
        snackbar_icon: '',
        changed: false,
        show_new_alert: false,
        edit_dialog: false,
        delete_dialog: false,
        to_edit: '',
        to_delete: '',
        disable_all_dialog: false,
        added_message: false,
        days: [],
        component_name: this.$router.history.current.fullPath,
      }
    },
    methods: {
      showNewAlert() {
        this.show_new_alert = !this.show_new_alert
      },
      showDeleteConfirm(payload) {
        this.to_delete = payload
        this.delete_dialog = !this.delete_dialog
      },
      showEditModal(payload) {
        this.to_edit = payload
        this.edit_dialog = !this.edit_dialog
      },
      async deleteAlert() {
        await axios
          .post(`/api/alerts/delete`, {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            id: this.to_delete.id,
            component: this.component_name,
          })
          .then((res) => {
            if (res.data.status) {
              this.showNotification({
                msg: `the alert named ( ${this.to_delete.name} ) has been successfully deleted from your alert list`,
                type: 'success',
                icon: 'delete',
              })
            }
          })
        this.delete_dialog = !this.delete_dialog
      },
      showNotification(payload) {
        this.snackbar_color = payload.type
        this.snackbar_msg = payload.msg
        this.snackbar_icon = payload.icon
        this.snackbar = !this.snackbar
        this.changed = !this.changed
      },
      showAddedMessage(payload) {
        let txt = ''
        payload.days.map((elt, index) => {
          if (index < payload.days.length - 1) txt += elt + ' - '
          else txt += elt
        })
        this.days = txt
        this.added_message = !this.added_message
      },
    },
    async mounted() {
      let disableall = this.$route.query.disableall || false
      let id = this.$route.query.id || false
      if (disableall) {
        await axios
          .post(`/api/alerts/disableall`, {
            account: localStorage.getItem('account_id'),
            user: localStorage.getItem('user_id'),
            component: this.component_name,
          })
          .then((res) => {
            if (res.data.status) {
              this.disable_all_dialog = !this.disable_all_dialog
            }
          })
      }
      if (id.length) {
        await axios
          .get(`/api/alerts/getalert`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              id,
            },
          })
          .then((res) => {
            if (res.data.status) {
              this.to_edit = res.data.item
              this.edit_dialog = !this.edit_dialog
              // this.disable_all_dialog = !this.disable_all_dialog;
            }
          })

        // this.to_edit = payload;
      }
    },
  }
</script>
<style scoped></style>
