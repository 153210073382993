<template>
  <v-row>
    <v-col cols="4">
      <div class="box" @click="choosePlan(2)">
        <div class="d-flex aling-center justify-center" :class="plan1">
          <div>
            <span class="xl-text error--text"> MINI </span>
            <ul class="features">
              <li class="feature">Unlimited competitors!</li>
              <li class="feature">Up to 100 products</li>
              <li class="feature">Unlimited users</li>
              <li class="feature">Price History</li>
              <li class="feature">Price distribution</li>
              <li class="feature">Stock Availability Monitoring</li>
              <li class="feature">Advanced Filtering</li>
              <li class="feature">Batch Import</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <span class="lg-text font-weight-bold"> 49€</span>
        <span class="md-text"> / month </span>
      </div>
    </v-col>
    <v-col cols="4">
      <div class="box" @click="choosePlan(1)">
        <div class="d-flex aling-center justify-center" :class="plan2">
          <div>
            <span class="xl-text error--text"> PROFESSIONAL </span>
            <ul class="features">
              <li class="feature">Unlimited competitors!</li>
              <li class="feature">Up to 1000 products</li>
              <li class="feature">Unlimited users</li>
              <li class="feature">Price History</li>
              <li class="feature">Price distribution</li>
              <li class="feature">Stock Availability Monitoring</li>
              <li class="feature">Advanced Filtering</li>
              <li class="feature">Batch Import</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <span class="lg-text font-weight-bold"> 149€</span>
        <span class="md-text"> / month </span>
      </div>
    </v-col>
    <v-col cols="4">
      <div class="box" @click="choosePlan(0)">
        <div class="d-flex aling-center justify-center" :class="plan3">
          <div>
            <span class="xl-text error--text"> PREMIUM </span>
            <ul class="features">
              <li class="feature">Unlimited competitors!</li>
              <li class="feature">Up to 10000 products</li>
              <li class="feature">Unlimited users</li>
              <li class="feature">Price History</li>
              <li class="feature">Price distribution</li>
              <li class="feature">Stock Availability Monitoring</li>
              <li class="feature">Advanced Filtering</li>
              <li class="feature">Batch Import</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <span class="lg-text font-weight-bold"> 249€</span>
        <span class="md-text"> / month </span>
      </div>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "plans",
  props: ["selected_plan", "show"],
  data() {
    return {
      plan1: "plan ",
      plan2: "plan choosen",
      plan3: "plan ",
      choosenPlan: 1,
    };
  },
  methods: {
    choosePlan(num) {
      this.choosenPlan = num;
      switch (num) {
        case 2:
          this.plan1 = "plan choosen";
          this.plan2 = this.plan3 = "plan";
          break;
        case 1:
          this.plan2 = "plan choosen";
          this.plan1 = this.plan3 = "plan";
          break;
        case 0:
          this.plan3 = "plan choosen";
          this.plan2 = this.plan1 = "plan";
          break;
      }
      this.$emit("choosen-plan", this.choosenPlan);
    },
  },
  watch: {
    show: {
      handler() {
        this.choosePlan(this.selected_plan);
      },
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  cursor: pointer;
}
.plan {
  -webkit-box-flex: 5;
  -ms-flex-positive: 5;
  flex-grow: 5;
  padding: 25px 15px;
  text-align: center;
  margin-right: 30px;
  margin: 30px;
  border-radius: 10px;
  border-width: 1px;
  border: 1px solid #f0f0f0;
  background-color: #fbfbfc;
  transition: all 200ms;
}

.plan.choosen {
  border: 3px solid #5bc0db;
  margin: 27px;
}

.feature {
  line-height: 30px;
  display: list-item;
}
.features {
  margin-top: 20px;
  margin-bottom: 32px;
  padding: 0;
  list-style-type: none;
  color: #2b2e30;
}
</style>
