import { render, staticRenderFns } from "./validateMatchModal.vue?vue&type=template&id=39aa2ac7&scoped=true"
import script from "./validateMatchModal.vue?vue&type=script&lang=js"
export * from "./validateMatchModal.vue?vue&type=script&lang=js"
import style0 from "./validateMatchModal.vue?vue&type=style&index=0&id=39aa2ac7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39aa2ac7",
  null
  
)

export default component.exports