<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card width="30%">
          <v-col
            cols="12"
            style="background-color: #f96654"
            class="d-flex align-center"
          >
            <img
              :src="require('../../assets/Dynamic_Pricing.svg')"
              width="220"
            />
          </v-col>
          <v-card-text class="pb-0">
            <v-form
              ref="email_insert_form"
              id="email_insert_form"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" class="pa-8 pt-4 pb-0">
                  <v-row>
                    <v-col cols="12">
                      <h2>Enter your email</h2>
                      <v-row>
                        <v-col cols="12" class="pt-0 pb-0">
                          <v-text-field
                            prepend-inner-icon="$email"
                            ref="email_crawlo"
                            v-model="resetEmail"
                            label="Email"
                            :error="isWrong"
                            :rules="[rules.required, rules.emailValidation]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0 d-flex">
                          <v-btn class="ma-1" text @click="gotoLogin()">
                            <v-icon left>$back</v-icon> Sign In
                          </v-btn>
                          <v-btn light color="error" class="ml-auto" @click="submitEmail">
                            Send !
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-0 d-flex justify-center">
        <v-alert
          :value="isWrong"
          color="error"
          dark
          border="right"
          icon="mdi-alert"
          transition="scale-transition"
        >
          No account found with that email address
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "EmailInsert",

  data: () => {
    return {
      resetEmail: "",
      isWrong: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        emailValidation: (value) =>
          /.+@.+\..+/.test(value) || "Email not valid.",
      },
    };
  },
  methods: {
    gotoLogin() {
      this.$router.push("/login");
    },
    async submitEmail() {
      if (this.$refs.email_insert_form.validate()) {
        let res = await this.$store.dispatch("RESET_REQUEST", {
          resetEmail: this.resetEmail,
        });
        if (res.data.status === "error") {
          this.isWrong = true;
        } else {
          this.isWrong = false;
          this.$router.push({
            path: "/login",
            query: { from_insert_email: true },
          });
        }
      }
    },
  },
  mounted() {},
};
</script>
