<template>
  <div>
    <validateMatchModal
      :productToEdit="selectedProductToAction"
      :matchesList="matchesList"
      :pagination="pagination"
      :filter="searchParams"
      :show="show_validate_modal"
      :component_name="component_name"
      @show-notification="showNotification"
      @callback-function="dataChanged"
    />
    <deleteMatchModal
      :ProductToDelete="selectedProductToAction"
      :show="show_delete_modal"
      :component_name="component_name"
      @callback-function="dataChanged"
    />
    <deleteMatchModalMultiple
      :ProductsToDelete="selectedMatchs"
      :show="show_delete_modal_multiple"
      :component_name="component_name"
      @callback-function="dataChanged"
    />
    <editMatchModalCustom
      :ProductToEdit="selectedProductToAction"
      :show="show_edit_modal"
      :component_name="component_name"
      @confirm-edit="dataChanged"
      @show-notification="showNotification"
      v-if="user_website.id == source_id"
    />
    <editMatchModal
      :ProductToEdit="selectedProductToAction"
      :show="show_edit_modal"
      :component_name="component_name"
      @confirm-edit="dataChanged"
      @show-notification="showNotification"
      v-else
    />
    <!-- This is the exportation modal, to choose which websites you didn't want to export-->
    <exportModal
      :show="export_modal_opend"
      :component_name="component_name"
      @show-redirect-modal="showRedirectModal()"
    />
    <exportRedirectModal :show="show_redirect_modal" />
    <histogramchart
      :selected_product="selectedProductToAction"
      :component_name="component_name"
      :show="show_histograme_modal"
    />
    <div v-if="showGlobalAccurateDataMessage" class="d-flex justify-center">
      <v-alert prominent type="warning" class="w-50" color="error">
        <v-row align="center">
          <v-col class="grow">
            <div class="md-text">The data on your Dashboard may not be accurate initially.</div>
            <div class="md-text">We will validate your websites within the next 24 hours.</div>
          </v-col>
          <v-col class="shrink">
            <v-btn outlined @click="hideGlobalAccurateDataMessage">Hide</v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <div v-if="showGlobalAddProductsMessage" class="d-flex justify-center">
      <v-alert prominent type="info" class="w-50">
        <v-row align="center">
          <v-col class="grow">
            <div class="md-text">Start by creating you first product list</div>
            <div class="md-text">You can import products from an xlsx or csv file</div>
          </v-col>
          <v-col class="shrink">
            <router-link to="importations">
              <v-btn outlined>Add Products</v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-alert>
    </div>
    <v-row v-if="match_length.list_length">
      <v-col cols="6"><chartcardpricingstatus :component_name="component_name" /></v-col>
      <v-col cols="6"><chartcardavailability :component_name="component_name" /></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card outlined>
          <v-card-title>
            <span class="md-text">Dashboard</span>
            <span class="md-text">
              <v-switch
                color="error"
                dense
                class="ma-0 ml-5"
                v-model="compactTable"
                lable="Table type"
                hide-details
              >
              </v-switch
            ></span>
            <span class="sm-text text--secondary">{{
              compactTable ? 'Compact View' : 'Basic View'
            }}</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  light
                  color="primary"
                  @click="showExportModal"
                  small
                  >Prepare your export
                  <v-icon right dark>mdi-database-export</v-icon>
                </v-btn>
                <v-btn
                  :disabled="!selectedMatchs.length"
                  outlined
                  light
                  color="error"
                  class="ml-auto mr-2"
                  @click="deleteMatchs"
                  small
                  >Delete Products
                  <v-icon right dark>mdi-delete-forever</v-icon>
                </v-btn>
              </template>
              <span>export your histories matches data</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text
            v-if="
              showGlobalAddProductsMessage &&
              ((!matches_stats && !match_length.list_length) || matches_stats)
            "
          >
            <div v-if="!matches_stats && !match_length.list_length" class="d-flex justify-center">
              <v-alert prominent type="info" color="secondary" class="w-50">
                <v-row align="center">
                  <v-col class="grow">
                    <div>Your product list will be available soon</div>
                  </v-col>
                </v-row>
              </v-alert>
            </div>
            <div class="d-flex justify-center" v-if="matches_stats">
              <div class="w-50">
                <v-row align="center">
                  <v-col cols="12">
                    <div class="lg-text mb-2 text-center">No Products Yet</div>
                    <div class="text-center md-text">Start by creating you first product list</div>
                    <div class="text-center md-text">
                      You can import products from an xlsx or csv file
                    </div>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center">
                    <router-link to="importations">
                      <v-btn color="info">Add Products</v-btn>
                    </router-link>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
          <v-card-text v-else>
            <filterInputs @load-data="dataChanged" :component_name="component_name" />
            <v-row>
              <v-col cols="12" class="pb-0">
                <simpleComparePricesTable
                  v-if="compactTable"
                  :changed="data_changed"
                  :loading="loading"
                  :selected_price_index="selected_price_index"
                  :component_name="component_name"
                  :filter="filter"
                  @select-matchs="matchsSelected"
                  @show-price-chart="showPriceChart"
                  @show-edit-modal="showEditModal"
                  @show-validate-modal="showValidateModal"
                  @confirm-delete="confirmDelete"
                  @show-notification="showNotification"
                >
                </simpleComparePricesTable>
                <comparePricesTable
                  v-else
                  :changed="data_changed"
                  :loading="loading"
                  :component_name="component_name"
                  :filter="filter"
                  @show-price-chart="showPriceChart"
                  @show-edit-modal="showEditModal"
                  @show-validate-modal="showValidateModal"
                  @confirm-delete="confirmDelete"
                  @select-matchs="matchsSelected"
                  @show-notification="showNotification"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :show="snackbar" :color="snackbar_color" :msg="snackbar_msg" :icon="snackbar_icon" />
  </div>
</template>

<script>
  import axios from 'axios'
  import histogramchart from '../common/histogramChart'
  import exportModal from '../Exportation/exportModal.vue'
  import exportRedirectModal from '../common/exportRediretModal.vue'
  import comparePricesTable from '../common/comparePricesTable.vue'
  import simpleComparePricesTable from '../common/simpleComparePricesTable.vue'
  import chartcardpricingstatus from '../common/chartPricingStatus'
  import chartcardavailability from '../common/chartCardAvailability'
  import deleteMatchModal from '../common/deleteMatchModal.vue'
  import deleteMatchModalMultiple from '../common/deleteMatchModalMultipl.vue'
  import editMatchModal from '../common/editMatchModal.vue'
  import editMatchModalCustom from '../common/editMatchModalCustom.vue'
  import filterInputs from '../common/filterInputs.vue'
  import validateMatchModal from '../common/validateMatchModal.vue'

  export default {
    name: 'Compare',
    components: {
      histogramchart,
      exportModal,
      exportRedirectModal,
      comparePricesTable,
      simpleComparePricesTable,
      chartcardpricingstatus,
      chartcardavailability,
      deleteMatchModal,
      deleteMatchModalMultiple,
      editMatchModal,
      editMatchModalCustom,
      filterInputs,
      validateMatchModal,
    },
    props: {
      msg: String,
    },
    data() {
      return {
        compactTable: true,
        user_website: localStorage.user_website ? JSON.parse(localStorage.user_website) : {},
        source_id: '6019aee744817b0f85ff85bb',
        show_redirect_modal: false,
        snackbar: false,
        snackbar_color: '',
        snackbar_msg: '',
        snackbar_icon: '',
        loading: false,
        filter: {},
        chartData: {
          data: {
            labels: [],
            datasets: [],
          },
        },
        account_id: localStorage.getItem('account_id'),
        search: '',
        matchs: [],
        allProducts: [],
        selectedProduct: {},
        selectedProductToAction: {
          match_id: '',
          match_ref: '',
          product_brand: '',
          product_name: '',
          product_image: '',
        },
        showProductChart: false,
        websites: {},
        exclude_websites: [],
        remainWebsites: [],
        export_modal_opend: false,
        // lastAvailableDate: "",
        show_histograme_modal: false,
        show_delete_modal: false,
        show_delete_modal_multiple: false,
        show_validate_modal: false,
        total_data: 0,
        data_changed: false,
        selected_price_index: null,
        show_edit_modal: false,
        match_length: { list_length: 1 },
        showGlobalAddProductsMessage: false,
        showGlobalAccurateDataMessage: false,
        component_name: this.$router.history.current.fullPath,
        selectedMatchs: [],
        matchesList: [],
        pagination: {},
        searchParams: {},
      }
    },
    methods: {
      showExportModal() {
        this.export_modal_opend = !this.export_modal_opend
      },
      showRedirectModal() {
        this.show_redirect_modal = !this.show_redirect_modal
      },
      async setUserWebsites() {
        await axios
          .get('/api/websites/list', {
            params: {
              account: this.account_id,
              user: localStorage.getItem('user_id'),
              component: this.component_name,
            },
          })
          .then((res) => {
            localStorage.setItem(`user_website`, JSON.stringify(res.data.account.website))
            localStorage.setItem(`user_websites`, JSON.stringify(res.data.account.websites))
          })
      },
      deleteMatchs() {
        this.show_delete_modal_multiple = !this.show_delete_modal_multiple
      },
      matchsSelected(matchs) {
        this.selectedMatchs = matchs
      },
      confirmDelete(item) {
        this.selectedProductToAction = item
        this.show_delete_modal = !this.show_delete_modal
      },
      showValidateModal(item) {
        this.selectedProductToAction = item.item
        this.matchesList = item.data
        this.pagination = item.pagination
        this.searchParams = item.searchParams
        this.show_validate_modal = !this.show_validate_modal
      },
      showEditModal(item) {
        this.selectedProductToAction = item
        this.show_edit_modal = !this.show_edit_modal
      },
      dataChanged(payload) {
        this.filter = payload
        this.data_changed = !this.data_changed
      },
      update_filter: function () {
        this.dataChanged()
      },
      showPriceChart(item) {
        this.selectedProductToAction = item
        this.show_histograme_modal = !this.show_histograme_modal
      },
      showNotification(payload) {
        this.snackbar_color = payload.type
        this.snackbar_msg = payload.msg
        this.snackbar = !this.snackbar
        this.snackbar_icon = payload.icon
      },
      hideGlobalAccurateDataMessage() {
        localStorage.setItem('show_ACCURATEDATA_MESSAGE', 'hide')
        this.showGlobalAccurateDataMessage = false
      },
      hideAddProductsMessage() {
        localStorage.setItem('show_PRODUCTS_MESSAGE', 'hide')

        if (this.matches_stats && localStorage.getItem('show_PRODUCTS_MESSAGE') === 'show') {
          this.showGlobalAddProductsMessage = true
        } else {
          this.showGlobalAddProductsMessage = false
        }
      },
      async getMatchsStat() {
        this.match_length = await axios
          .get('/api/products/matchsstat', {
            params: {
              account: this.account_id,
              user: localStorage.getItem('user_id'),
              component: this.component_name,
            },
          })
          .then((res) => {
            return res.data
          })
        if (this.match_length.data_length) this.matches_stats = false
        else this.matches_stats = true

        if (this.matches_stats && localStorage.getItem('show_PRODUCTS_MESSAGE') === 'show') {
          this.showGlobalAddProductsMessage = true
        } else {
          this.showGlobalAddProductsMessage = false
        }

        if (
          this.matches_stats &&
          !this.match_length.list_length &&
          localStorage.getItem('show_ACCURATEDATA_MESSAGE') !== 'hide'
        ) {
          localStorage.setItem('show_ACCURATEDATA_MESSAGE', 'show')
          this.showGlobalAccurateDataMessage = true
        }

        if (
          localStorage.getItem('show_ACCURATEDATA_MESSAGE') == 'show' &&
          this.showGlobalAccurateDataMessage == false
        ) {
          this.showGlobalAccurateDataMessage = true
        } else {
          this.showGlobalAccurateDataMessage = false
        }
      },
    },
    mounted() {
      this.setUserWebsites()
      this.getMatchsStat()
    },
    created() {
      if (localStorage.getItem('compactTable') == 'true') {
        this.compactTable = true
      } else if (localStorage.getItem('compactTable') == 'false') {
        this.compactTable = false
      } else {
        this.compactTable = true
      }
    },
    watch: {
      compactTable(val) {
        localStorage.setItem('compactTable', val)
      },
    },
  }
</script>

<style scoped>
  .theme--light.v-application {
    background: none !important;
  }
</style>
