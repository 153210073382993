<template>
  <v-data-table
    :headers="headers"
    :items="data.items"
    :options.sync="pagination"
    :footer-props="{
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }"
    :server-items-length="data.count"
    :loading="loading"
    :items-per-page="10"
  >
    <template v-slot:item.status="{ item }">
      <badge
        :text="item.status == 'done' ? 'success' : item.status"
        :color="item.status == 'done' ? 'success' : 'secondary'"
        :progress="item.status == 'pending'"
      />
    </template>

    <template v-slot:item.action="{ item }">
      <template>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="item.status != 'done'"
              @click="downloadFile(item.id)"
              v-on="on"
              icon
              small
              class="mr-2 action-icon cursor-pointer"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Download File</span>
        </v-tooltip>
      </template>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";
import io from "socket.io-client";

export default {
  data: () => ({
    data: [],
    loading: false,
    pagination: { rowsPerPage: 10, totalItems: 0 },
    headers: [
      { text: "File name", value: "filename", sortable: false },
      { text: "Name", value: "export_name", sortable: false },
      { text: "Date", value: "date", align: "center", sortable: false },
      {
        text: "Status",
        value: "status",
        align: "center",
        sortable: false,
        width: "1%",
      },
      { text: "Action", value: "action", align: "center", sortable: false },
    ],
  }),
  props: ["exportations", "component_name"],
  components: {},
  mounted() {
    this.data = this.exportations;
    this.pagination.totalItems = this.data.count;
    this.$socket.client.on("globalListener", (data) => {
      if (data.dataChanged && data.cause == "exportationChanges") {
        this.getRequests();
      }
    });
    this.start();
  },
  methods: {
    blobToFile(data, filename) {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    downloadFile(id) {
      axios
        .get("/api/exportation/getfile", {
          params: {
            account: localStorage.getItem("account_id"),
            user: localStorage.getItem("user_id"),
            component: this.component_name,
            id,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        })
        .then((res) => {
          let filename = res.headers.filename;
          this.blobToFile(res.data, filename);
        })
        .catch(function() {
          console.log("FAILURE!! download file");
        });
    },
    getItemPerPage(val) {
      this.pagination.rowsPerPage = val;
    },
    getRequests() {
      let from =
        this.pagination.page * this.pagination.rowsPerPage -
        this.pagination.rowsPerPage;
      let size = this.pagination.rowsPerPage;

      axios
        .get("/api/exportation", {
          params: {
            account: localStorage.getItem("account_id"),
            user: localStorage.getItem("user_id"),
            component: this.component_name,
            from,
            size,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch(function() {
          console.log("FAILURE!! get all requests");
        });
    },

    start() {
      setTimeout(() => {
        this.getRequests();
        this.start();
      }, 5000);
    },
  },
  watch: {
    pagination: {
      handler() {
        this.loading = true;
        try {
          this.getRequests();
        } catch (error) {
          console.log("pagination", error);
        }
      },
      deep: true,
    },
  },
};
</script>
<style></style>
