<template>
  <div>
    <v-card outlined class="card-height">
      <v-card-title class="pb-0">
        <span class="sm-text text--secondary ml-3">Products Availability Overview</span>
        <!-- <v-info top>Represents the count of the availability of each competitor's products</v-info> -->
      </v-card-title>
      <div class="mt-6"></div>
      <v-card-text style="height: 190px" class="d-flex align-center">
        <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
          <loadingProgress :loading="loading" text="Loading products availability data..." />
        </v-col>
        <v-row v-show="!loading">
          <v-col cols="12" sm="12" md="12" class="d-flex align-center justify-center">
            <canvas
              id="barchart"
              ref="barchart"
              style="width: 100% !important; height: 180px !important"
            ></canvas>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="mt-5">
            <span class="text--secondary sm-text d-flex justify-end">
              Updated {{ dateToDayName(statistics.lastUpdateDate) }}
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  import Chart from 'chart.js'
  import axios from 'axios'
  export default {
    props: ['component_name'],
    data() {
      return {
        loading: true,
        statistics: {
          data: [0, 0, 0, 0, 0, 0, 0, 0],
          lastUpdateDate: '',
        },
        limit: 10,
        higher_than_average: 0,
        lower_than_average: 0,
        total: 0,
      }
    },
    methods: {
      fullDateToShort(date) {
        //the defualt date format YYYY-MM-DD
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]

        const dateParts = date.split('-')
        const month = months[parseInt(dateParts[1]) - 1]
        return `${month} ${dateParts[2]}`
      },
      dateToDayName(date) {
        //date format YYYY-MM-DD
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const dateParts = date.split('-')
        const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
        //if date equal today return 'Today'
        //if date equal yesterday return 'Yesterday'

        if (dateObj.toDateString() === new Date().toDateString()) return 'Today'
        if (
          dateObj.toDateString() ===
          new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
        )
          return 'Yesterday'
        return days[dateObj.getDay()]
      },
      async initChart() {
        let requestedData = await this.getStatistics()

        var barChartData = {
          labels: [],
          datasets: [
            {
              backgroundColor: '#80cb7b',
              stack: 'Stack 0',
              data: [],
              label: 'InStock',
            },
            {
              backgroundColor: '#ff8373',
              stack: 'Stack 0',
              data: [],
              label: 'outOfStock',
            },
          ],
        }

        if (Object.keys(requestedData).length) {
          //const chartData = Object.keys(requestedData[0]._source.statistics).slice(0,this.limit)
          const chartData = Object.keys(requestedData)
            .slice(0, this.limit)
            .sort((a, b) => requestedData[b].in_stock - requestedData[a].in_stock)

          chartData.map((website) => {
            barChartData.labels.push(website)
            barChartData.datasets[0].data.push(requestedData[website].in_stock)
            barChartData.datasets[1].data.push(requestedData[website].out_of_stock)
          })
        }
        var ctx = document.getElementById('barchart').getContext('2d')
        window.myBar = new Chart(ctx, {
          type: 'bar',
          data: barChartData,
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              mode: 'index',
              intersect: false,
            },
            responsive: false,
            hover: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              xAxes: [
                {
                  barPercentage: 0.3,
                  ticks: {
                    display: true,
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                    drawBorder: false,
                  },
                },
              ],
            },
          },
        })
      },
      async getStatistics() {
        this.loading = true
        return await axios
          .get(`/api/products/statisticspricingstat`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              match: 1,
            },
          })
          .then((res) => {
            this.statistics.lastUpdateDate = res.data.lastUpdateDate
            return res.data.statistics
          })
          .finally(() => (this.loading = false))
      },
    },
    mounted() {
      this.initChart()
      const ctx = this.$refs.barchart
    },
    components: {},
  }
</script>
<style scoped>
  .crawlo-text-size {
    font-size: 18px !important;
  }
  .hightprice {
    color: #db4e4e !important;
  }
  .lowprice {
    color: #33de40 !important;
  }
  .overpriced {
    color: #ff8373;
  }
  .underpriced {
    color: #80cb7b;
  }
  .nocompetitor {
    color: #a3a0fb;
  }
  .card-height {
    height: 320px !important;
  }
</style>
