<template>
  <div class="d-flex justify-center">
    <v-alert
     class="w-50"
      prominent
      type="success"
    >
      <v-row align="center">
        <v-col cols="12" class="pb-0 lg-text">Dashboard Created</v-col>
        <v-col class="grow md-text">
         Press Continue to <router-link to="/login">sign in</router-link> to your account.
        </v-col>
        <v-col class="shrink">
          <v-btn outlined @click="goToLogin()">Continue</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>
<script>
export default {
  name: "Success",
  methods: {
    goToLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
