<template>
  <div>
    <v-card outlined>
      <v-card-title>
        <span class="md-text">Exportations List</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              outlined
              class="ml-auto"
              light
              color="primary"
              @click="showExportModal"
              >Prepare your export
              <v-icon right dark>mdi-database-export</v-icon>
            </v-btn>
          </template>
          <span>export your histories matches data</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-row>
          <exportationTable
            class="w-100"
            :exportations="exportations"
            :component_name="component_name"
          />
        </v-row>
      </v-card-text>
    </v-card>
    <exportModal :show="export_modal_opend" :component_name="component_name" />
    <snackbar :show="snackbar" :color="snackbar_color" :msg="snackbar_msg" />
  </div>
</template>
<script>
import exportationTable from "./exportationTable.vue";
import exportModal from "./exportModal.vue";

export default {
  name: "exportation",
  components: {
    exportationTable,
    exportModal,
  },
  data() {
    return {
      snackbar: false,
      snackbar_color: "",
      snackbar_msg: "",
      exportations: {},
      export_modal_opend: false,
      component_name: this.$router.history.current.fullPath,
    };
  },
  methods: {
    showNotification(payload) {
      this.snackbar_color = payload.type;
      this.snackbar_msg = payload.msg;
      this.snackbar = true;
    },
    showExportModal() {
      this.export_modal_opend = !this.export_modal_opend;
    },
  },
};
</script>

<style>
.v-app-height > .v-application--wrap {
  min-height: 0px;
}
</style>
