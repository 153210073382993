<template>
  <div>
    <v-card outlined class="card-height">
      <v-card-title class="pb-0">
        <span class="sm-text text--secondary ml-3">Pricing Status</span>
        <!-- <v-info top>Represents overview of price distribution</v-info> -->
      </v-card-title>
      <div v-if="statistics.total.value">
        <span class="font-weight-bold ml-3 text--secondary pl-4">
          {{ statistics.total.value }} product
        </span>
        <!-- <span class="sm-text ml-2 text--secondary">{{
          fullDateToShort(statistics.lastUpdateDate)
        }}</span> -->
        <span class="sm-text ml-1 text--secondary"> in total </span>
      </div>
      <v-card-text class="d-flex">
        <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
          <loadingProgress :loading="loading" text="Loading pricing status data..." />
        </v-col>
        <v-row v-show="!loading">
          <v-col cols="12" sm="4" md="4" class="d-flex align-center justify-center">
            <canvas
              id="availabilitychart"
              style="width: 100% !important; height: 150px !important"
            ></canvas>
          </v-col>
          <v-col cols="12" sm="8" md="8" class="d-flex align-center justify-center">
            <v-simple-table class="w-100">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="d-flex align-center">
                      <v-icon color="secondary">$chart_arc</v-icon>
                      <span class="ml-2">Inline</span>
                      <span class="ml-auto"
                        >{{ statistics.pricing.inlinePrice.count }} products</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex align-center">
                      <v-icon color="error">$chart_arc</v-icon>
                      <span class="ml-2">Overpriced</span>
                      <span class="ml-auto"
                        >{{ statistics.pricing.overPriced.count }} products</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="d-flex align-center">
                      <v-icon color="success">$chart_arc</v-icon>
                      <span class="ml-2">Underpriced</span>
                      <span class="ml-auto"
                        >{{ statistics.pricing.underPriced.count }} products</span
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="mt-7" v-if="statistics.lastUpdateDate">
            <span class="text--secondary sm-text d-flex justify-end">
              Updated {{ dateToDayName(statistics.lastUpdateDate) }}
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
  import Chart from 'chart.js'
  import axios from 'axios'

  export default {
    props: ['component_name'],
    data() {
      return {
        loading: false,
        hideBodyCard: false,
        statistics: {
          data: [],
          lastUpdateDate: '',
          total: {
            value: 0,
          },
          price_index: 0,
          pricing: {
            overPriced: {
              count: 0,
              purcentage: 0,
            },
            inlinePrice: {
              count: 0,
              purcentage: 0,
            },
            underPriced: {
              count: 0,
              purcentage: 0,
            },
          },
        },
      }
    },
    mounted() {
      this.initChart()
    },
    methods: {
      fullDateToShort(date) {
        //the defualt date format YYYY-MM-DD
        const months = [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ]

        const dateParts = date.split('-')
        const month = months[parseInt(dateParts[1]) - 1]
        return `${month} ${dateParts[2]}`
      },
      dateToDayName(date) {
        //date format YYYY-MM-DD
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const dateParts = date.split('-')
        const dateObj = new Date(dateParts[0], dateParts[1] - 1, dateParts[2])
        //if date equal today return 'Today'
        //if date equal yesterday return 'Yesterday'

        if (dateObj.toDateString() === new Date().toDateString()) return 'Today'
        if (
          dateObj.toDateString() ===
          new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
        )
          return 'Yesterday'
        return days[dateObj.getDay()]
      },
      hideBody() {
        if (this.hideBodyCard) this.hideBodyCard = false
        else this.hideBodyCard = true
      },
      async initChart() {
        const requestData = await this.getStatistics()
        requestData.map((bucket) => {
          if (bucket.key > 0) {
            this.statistics.pricing.overPriced.count =
              this.statistics.pricing.overPriced.count + bucket.doc_count
          }

          if (bucket.key < 0) {
            this.statistics.pricing.underPriced.count =
              this.statistics.pricing.underPriced.count + bucket.doc_count
          }

          if (bucket.key == 0) {
            this.statistics.pricing.inlinePrice.count =
              this.statistics.pricing.inlinePrice.count + bucket.doc_count
          }
        })

        var data = {
          labels: ['Inline', 'Overpriced', 'Underpriced'],
          datasets: [
            {
              data: [
                this.statistics.pricing.inlinePrice.count,
                this.statistics.pricing.overPriced.count,
                this.statistics.pricing.underPriced.count,
              ],
              backgroundColor: ['#20c2de', '#ff8373', '#80cb7b'],
              hoverBackgroundColor: ['#20c2de', '#ff8373', '#80cb7b'],
            },
          ],
          options: {
            cutoutPercentage: 40,
          },
        }

        let self = this
        var promisedDeliveryChart = new Chart(document.getElementById('availabilitychart'), {
          type: 'doughnut',
          data: data,
          plugins: [
            {
              beforeDraw: function (chart) {
                var width = chart.chart.width,
                  height = chart.chart.height,
                  ctx = chart.ctx

                ctx.restore()
                var fontSize = '20px' //(.toFixed(2);
                ctx.font = fontSize + 'em sans-serif'
                ctx.textBaseline = 'middle'
                var text = `${self.statistics.total.value} products`,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 2

                ctx.fillText(text, textX, textY)
                ctx.save()
              },
            },
          ],
          options: {
            cutoutPercentage: 60,
            responsive: false,
            legend: {
              display: false,
              position: 'bottom',
              align: 'start',
              labels: {
                padding: 20,
              },
            },
          },
        })
      },
      async getStatistics() {
        this.loading = true
        return await axios
          .get(`/api/products/statisticsglobalstat`, {
            params: {
              account: localStorage.getItem('account_id'),
              user: localStorage.getItem('user_id'),
              component: this.component_name,
              match: 1,
            },
          })
          .then((res) => {
            this.statistics.lastUpdateDate = res.data.lastUpdateDate
            this.statistics.total.value = res.data.total.value
            return res.data.statisticsData
          })
          .finally(() => (this.loading = false))
      },
    },
    components: {},
  }
</script>
<style scoped>
  .crawlo-text-size {
    font-size: 18px !important;
  }
  .hightprice {
    color: #db4e4e !important;
  }
  .lowprice {
    color: #33de40 !important;
  }
  .text-bleu {
    color: #20c2de;
  }
  .text-extra-small {
    font-size: 0.9em !important;
  }
  .overpriced {
    color: #ff8373;
  }
  .underpriced {
    color: #80cb7b;
  }
  .nocompetitor {
    color: #a3a0fb;
  }
  .key-inline {
    border: solid #20c2de;
    padding: 5px;
    border-radius: 7px;
    width: 90%;
  }

  .key-overpriced {
    border: solid #ff8373;
    padding: 5px;
    border-radius: 7px;
    width: 90%;
  }

  .key-underpriced {
    border: solid #80cb7b;
    padding: 5px;
    border-radius: 7px;
    width: 90%;
  }

  .key-nocompetitors {
    border: solid #a3a0fb;
    padding: 5px;
    border-radius: 7px;
    width: 90%;
  }
  .card-height {
    height: 320px !important;
  }
</style>
