<template>
  <v-dialog v-model="dialog" eager max-width="40%">
    <v-card>
      <v-card-title>
        <span class="md-text">Notification - ({{ item.name }})</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
            <loadingProgress
              :loading="loading"
              text="Loading notification..."
            />
          </v-col>
          <v-row v-show="!loading">
            <v-col cols="12" md="12" class="text-center pt-6">
              <span class="font-weight-bold error--text mt-4 lg-text">
                {{ notif_header }}
              </span>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="mt-4"
              v-html="competitors_statistics_html"
            >
            </v-col>
            <v-col cols="12" md="12" v-if="is_full_reporte">
              <v-btn color="error" class="float-right" @click="downloadData"
                >Download Data<v-icon right dark>$file_download</v-icon></v-btn
              >
            </v-col>
            <v-col cols="12" md="12">
              <h3 class="mt-0 mb-2">Overview</h3>

              <v-row style="background-color: #f9f9f9">
                <v-col
                  cols="12"
                  md="12"
                  class="mt-2"
                  v-for="(competitor, index) in Object.keys(
                    competitors_compare
                  )"
                  :key="index"
                >
                  <div>
                    <h3 class="mt-0 secondary--text font-weight-bold">
                      Me vs {{ aliasTitle(competitor) }}
                    </h3>
                    <v-row class="d-flex align-center">
                      <v-col cols="12" md="6" class="pt-0 pb-0"
                        ><span style="font-size: 18px"
                          >i am higher in
                          <strong
                            >{{
                              percentageCalc(
                                competitors_compare[competitor].higher,
                                competitors_compare[competitor].total
                              )
                            }}%</strong
                          >
                          products</span
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="pa-1">
                        <div
                          class="text-center"
                          style="
                            font-size: 16px;
                            width: 95%;
                            border: 2px solid #e1e4e5;
                            background-color: #fff;
                          "
                        >
                          <div
                            class="pt-2 pb-2 text-left"
                            style="color: #e44e69; background: #ffadbc"
                            :style="
                              'width: ' +
                                percentageCalc(
                                  competitors_compare[competitor].higher,
                                  competitors_compare[competitor].total
                                ) +
                                '%;'
                            "
                          >
                            <strong class="ml-2">{{
                              competitors_compare[competitor].higher
                            }}</strong>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-0"
                        ><span style="font-size: 18px"
                          >i am cheaper in
                          <strong
                            >{{
                              percentageCalc(
                                competitors_compare[competitor].cheaper,
                                competitors_compare[competitor].total
                              )
                            }}%</strong
                          >
                          products</span
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="pa-1">
                        <div
                          class="text-center"
                          style="
                            font-size: 16px;
                            width: 95%;
                            border: 2px solid #e1e4e5;
                            background-color: #fff;
                          "
                        >
                          <div
                            class="pt-2 pb-2 text-left"
                            style="color: #50c021; background: #d7f6ca"
                            :style="
                              'width: ' +
                                percentageCalc(
                                  competitors_compare[competitor].cheaper,
                                  competitors_compare[competitor].total
                                ) +
                                '%;'
                            "
                          >
                            <strong class="ml-2">{{
                              competitors_compare[competitor].cheaper
                            }}</strong>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-0"
                        ><span style="font-size: 18px"
                          >i am equal in
                          <strong
                            >{{
                              percentageCalc(
                                competitors_compare[competitor].equals,
                                competitors_compare[competitor].total
                              )
                            }}%</strong
                          >
                          products</span
                        >
                      </v-col>
                      <v-col cols="12" md="6" class="pa-1">
                        <div
                          class="text-center"
                          style="
                            font-size: 16px;
                            width: 95%;
                            border: 2px solid #e1e4e5;
                            background-color: #fff;
                          "
                        >
                          <div
                            class="pt-2 pb-2 text-left"
                            style="color: #e0a500; background: #ffe9ad"
                            :style="
                              'width: ' +
                                percentageCalc(
                                  competitors_compare[competitor].equals,
                                  competitors_compare[competitor].total
                                ) +
                                '%;'
                            "
                          >
                            <strong class="ml-2">{{
                              competitors_compare[competitor].equals
                            }}</strong>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "axios";
import Chart from "chart.js";
import XLSX from "xlsx/xlsx.mini";
export default {
  name: "notificationModal",
  props: ["show", "item"],
  data: () => ({
    dialog: false,
    loading: false,
    percentage: 0,
    first_label: "",
    first_value: "",
    notif_header: "",
    competitors_statistics: [],
    competitors_statistics_html: "",
    competitors_compare: [],
    is_full_reporte: false,
    data: [],
  }),
  computed: {
    notification_percentare() {
      return this.percentage > 0 ? "+" + this.percentage : this.percentage;
    },
  },
  methods: {
    downloadData() {
      let workSheet = XLSX.utils.json_to_sheet(this.data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, workSheet, "alert data");
      XLSX.writeFile(wb, `alert_data_export_${this.item.name}.xlsx`);
    },
    async initNotification() {
      this.loading = true;
      await axios
        .get(`/api/notification/getInfo`, {
          params: {
            account: localStorage.getItem("account_id"),
            user: localStorage.getItem("user_id"),
            id: this.item.id,
            viewed: this.item.viewed,
          },
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.is_full_reporte = res.data.full_report;
          if (this.is_full_reporte) this.data = res.data.data;
          this.percentage = res.data.trigger;
          this.first_label =
            this.percentage > 0 ? "Higher than" : "Cheaper than";
          this.first_value = res.data.found_product_count;

          this.totale_products = res.data.totale_products;
          this.competitors_statistics = res.data.competitors_statistics;
          this.competitors_compare = res.data.competitors_compare;
          let my_products_percentage = Math.round(
            (this.first_value / this.totale_products) * 100
          );
          this.notif_header = `${this.first_value} (${my_products_percentage}%) of my products are ${this.notification_percentare}% ${this.first_label} some competitors.`;
          this.competitorsStatistics();
        })
        .finally(() => (this.loading = false));
    },
    aliasTitle(alias) {
      return alias[0].toUpperCase() + alias.slice(1);
    },
    competitorsStatistics() {
      let keys = Object.keys(this.competitors_statistics);
      let result_html = "";
      keys.forEach((key) => {
        let alias = this.aliasTitle(key);
        let notification_percentare = this.notification_percentare;
        let first_label = this.first_label;

        let percentage = this.competitors_statistics[key].isHigher
          ? Math.round(
              (this.competitors_statistics[key].higher /
                this.competitors_statistics[key].total) *
                100
            )
          : Math.round(
              (this.competitors_statistics[key].cheaper /
                this.competitors_statistics[key].total) *
                100
            );

        let products_count = this.competitors_statistics[key].isHigher
          ? this.competitors_statistics[key].higher
          : this.competitors_statistics[key].cheaper;
        result_html += `<div class="mt-6 mb-6" style="font-size:18px">${this.notification_percentare}% ${first_label} <strong>${alias}</strong> : ${products_count} (${percentage}%) products</div>`;
      });
      this.competitors_statistics_html = result_html;
    },
    calcPercentage(value, total) {
      return ((value / total) * 100).toFixed(2);
    },
  },
  mounted() {},
  watch: {
    show: {
      handler() {
        this.dialog = true;
        this.initNotification();
      },
    },
  },
};
</script>
<style scoped>
.devider {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.2);
}
</style>
