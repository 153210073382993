<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card width="30%">
          <v-col
            cols="12"
            style="background-color: #f96654"
            class="d-flex align-center"
          >
            <img
              :src="require('../../assets/Dynamic_Pricing.svg')"
              width="220"
            />
          </v-col>
          <v-card-text class="pb-0">
            <v-form
              ref="reset_password_form"
              id="reset_password_form"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" class="pa-8 pt-4 pb-0">
                  <v-row>
                    <v-col cols="12">
                      <h2>Reset password</h2>
                      <v-row>
                        <v-col cols="12" class="pt-0 pb-0">
                          <v-text-field
                            prepend-inner-icon="mdi-form-textbox-password"
                            v-model="newPassword"
                            :append-icon="
                              show_password ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :rules="[rules.required, rules.min]"
                            label="New password"
                            hint="At least 8 characters"
                            counter
                            :is-password="!show_password"
                            @click:append="show_password = !show_password"
                            @focus="show_indecator = true"
                            @blur="show_indecator = false"
                            :loading="show_indecator"
                          >
                            <template v-slot:progress v-if="show_indecator">
                              <v-progress-linear
                                :value="progress"
                                :color="password_color"
                                absolute
                                height="7"
                              >
                              </v-progress-linear>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-0">
                          <v-text-field
                            prepend-inner-icon="mdi-form-textbox-password"
                            v-model="verifyPassword"
                            :append-icon="
                              show_password_conf ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            :rules="[
                              (v) =>
                                v === newPassword || 'Password do not match',
                              rules.required,
                            ]"
                            label="Confirm password"
                            hint="At least 8 characters"
                            :is-password="!show_password_conf"
                            @click:append="
                              show_password_conf = !show_password_conf
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="pb-0 d-flex">
                          <v-btn class="ma-1" text @click="gotoLogin()">
                            <v-icon left>$back</v-icon> Sign In
                          </v-btn>
                          <v-btn
                            light
                            color="error"
                            class="ml-auto"
                            @click="resetPassword"
                          >
                            Reset !
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="mb-0 d-flex justify-center">
        <v-alert
          :value="isWrong"
          color="error"
          dark
          border="right"
          icon="mdi-alert"
          transition="scale-transition"
          style="width: 30%"
        >
          <v-row align="center">
            <v-col class="grow pa-0 ml-4 mr-4"
              >There is something wrong ! please resend the reset email.</v-col
            >
            <v-col class="shrink pa-0 pr-4">
              <v-btn dark color="primary" outlined @click="goToReset"
                >Go to reset password</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ResetPassword",
  data: () => {
    return {
      show_indecator: false,
      token: "",
      email: "",
      newPassword: "",
      verifyPassword: "",
      message: "",
      isWrong: false,
      success: false,
      show_password: false,
      show_password_conf: false,
      count: 20,
      password_color: "error",
      rules: {
        required: (value) => !!value || "This field is required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    progress() {
      return Math.min(100, this.count);
    },
  },
  methods: {
    goToReset() {
      this.$router.push("/EmailInsert");
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    resetPassword: function () {
      if (this.$refs.reset_password_form.validate()) {
        this.$store
          .dispatch("RESET_PASSWORD", {
            newPassword: this.newPassword,
            verifyPassword: this.verifyPassword,
            token: this.token,
            email: this.email,
          })
          .then((res) => {
            if (res.data.status === "error") {
              this.isWrong = true;
            } else {
              this.$router.push("/Login");
            }
          });
      }
    },
  },
  mounted() {
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
    if (this.token == "" || this.email == "") {
      this.$router.push("/emailinsert");
    }
  },
  watch: {
    newPassword: {
      handler() {
        this.count = 20;
        if (this.newPassword != undefined) {
          if (8 <= this.newPassword.length && this.newPassword.length <= 32) {
            if (RegExp(".*\\d.*").test(this.newPassword)) this.count += 20;
            if (RegExp(".*[a-z].*").test(this.newPassword)) this.count += 20;
            if (RegExp(".*[A-Z].*").test(this.newPassword)) this.count += 20;
            if (
              RegExp("(?=.*[!@#\\$%\\^&\\*])(?=.{8,})").test(this.newPassword)
            )
              this.count += 20;
          }
        }
        this.password_color =
          this.count <= 40
            ? "error"
            : this.count == 60
            ? "warning"
            : this.count == 80
            ? "light-blue"
            : "success";
      },
    },
  },
};
</script>
