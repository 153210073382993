<template>
  <v-dialog v-model="dialog" eager max-width="50%">
    <v-card>
      <v-card-title>
        <span class="md-text">Urls errors handler</span>
        <v-btn icon class="ml-auto" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pb-0">
        <v-container>
          <v-col cols="12" sm="12" md="12" class="ma-0" v-if="loading">
            <loadingProgress :loading="loading" text="Loading errors data..." />
          </v-col>
          <v-row v-show="!loading">
            <v-col cols="12" sm="12" md="12" class="ma-0 pa-0 pb-2">
              <productForm
                v-for="(element, index) in products"
                :key="element.id"
                :style="index > 0 ? 'margin-top:30px;' : ''"
                :element="element"
                :index="index"
                :update="true"
                :error="true"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text class="mr-2" @click="dialog = false">Cancel</v-btn>
        <submitButton
          :submitFunction="submitErrorHandle"
          :submit_loading="submit_loading"
          text="Save"
          color="secondary"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
 
import axios from "axios";
const isUrl = require("is-url");
export default {
  data() {
    return {
      dialog: false,
      products: [],
      submit_loading: false,
    };
  },
  props: [
    "data",
    "loading",
    "isSingleProduct",
    "error_id",
    "show",
  ],
  methods: {
    valueChanged(item, event) {
      if (!isUrl(item.value)) {
        item.validated = false;
      } else {
        item.validated = true;
        item.checked = true;
      }
    },
    prepareErrors() {
      let p = [];
      this.data.map((elt) => {
        for (let i = 0; i < this.products.length; i++) {
          if (this.products[i].id == elt.id) {
            let temp = this.products[i].urls.split("\n");
            temp = temp.map((element, index) => {
              elt.urls[index].url = element;
            });
            p.push(elt);
            break;
          }
        }
        return elt;
      });

      let result = { data: p, isSingleProduct: this.isSingleProduct };
      return result;
    },
    submitErrorHandle() {
      this.submit_loading = true;
      let result = this.prepareErrors();
      let isSingleProduct = result.isSingleProduct;
      axios
        .post("/api/importation/errorhandler", {
          data: {
            id: this.error_id,
            account_id: localStorage.getItem("account_id"),
            data: result.data,
            isSingleProduct,
            user_id: localStorage.getItem("user_id"),
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.dialog = false;
          if (res.data.status) {
            this.$emit("show-notification", {
              msg: `${res.data.error_handled_count} errors has been handled`,
              type: "success",
            });
            this.updateStatistics();
          } else {
            if (res.data.is_limit_error) {
              this.$emit("show-notification", {
                msg: res.data.msg,
                type: "warning",
              });
            }
          }
        })
        .catch(function (err) {
          console.log("submitErrorHandle FAILURE!", err);
        })
        .finally(() => (this.submit_loading = false));
    },
  },
  mounted() {},
  watch: {
    data: {
      deep: true,
      handler() {
        this.products = JSON.parse(JSON.stringify(this.data));
        this.products = this.products.map((elt) => {
          let products = "";
          for (let i = 0; i < elt.urls.length; i++) {
            products += elt.urls[i].url + (i < elt.urls.length - 1 ? "\n" : "");
          }
          elt.urls = products;
          return elt;
        });
      },
    },
    show: {
      handler() {
        this.dialog = true;
      },
    },
  },
};
</script>