<template>
  <div v-if="product.product">
    <div v-if="!show_delete && !removed">
      <div class="product-website-text d-flex align-center">
        <img
          :src="'https://8181.integ.crawlo.com/favicon?size=xs&website=' + product.website.url"
          @error="replaceByDefault"
          width="30"
          height="30"
          class="mr-2 favicon-img"
        />
        {{ product.website.alias }}
        <v-btn icon class="ml-auto" v-if="!default_product" @click="show_delete = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </div>
      <v-text-field
        label="New product url"
        v-model="product.product.url"
        :background-color="background"
        :color="color"
        :rules="urlRule"
        solo
        hide-details
        clearable
        @input="valueChanged()"
      ></v-text-field>
    </div>
    <div v-if="show_delete">
      <div class="product-website-text">
        <img
          :src="'https://8181.integ.crawlo.com/favicon?size=xs&website=' + product.website.url"
          @error="replaceByDefault"
          width="30"
          height="30"
          class="mr-2 favicon-img"
        />
        {{ product.website.alias }}
        <div v-if="removed">
          <div>
            <v-alert type="info" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
              <div>
                The deletion will applied when the edit is complete, you still can undo the deletion
              </div>
              <div>{{ product.product.url }}</div>
            </v-alert>
          </div>
          <div class="text-center mt-4">
            <v-btn color="primary" @click="undoDeletion(product.product)">Undo</v-btn>
          </div>
        </div>
        <div v-else>
          <div>
            <v-alert type="warning" class="mb-0 mt-4 mb-4 font-weight-bold delete-text">
              <div>
                Are you sure you want to delete "
                {{ product.website.alias }} " product ?
              </div>
              <div>{{ product.product.url }}</div>
            </v-alert>
          </div>
          <div class="text-center mt-4">
            <v-btn class="mr-2" color="primary" text @click="deleteProduct(product.product)"
              >Yes, delete it!</v-btn
            >
            <v-btn color="error" @click="show_delete = false">cancel</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  const isUrl = require('is-url')
  export default {
    name: 'editProductForm',
    props: ['product', 'default_product'],
    data() {
      return {
        removed: false,
        show_delete: false,
        submit_loading: false,
        background: '#ECFBE6',
        color: '#0BA058',
        urlRule: [(v) => isUrl(v) || 'This url not valid, please try to correct it'],
      }
    },
    methods: {
      deleteProduct() {
        this.removed = true
        this.$emit('delete-product', this.product)
      },
      undoDeletion() {
        this.removed = false
        this.show_delete = false
        this.$emit('undo-deletion-product', this.product)
      },
      valueChanged() {
        if (isUrl(this.product.product.url)) {
          this.background = '#ECFBE6'
          this.color = '#0BA058'
        } else {
          this.background = '#FFE9ED'
          this.color = '#CC1E3F'
        }
      },
    },
  }
</script>
<style scoped>
  .product-website-text {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  .url_input {
    color: #ff0000;
    border-color: #ff0000;
  }
  .costum-bagde {
    text-transform: none;
    cursor: auto;
  }
  .product-name-text {
    font-size: 1.2rem;
  }
</style>
