<template>
  <div>
    <v-card
    outlined
    >
      <v-card-title>
        <v-row>
          <v-col cols="8">
            <span class="md-text">History</span>
            <v-info top>Represents the history of exclusive products</v-info>
          </v-col>
          <v-col cols="4" class="d-flex align-center">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRangeText"
                  placeholder="select a range"
                  prepend-icon="mdi-calendar-range-outline"
                  readonly
                  dense
                  hide-details
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dates"
                no-title
                range
                scrollable
                :max="max_date"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="changeRange"> OK </v-btn>
              </v-date-picker>
            </v-menu>
            <v-btn color="grey" icon light @click="reset" class="mx-2">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              class="ml-auto"
              v-on="{ ...menu }"
              outlined
              small
              color="primary"
              @click="exportChart()"
            >
              Export chart
              <v-icon right dark> $file_download </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text style="height: 350px" class="d-flex align-center">
        <v-col cols="12" class="ma-0" v-if="loading">
          <loadingProgress
            :loading="loading"
            text="Loading price index data..."
          />
        </v-col>
        <v-row v-show="!loading">
          <v-col cols="12" class="d-flex align-center justify-center pt-6">
            <span v-if="no_data" class="md-text text-center">
              There is no history in this range
              <span class="error--text">({{ dateRangeText }})</span> !
              <br />
              please try to select a valid range or go back
              <br />
              <v-btn
                color="primary"
                outlined
                light
                small
                @click="reset"
                class="ml-2"
              >
                init
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </span>
            <canvas
            class="w-100 px-2 pb-6"
              v-show="!no_data"
              ref="exclusiveChart"
              id="exclusiveChart"
              style="width: 100% !important"
              height="320px"
            ></canvas>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import Chart from "chart.js";
import axios from "axios";
import moment from "moment";
import XLSX from "xlsx/xlsx.mini";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.plugins.unregister(ChartDataLabels);
export default {
  name:"chartCardExlusive",
  props: ["component_name"],
  components: {},
  data() {
    return {
      loading: false,
      dates: [],
      menu: false,
      exclusive_chart: {},
      no_data: false,
      max_date: moment(Date.now()).format("YYYY-MM-DD"),
    };
  },
  mounted() {
    this.initChart();
  },
  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },
  methods: {
    async initChart() {
      const data = await this.getStatistics();

      let labels = [];
      let dataset_data = [];

      data.map((datum) => {
        labels.push(datum.date);
        dataset_data.push(datum.count);
      });

      let ctx = document.getElementById("exclusiveChart");
      this.exclusive_chart = new Chart(ctx, {
        type: "bar",
        plugins: [ChartDataLabels],
        data: {
          labels,
          datasets: [
            {
              label: "",
              data: dataset_data,
              borderSkipped: true,
              backgroundColor: "#20c2de",
            },
          ],
        },
        options: {
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          responsive: false,
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 30,
              bottom: 0,
            },
          },
          scales: {
            xAxes: [
              {
                position: "left",
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  suggestedMin: 0,
                },
              },
            ],
          },

          plugins: {
            datalabels: {
              align: "end",
              anchor: "end",
              font: {
                size: 11,
                weight: 600,
              },
              offset: 4,
              color: "#9e9e9e",
              padding: {
                left: 10,
                right: 10,
              },
            },
          },
        },
      });
    },
    exportChart() {
      let data_to_export = [];
      this.exclusive_chart.data.labels.map((elt, index) => {
        let indexs = { date: elt };
        indexs.count = this.exclusive_chart.data.datasets[0].data[index];
        data_to_export.push(indexs);
      });
      let workSheet = XLSX.utils.json_to_sheet(data_to_export);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, workSheet, "exclusive products chart");
      XLSX.writeFile(wb, `exclusive_chart_export.xlsx`);
    },
    async changeRange() {
      let data = await this.getStatistics();
      let labels = [];
      let dataset_data = [];
      data.map((datum) => {
        labels.push(datum.date);
        dataset_data.push(datum.count);
      });
      this.exclusive_chart.data.labels = labels;
      this.exclusive_chart.data.datasets[0].data = dataset_data;
      this.exclusive_chart.update();
    },
    reset() {
      this.dates = [];
      this.changeRange();
    },
    async getStatistics() {
      this.$refs.menu.save(this.dates);
      let from, to, temp1, temp2;

      if (this.dates.length) {
        temp1 = this.dates[0].split("-");
        let x_date = Date.UTC(
          temp1[0],
          temp1[1] - 1,
          temp1[2] != undefined ? temp1[2] : 1,
          0,
          0,
          0
        );
        let x = new Date(x_date);
        temp2 =
          this.dates[1] == undefined
            ? this.dates[0].split("-")
            : this.dates[1].split("-");
        let y_date = Date.UTC(
          temp2[0],
          temp2[1] - 1,
          temp2[2] != undefined ? temp2[2] : 1,
          22,
          0,
          0
        );
        let y = new Date(y_date);
        if (+x <= +y) {
          from = x.getTime();
          to = y.getTime();
        } else {
          y.setHours(0);
          x.setHours(22);
          from = y.getTime();
          to = x.getTime();
        }
      } else {
        to = 0;
        from = 0;
      }
      this.loading = true;
      this.no_data = false;
      return await axios
        .get(`/api/products/exclusivehistory`, {
          params: {
            account: localStorage.getItem("account_id"),
            user: localStorage.getItem("user_id"),
            component: this.component_name,
            full: false,
            from,
            to,
          },
        })
        .then((res) => {
          if (!res.data.statisticsData.length) this.no_data = true;
          return res.data.statisticsData;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.card-height {
  height: 320px !important;
}
.crawlo-text-size {
  font-size: 18px !important;
}
.hightprice {
  color: #db4e4e !important;
}
.lowprice {
  color: #33de40 !important;
}
</style>
