<template>
  <v-card
    @click="onClick"
    class="mx-auto"
    :color="color"
    dark
    max-width="160"
    min-width="160"
  >
    <v-card-title>
      <v-icon large left>
        {{ icon }}
      </v-icon>
      <span class="title font-weight-light">{{ title }}</span>
    </v-card-title>

    <v-card-text class="headline font-weight-bold">
      {{ value }}
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "cartInfo",
  props: ["color", "value", "title", "width", "icon"],
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
