import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import components from './components'
import axios from 'axios'
import store from './store'
import authInterceptor from './auth.interceptor'
import NotFound from './components/NotFound/NotFound.vue'
import VueCodemirror from 'vue-codemirror'
import vuetify from './plugins/vuetify'
import VueTour from 'vue-tour'
import VueProgressBar from 'vue-progressbar'
import formatProductOffer from './bin/formatProductOffer'
import getWebsiteFavicon from './bin/getWebsiteFavicon'
import percentageCalc from './bin/percentageCalc'
import replaceByDefault from './bin/replaceByDefault'
import getAccountWebsites from './bin/getAccountWebsites'
import simplePriceFormatter from './bin/simplePriceFormatter'
import priceFormatter from './bin/priceFormatter'
import connectSocket from './bin/connectSocket'
import 'codemirror/lib/codemirror.css'
import singlePriceHandler from './bin/singlePriceHandler'
require('vue-tour/dist/vue-tour.css')

const dotenv = require('dotenv')
dotenv.config()

Vue.use(VueProgressBar, {
  color: '#39f',
  failedColor: 'red',
  height: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300,
  },
})
Vue.use(VueTour)
Vue.use(VueRouter)
Vue.use(VueCodemirror)
Vue.config.productionTip = false

import submitButton from './components/common/submitButton.vue'
import loadingProgress from './components/common/loadingProgress.vue'
import editProductForm from './components/common/editProductForm.vue'
import editProductFormCustom from './components/common/editProductFormCustom.vue'
import productForm from './components/Importation/productForm.vue'
import deleteModal from './components/common/deleteModal.vue'
import snackbar from './components/common/snackbar.vue'
import badge from './components/common/badge.vue'
import info from './features/info.vue'
Vue.component('submitButton', submitButton)
Vue.component('loadingProgress', loadingProgress)
Vue.component('editProductForm', editProductForm)
Vue.component('editProductFormCustom', editProductFormCustom)
Vue.component('productForm', productForm)
Vue.component('deleteModal', deleteModal)
Vue.component('snackbar', snackbar)
Vue.component('badge', badge)
Vue.component('v-info', info)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

/**
 * check if the user is authenticated
 * check if the user has the authorization for selected component
 * we need to do this with server side checking
 */
// const ifAuthenticated = (to, from, next) => {
//   if (store.getters.isAuthenticated) {
//     next()
//     return
//   }
//   next('/login')
// }

const routes = [
  {
    path: '/',
    redirect: '/Pricing',
  },
]

Object.keys(components).forEach((component) => {
  // console.log(components[component]);
  if (
    component == 'Login' ||
    component == 'EmailInsert' ||
    component == 'ResetPassword' ||
    component == 'Subscription' ||
    component == 'Success' ||
    component == 'Privacy' ||
    component == 'TermsConditions' ||
    component == 'Error' ||
    component == 'Unauthorized' ||
    component == 'NotFound'
  )
    routes.push({
      name: component,
      path: `/${component}`,
      component: components[component],
      //beforeEnter: ifNotAuthenticated,
      meta: {
        title: 'Crawlo Pricing',
        plainLayout: true,
      },
    })
  else
    routes.push({
      name: component,
      path: `/${component}`,
      meta: {
        title: 'Crawlo Pricing',
      },
      component: components[component],
      // beforeEnter:ifAuthenticated
    })
})

routes.push({
  path: '*',
  component: NotFound,
  meta: {
    plainLayout: true,
  },
})

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = store.getters.isAuthenticated()
  let isAdmin = localStorage.getItem('super_admin')
  let to_path = to.path.toLowerCase()

  if (
    (isAuthenticated &&
      (to_path === '/Subscription'.toLowerCase() ||
        to_path === '/EmailInsert'.toLowerCase() ||
        to_path === '/ResetPassword'.toLowerCase())) ||
    (to_path === '/administration'.toLowerCase() && !isAdmin)
  )
    next('/pricing')
  else if (
    to_path === '/EmailInsert'.toLowerCase() ||
    to_path === '/ResetPassword'.toLowerCase() ||
    to_path === '/Subscription'.toLowerCase() ||
    to_path === '/Success'.toLowerCase() ||
    to_path === '/Privacy'.toLowerCase() ||
    to_path === '/TermsConditions'.toLowerCase()
  )
    next()
  else if (!isAuthenticated && to_path !== '/login') next('/login')
  else if (isAuthenticated && to.matched[0].components.default.name === 'Login') next(false)
  else next()
})

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  axios.defaults.headers.common['account'] = localStorage.getItem('account_id')
  axios.defaults.headers.common['customer'] = localStorage.getItem('customer_id')
}

/**
 * this to intercept all requests
 * if statu different from 401 rejected
 * if statu equal 401 log out the user
 */
axios.interceptors.response.use(undefined, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use(undefined, authInterceptor(axios, router, store))

/**
 * put here all global methods
 * global methods used in differents components
 */
Vue.mixin({
  methods: {
    formatProductOffer,
    getWebsiteFavicon,
    percentageCalc,
    replaceByDefault,
    getAccountWebsites,
    simplePriceFormatter,
    priceFormatter,
    connectSocket,
    singlePriceHandler,
  },
})

new Vue({
  render: (h) => h(App),
  router,
  vuetify,
  store,
  mode: 'history',
}).$mount('#app')
